// @import "./Assets/css/bootstrap.min.css";
@import "./Assets/css/custom-bootstrap.scss";
@import "~bootstrap/scss/bootstrap";
@import "./Assets/css/animation.scss";
@import "./Assets/css/Variables";
@import "font-awesome/css/font-awesome.min.css";
@import "./Assets/css/Header";
@import "./Assets/css/Misc";
@import "./Assets/css/Sidebar";
@import "./Assets/css/Footer";
@import "./Assets/css/Layout";
@import "./Assets/css/Responsive";
@import "./Assets/css/admin.min.scss";
@import "./Assets/css/front.scss";
@import "./Assets/css/project.scss";
@import "./Assets/css/projectDetails.scss";
@import "./Assets/css/mapView.scss";
@import "./Assets/css/Comparison.scss";
@import "./Assets/css/localitydetails.scss";
@import "./Assets/css/page404.scss";
@import "./Assets/css/puneLocalities.scss";
@import "./Assets/css/comparepage.scss";
@import "./Assets/css/blogdetail.scss";
@import "./Assets/css/activity.scss";
@import "./Assets/css/blogslisting.scss";
@import "./Assets/css/ShareButtonWithPopup.scss";
@import "./Assets/css/IRFS.scss";
@import "./Assets/css/pdp.scss";
@import "./Assets/css/BottomNavBar.scss";
@import "./Assets/css/SearchPopup.scss";
@import "./Assets/css/profiledeveloper.scss";
@import "./Assets/css/irfslisting.scss";
@import "./Assets/css/createUser.scss";
@import "./Assets/css/wishlistDetails.scss";
@import "./Assets/css/developerlisting.scss";
@import "./Assets/css/gamification.scss";
@import "./Assets/css/ExitIntentComponent.scss";
@import "./Assets/css/leaflet.scss";
@import "./Assets/css/SeoPages.scss";
@import "./Assets/css/BwStudio.scss";
@import "./Assets/css/StudioHeader.scss";
@import "./Assets/css/christmastheme.scss";
