$grid-columns: 12;
// $grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // 1280px breakpoint for HD desktop
  hd: 1280px,
  // 1366px breakpoint for wider screens
  wide: 1366px,
  // Super large screen / extra wide desktop
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  hd: 1230px,  // Defining container width for 1280px
  wide: 1200px, // Defining container width for 1366px
  xxl: 1320px   // Defining container width for 1400px
);
