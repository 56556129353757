// banner starts
.bwstudio{
    @function proportional-width($container-width, $reference-screen-width) {
        @return calc($container-width / $reference-screen-width) * 100vw;
    }
    @mixin proportional-container($container-width, $reference-screen-width) {
        width: proportional-width($container-width, $reference-screen-width);
    }
    .container {
        @include proportional-container(1688px, 1920px);
        max-width: 100%;
        @media only screen and (max-width: 640px) {
            @include proportional-container(600px, 640px);
        }
    }
    @function px-to-vw($px, $base: 1366) {
        @return calc($px / $base) * 100vw;
    }
    @function px-to-vhmob($px, $base: 667) {
        @return calc($px / $base) * 100vh;
    }
    .hero-section{
        padding-top: px-to-vw(90);
    }
    .hero-heading h1, .hero-heading .h1 {
        font-size: px-to-vw(77);
        line-height: px-to-vw(77);
        margin: px-to-vw(15) 0px;
    }
    .devlisting-studio{
        .devListing{
            background-image: unset;
            padding-top: 0px;
            .developer-box-container .developer-card .infoWrapper .imgWrap{
                text-align: center;
                border: none;
                border-radius: 0;
                background-color: transparent;
                box-shadow: none;
                .developer-logo{
                    filter: gray;
                    -webkit-filter: grayscale(1);
                    width: px-to-vw(240);
                    padding: px-to-vw(10);
                    &:hover{
                        filter: none;
                        -webkit-filter: grayscale(0);
                    }
                }
            }
        }
    }
    .btnclick{
        border: none;
        background-color: unset;
        padding: 0px;
        margin: auto;
        display: block;
        margin-top: px-to-vw(-55);
        z-index: 1;
        position: relative;
        img{
            width: px-to-vw(112);
        }
        @media (max-width:992px){
            margin-top: -65px;
            width: 75px;
        }
    }
    .devlist2{
        .devListing{
            background-image: unset;
            padding-top: px-to-vw(20);
            .developer-box-container .developer-card .infoWrapper .imgWrap {
                border-radius: px-to-vw(20);
                border: px-to-vw(2) solid #0942D9;
                background: #FFFDFD;
                box-shadow: px-to-vw(2) px-to-vw(2) px-to-vw(4) 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
    .studio-hero{
        background:#0942D9;
        border-radius: px-to-vw(80);
        padding: 0px 0px 0px px-to-vw(80);
        overflow: hidden;
        height: px-to-vw(548);
        position: relative;
        @media (max-width:992px){
            padding: 40px 0px 0px;
            border-radius: 50px;
            height: auto;
            margin: 0 10px;
        }
        @media (max-width:375px) {
            min-height: 80vh;
        }
        .studiobanner-head{
            span{
                color: #4ADB96;
            }
        }
        .bnrpattern1{
            position: absolute;
            left: 0;
            width: px-to-vw(355);
        }
        .bnrpattern2{
            position: absolute;
            right: 0;
            width: px-to-vw(429);
        }
        .bannerimg{
            position: absolute;
            right: px-to-vw(60);
            bottom: 0;
            width: px-to-vw(520);
            @media (max-width:992px){
                position: unset;
            }
        }
        .h100{
            height: 100%;
            align-items: center;
            position: relative;
        }
    }
    .studio-head{
        color: #034EF1;
        font-size: px-to-vw(52);
        font-style: normal;
        font-weight: 700;
        line-height: px-to-vw(59);
        letter-spacing: px-to-vw(-1.04);
        text-transform: uppercase;
        @media (max-width:992px){
            font-size: 32px;
            line-height: 42px;
        }
        span{
            color: #50D894;
            font-size: px-to-vw(52);
            font-style: normal;
            font-weight: 700;
            line-height: px-to-vw(59);
            letter-spacing: px-to-vw(-1.04);
            text-transform: uppercase;
            @media (max-width:992px){
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
    // clients starts
    .clients{
        .leftcontent{
            .leftcontentimg{
                margin-bottom: px-to-vw(20);
            }
            .leftcontent-text{
                color: #8C8C8C;
                font-size: px-to-vw(24);
                font-style: normal;
                font-weight: 400;
                line-height: 177%;
                margin-bottom: px-to-vw(10);
                position: relative;
                @media (max-width:992px){
                    font-size: 18px;
                }
                &::after{
                    content: '';
                    display: block;
                    margin: auto;
                    margin-right: 0;
                    width: px-to-vw(116);
                    height: px-to-vw(2);
                    background: #42B57E;
                    margin-top: px-to-vw(2);
                }
            }
        }
        .rightcontent {
            border: px-to-vw(6) solid #0942D9;
            padding: px-to-vw(25) px-to-vw(40);
            width: fit-content;
            @media (max-width:992px){
                width: 100%;
                padding: 25px;
                border: 2px solid #0942D9;
            }
            .rightcontent-text {
                color: #0942D9;
                font-size: px-to-vw(48);
                font-style: normal;
                font-weight: 600;
                line-height: 111%;
                @media (max-width:992px){
                    font-size: 32px;
                    line-height: 42px;
                }
                span{
                    color: #42B57E;
                }
            }
        }
    }
    
    // process starts
    .process{
        .dots{
            position: absolute;
            top: px-to-vw(-100);
            left: 0;
        }
        .dots2{
            position: absolute;
            top: px-to-vw(-25);
            right: px-to-vw(-50);
        }
        .dots3{
            position: absolute;
            bottom: px-to-vw(-40);
            left: px-to-vw(-30);
        }
        .dots4{
            position: absolute;
            bottom: px-to-vw(258);
            left: 50%;
            transform: translateX(px-to-vw(50));
        }
        .process-wrap{
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: auto auto auto;
            gap: px-to-vw(16);
            @media (max-width:992px){
                display: block;
            }
            .process-box{
                padding: px-to-vw(60) 0 px-to-vw(80) px-to-vw(40);
                border-radius: px-to-vw(15);
                background-color: #fff;
                @media (max-width:992px){
                    padding: 30px 0px px-to-vw(25) px-to-vw(15);
                }
                &.processbox1{
                    border: px-to-vw(5) solid #EC9900;
                    grid-column: span 3;
                    grid-row: span 1;
                    padding-right: px-to-vw(20);
                    @media (max-width:992px){
                        border: 2px solid #EC9900;
                    }
                }
                &.processbox2{
                    border: px-to-vw(5) solid #EC605B;
                    grid-column: span 3;
                    grid-row: span 1;
                    @media (max-width:992px){
                        border: 2px solid #EC605B;
                    }
                }
                &.processbox3{
                    padding: px-to-vw(50) 0px px-to-vw(40) px-to-vw(40);
                    border: px-to-vw(5) solid #13D0CA;
                    grid-column: span 4;
                    grid-row: span 1;
                    @media (max-width:992px){
                        border: 2px solid #13D0CA;
                    }
                }
                &.processbox4{
                    border: px-to-vw(5) solid #50D894;
                    grid-column: span 2;
                    grid-row: span 2;
                    padding-right: px-to-vw(20);
                    @media (max-width:992px){
                        border: 2px solid #50D894;
                    }
                }
                &.processbox5{
                    border: px-to-vw(5) solid #034EF1;
                    grid-column: span 2;
                    grid-row: span 1;
                    padding: px-to-vw(20) px-to-vw(20) 0px px-to-vw(30);
                    @media (max-width:992px){
                        border: 2px solid #034EF1;
                    }
                }
                &.processbox6{
                    border: px-to-vw(5) solid #EC9900;
                    grid-column: span 2;
                    grid-row: span 1;
                    padding: px-to-vw(20) px-to-vw(20) 0px px-to-vw(30);
                    background-color: transparent;
                    @media (max-width:992px){
                        border: 2px solid #EC9900;
                    }
                }
            }
            .process-text {
                color: #000;
                font-size: px-to-vw(28);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .process-list {
                list-style: disc;
                color: #727272;
                font-size: px-to-vw(20);
                font-style: normal;
                font-weight: 400;
                line-height: px-to-vw(36);
                margin-inline-start: px-to-vw(25);
            }
            .processimg1 {
                position: absolute;
                bottom: px-to-vw(-10);
                right: px-to-vw(-5);
            }
            .processimg2 {
                position: absolute;
                bottom: px-to-vw(15);
                right: px-to-vw(15);
            }
            .processimg3{
                position: absolute;
                right: px-to-vw(15);
                bottom: px-to-vw(10);
            }
            .processimg5{
                position: absolute;
                right: px-to-vw(15);
            }
            .processimg6{
                margin: auto;
                margin-right: 0;
                display: block;
            }
        }
    }
    
    .tabs {
        display: flex;
        justify-content: center;
        margin-bottom: px-to-vw(15);
        margin-top: px-to-vw(20);
        button{
            margin: 0px px-to-vw(10);
            color: #fff;
        }
      }
      
      .tab-btn {
        padding: px-to-vw(10) px-to-vw(15);
        border: none;
        background: #f9f9f9;
        cursor: pointer;
        transition: background 0.3s;
        border-radius: px-to-vw(10);
        background: #034EF1;
      }
      
      .tab-btn.active {
        border-radius: px-to-vw(10);
        border: 3px solid #50D894;
        background: #034EF1;
      }
      
      .tabsbg{
        background: #0942D9;
        box-shadow: 0px px-to-vw(4) px-to-vw(4) 0px rgba(0, 0, 0, 0.25);
        padding-bottom: px-to-vw(30);
        border-radius: px-to-vw(40);
      }
    
      .contentflex{
        display: flex;
      }
      .slide-text{
        padding: px-to-vw(40) 0px 0px px-to-vw(40);
        p{
            color: #FFF;
            font-size: px-to-vw(48);
            font-style: normal;
            font-weight: 700;
            line-height: 123%;
        }
      }
      .slide-header {
        display: flex;
    }
    .slide{
        padding-top: px-to-vw(300);
        .content-title{
            color: #0942D9;
        font-size: px-to-vw(24);
        font-style: normal;
        font-weight: 700;
        line-height: 111%;
          }
    }
    .content-box {
        border-right: px-to-vw(2) solid #50D894;
        padding-left: px-to-vw(20);
        padding-right: px-to-vw(20);
    }
    .slide-image {
        margin-top: px-to-vw(-90);
        margin-right: px-to-vw(20);
    }
    .slide-header{
        margin-top: px-to-vw(-200);
        display: flex;
        align-items: flex-end;
    }
      .slide-image{
        img{
            margin: auto;
            margin-right: 0;
        }
      }
    .nav-slider{
        margin-top: px-to-vw(30);
    }
      .nav-image-wrapper {
        border-radius: px-to-vw(20);
        border: px-to-vw(2) solid #0942D9;
        background: #FFFDFD;
        box-shadow: px-to-vw(2) px-to-vw(2) px-to-vw(4) 0px rgba(0, 0, 0, 0.25);
        width: fit-content;
        padding: px-to-vw(30);
        width: px-to-vw(300);
        height: px-to-vw(170);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    // .custom-slider {
    //     .slick-slide {
    //       transition: transform 0.3s ease, width 0.3s ease;
    //       opacity: 0.6;
    //       &.slick-center {
    //         opacity: 1;
    //         transform: scale(1.2);
    //       }
    //     }
      
    //     .slide {
    //       img {
    //         width: 100%;
    //         height: auto;
    //         display: block;
    //       }
    //     }
    //   }
    
    .tools-wrapper{
        margin-top: px-to-vw(30);
    }
    .toolsbox{
        img{
            margin: auto;
            display: block;
        }
    }
      .tools-text{
        margin-top: px-to-vw(10);
        text-align: center;
        @media (max-width:992px){
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 111%;
        }
      }
    .expand-container {
        display: flex;
        flex-direction: row; /* Default to row for desktop */
        margin-top: px-to-vw(30);
        @media (max-width:992px){
            flex-direction: column;
            // height: 70vh;
        }
        @media (max-width:640px){
            height: 900px;
        }
        .panel {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: px-to-vw(460);
            border-radius: px-to-vw(43);
            color: white;
            cursor: pointer;
            flex: 1;
            margin: px-to-vw(5);
            padding: 0px;
            position: relative;
            -webkit-transition: all 700ms ease-in-out;
            transition: all 700ms ease-in-out;
            overflow: hidden;
            // filter: grayscale(1);
            @media (max-width:992px){
                height: 20vh;
                margin: px-to-vw(5) 0;
                border-radius: 30px;
            }
            @media (min-width:992px) and (max-width:1024px){
                height: 30vh;
            }
            @media (min-width:1900px){
                height: px-to-vw(500);
                border-radius: px-to-vw(43);
            }
            .dots{
                position: absolute;
            }
            .panel-head {
                font-size: px-to-vw(24);
                position: absolute;
                bottom: px-to-vw(180);
                left: 65%;
                margin: 0;
                transform: rotate(-90deg) translateX(-50%);
                transform-origin: bottom left;
                width: max-content;
                transition: all .7s linear;
                color: #FFF;
                font-size: px-to-vw(40);
                font-style: normal;
                font-weight: 700;
                line-height: 111%;
                @media (max-width:992px){
                    bottom: 22px;
                    left: 60%;
                    transform: rotate(0deg) translateX(-50%);
                    transform-origin: bottom center;
                    width: 100%;
                    font-size: 32px;
                }
                @media (max-width:640px){
                    bottom: 15px;
                    left: 70%;
                }
            }
            .panelc-flex{
                display: flex;
                align-items: center;
                z-index: 1;
                transition: bottom .7s linear;
                z-index: 1111;
                position: relative;
                height: 100%;
                .numberdiv {
                    width: px-to-vw(61);
                    height: px-to-vw(61);
                    border-radius: 100%;
                    background-color: white;
                    transition: all .6s ease-in-out;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    @media (max-width:992px){
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: -12px;
                        left: 20px;
                    }
                    @media (min-width:992px) and (max-width:1024px){
                        width: 50px;
                        height: 50px;
                    }
                    p {
                        color: black;
                        text-align: center;
                        font-size: px-to-vw(32);
                        font-weight: 600;
                        line-height: px-to-vw(62);
                        @media (max-width:992px){
                            font-size: 20px;
                        }
                        @media (min-width:992px) and (max-width:1024px){
                            font-size: 26px;
                            line-height: 55px;
                        }
                    }
                }
                .panel-content{
                    opacity: 0;
                    margin-left: px-to-vw(15);
                    padding-left: px-to-vw(30);
                    transition: all .7s linear;
                    @media (max-width:992px){
                        margin-left: 0;
                        padding-left: 0;
                    }
                    .prname {
                        color: #FFF;
                        font-family: "century-gothic";
                        font-size: px-to-vw(64);
                        font-style: normal;
                        font-weight: 700;
                        line-height: px-to-vw(64);
                        @media (max-width:992px){
                            font-size: 32px;
                            line-height: 42px;
                        }
                    }
                    .panel-head2 {
                        font-size: px-to-vw(24);
                        @media (max-width:992px){
                            font-size: 15px;
                        }
                    }
                    .panel-pos{
                        margin-top: px-to-vw(8);
                        line-height: normal;
                        @media (max-width:992px){
                            font-size: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }
            &.active{
                flex: 7;
                @media (min-width:1900px){
                    border-radius: px-to-vw(43);
                }
                &::before{
                    opacity: 0;
                }
                &::after{
                    opacity: 1;
                }
                .panel-head {
                    transform: rotate(0deg) translateX(0%);
                    opacity: 0;
                    left: 5%;
                    bottom: px-to-vw(90);
                    @media (max-width:992px){
                        opacity: 0;
                    }
                }
                .panelc-flex{
                    transition: bottom .7s linear;
                    @media (max-width:992px){
                        bottom: 0;
                        left: 0;
                        text-align: center;
                        padding: 0 0px;
                    }
                    .panel-content {
                        // display: block;
                        transition-delay: 4s;
                        opacity: 1;
                        transition: all 1s linear;
                        margin-left: 0px;
                        flex-direction: row;
                        @media (max-width:992px){
                            margin-left: 0;
                            padding: 25px 20px 0px;
                        }
                        .panel-desk{
                            margin-top: px-to-vw(20);
                        }
                    }
                    .numberdiv {
                        background-color: #FF5;
                        left: px-to-vw(35);
                        @media (max-width:992px){
                            left: 13px;
                            bottom: -3px;
                        }
                    }
                }
            }
        }
    }
    .expimg{
        display: flex;
        align-items: baseline;
        justify-content: center;
        @media (max-width:992px){
           flex-direction: column;
           align-items: center;
           justify-content: space-between;
        }
        .lapimg{
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width:992px){
                position: unset;
                max-width: 100%;
            }
        }
    }
    .panels-container {
        display: flex;
        gap: px-to-vw(10);
    }
    
    .panel {
        padding: px-to-vw(20);
        background-color: #f4f4f4;
        border: px-to-vw(1) solid #ddd;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        @media (max-width:992px){
            padding: 0px;
        }
    }
    
    .panel1{
        border-radius: px-to-vw(20);
        background: #EC9900;
    }
    .panel2{
        border-radius: px-to-vw(20);
    background: #A8E7DF;
    }
    .panel3{
        border-radius: px-to-vw(20);
    background: #A380FF;
    }
    .panel4{
        border-radius: px-to-vw(20);
    background: #FD8BBE;
    }
    .panel5{
        border-radius: px-to-vw(20);
    background: #F7E276;
    }
    
    .panel-content{
        display: grid;
        grid-template-columns: 50% 50%;
        height: 100%;
        width: 100%;
        padding-top: px-to-vw(60);
        @media (max-width:992px){
            grid-template-columns: auto;
            padding-top: 15px;
        }
    }
    
    .studioherosec{
        @media (max-width:375px){
            margin-top: 0px;
        }
    }

    .box-grid{
        background-color: #fff;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: px-to-vw(50) px-to-vw(45);
        border-radius: px-to-vw(50);
        gap: px-to-vw(15);
        height: 100%;
        align-items: baseline;
        @media (max-width:992px){
            display: block;
            padding: 20px;
            border-radius: 30px;
            height: auto;
            margin-top: 20px;
        }
        .slick-arrow{
            background-color: unset;
            &.slick-next:before {
                font-size: px-to-vw(30);
            }
            &.slick-prev{
                left: px-to-vw(-18);
                &::before{
                    font-size: px-to-vw(30);
                }
            }
        }
        .grid-item{
            border-radius: px-to-vw(30);
            background: #C4BADC;
            box-shadow: 0 px-to-vw(4) px-to-vw(4) 0 rgba(0, 0, 0, 0.25);
            padding: px-to-vw(25) px-to-vw(20);
            height: 100%;
            &:nth-child(2){
                background: #F7CF84;
            }
            &:nth-child(3){
                background: #FDEE9F;
            }
            &:nth-child(4){
                background: #F3BBD4;
            }
            .grid-title {
                font-size: px-to-vw(24)!important;
                line-height: px-to-vw(27);
                font-weight: 700;
                font-family: "century-gothic";
            }
            .grid-desc{
                font-size: px-to-vw(16);
            }
        }
    }
    .casestudy-wrapper{
        border-radius: px-to-vw(80);
        background: #F4F4F4;
        box-shadow: 0px 0px px-to-vw(30) 0px rgba(0, 0, 0, 0.25);
        padding: px-to-vw(30) px-to-vw(40);
        @media (max-width:992px){
            padding: 20px 0px;
            border-radius: 0px;
        }
        .pr-title {
            font-family: "century-gothic";
            font-size: px-to-vw(24);
            font-weight: 700;
            line-height: px-to-vw(29.52);
        }
        .nav-pills{
            @media (max-width:992px){
                flex-direction: row!important;
                justify-content: space-between;
            }
            .nav-item{
                @media (max-width:992px){
                    width: 48%;
                }
                .nav-link{
                    font-family: "century-gothic";
                    background-color: #E2E2E2;
                    margin:px-to-vw(5) 0px;
                    color: #2F2F2F;
                    font-weight: 400;
                    letter-spacing: -2%;
                    position: relative;
                    height: px-to-vw(70);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: px-to-vw(24);
                    @media (max-width:992px){
                        height: 50px;
                    }
                    &.active{
                        background: #fff;
                        color: #0942D9;
                        font-weight: 700;
                        &::before{
                            content: '';
                            position: absolute;
                            left: px-to-vw(-30);
                            top: 50%;
                            transform: translateY(-50%);
                            height: px-to-vw(45);
                            width: px-to-vw(50);
                            background-color: #fff;
                            @media (max-width:992px){
                                content: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .navslider1 {
        @media (max-width:992px){
            margin-bottom: -30px;
        }
    }
    
    
    .ame-slide{
        .mobreverse{
            @media (max-width:992px){
                flex-direction: column-reverse;
            }
        }
        .h100{
            height: 100%;
            @media (max-width:992px){
                height: auto;
            }
            .tab-content{
                height: 100%;
            }
        }
        .tab-content > .active{
            height: 100%;
        }
    }
    
    .ame-img-slider{
    
        .navslider1{
            .slick-track {
                display: flex;
                gap: 1rem;
            }
            .slick-slide{
                .thumbnail-title{
                    cursor: pointer;
                    color: #202020;
                    text-align: center;
                    font-family: "century-gothic";
                    font-size: px-to-vw(24);
                    font-style: normal;
                    font-weight: 400;
                    line-height: px-to-vw(21);
                    border-radius: px-to-vw(36);
                    border: px-to-vw(1) solid #192951;
                    padding: px-to-vw(12) px-to-vw(31) px-to-vw(17) px-to-vw(30);
                    @media (max-width:992px){
                        font-size: 16px;
                        border-radius: 20px;
                        padding: 10px 15px 10px 15px;
                        margin-bottom: 0px;
                    }
                }
                &.slick-current{
                    .thumbnail-title{
                        background: #034EF1;
                        color: #fff;
                        font-weight: 700;
                        border: none;
                    }
                }
            }
        }
    }
    
    .expand-container {
        display: flex;
        overflow: hidden; /* Prevents non-visible panels from being shown */
        transition: transform 0.5s ease-in-out; /* Smooth sliding effect */
    }
    
    .panel {
        flex: 0 0 calc(100% / 5); /* Each panel takes up 1/5th of the container width */
        box-sizing: border-box;
        margin: 0 px-to-vw(5);
        transition: transform 0.3s ease;
    }
    
    .navigation-buttons {
        display: flex;
        justify-content: end;
        margin-top: px-to-vw(20);
        @media (max-width:992px){
            display: none;
        }
        .prev-btn,
    .next-btn {
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
    }
    }
}