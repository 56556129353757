@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.bounce-text {
  // display: inline-block;
  animation: bounce 2s infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(1px);
  }
}

.shake-text {
  // display: inline-block;
  animation: shake 5s infinite;
}

@keyframes type {
  from {
    width: 0;
  }

  to {
    width: 50%;
  }
}

.type-text {
  // display: inline-block;
  overflow: hidden;
  /* Ensures the text will be hidden beyond its container */
  // border-right: 2px solid; /* Simulates a cursor */
  opacity: 0.6;
  white-space: nowrap;
  /* Keeps the text on a single line */
  text-align: left;
  animation:
    type 3s steps(40, end) 1s 1 normal both,
    /* Type effect */
}

.blinkText {
  animation: blink-caret 0.75s step-end infinite;
  /* Blinking cursor */
}

@keyframes blink-caret {

  from,
  to {
    color: transparent;
  }

  50% {
    color: black;
  }
}

@keyframes spin {
  0% {
    transform: scale(1.2) translateY(-50%) rotate(0deg);
  }

  100% {
    transform: scale(1) translateY(-50%) rotate(360deg);
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
    /* Start invisible */
  }

  50% {
    opacity: 0;
    /* Maintain invisibility for the first half */
  }

  100% {
    opacity: 1;
    /* Fully visible at the end */
  }
}

@keyframes scaleYIn {
  0% {
    transform: scaleY(0);
    /* Fully collapsed */
    transform-origin: top;
    /* Scale from the top */
  }

  100% {
    transform: scaleY(1);
    /* Full height */
    transform-origin: top;
    /* Maintain origin */
  }
}

// Keyframe for half-circle rotation and return
@keyframes rotateHalfCircle {
  0% {
    transform: rotate(0deg); // Original position
  }

  50% {
    transform: rotate(-10deg); // Half-circle rotation
  }

  100% {
    transform: rotate(0deg); // Return to original position
  }
}

@keyframes continuousFlipX {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

// Keyframe for Path 1: Spinner effect (rotates on its own axis)
@keyframes spinOnAxis {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Keyframe for Path 2: Kite effect animation
@keyframes kiteEffect {
  0% {
    transform: translate(0, 0); // Original position
  }

  50% {
    transform: translate(10px, 10px); // Move diagonally
  }

  100% {
    transform: translate(0, 0); // Return to original position
  }
}

// // Class for Path 1
// .path1 {
//   animation: spinOnAxis 2s linear infinite; // Smooth spinning effect
//   transform-origin: center; // Ensure rotation happens at the center of the path
// }

// // Class for Path 2
// .path2 {
//   animation: rotateHalfCircle 2s ease-in-out infinite; // Seamless looping
// }
.animateSvg {
  animation: continuousFlipX 1s linear infinite;
}

.btnHover {
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    ;
  }
}

.shineeffect {
  position: relative;

  &::before {
    background: linear-gradient(to right,
        fade_out(#fff, 1) 0%,
        fade_out(#43A66A, 0.85) 100%);
    content: "";
    display: block;
    height: 100%;
    left: -15%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 20%;
    z-index: 2;
    animation: shine infinite 3s;
    transition: all 0.5s linear;
  }
}

@keyframes shine {
  from {
    left: -15%;
  }

  to {
    left: 100%;
  }
}