.login-logo{
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300
}

.login-logo a{
	color: #444
}

.login-page{
	background-color: #d2d6de;
	background-image: url('../img/beyondwalls-portal-bg-new.jpg');
	background-size: 100%;
	height: 100vh;
}
/* Auth */

.auth {
	height: 100vh;
	.lock-profile-img {
		width: 90px;
		height: 90px;
		border-radius: 100%;
	}
	.auth-form-light {
		background: #fff;
		select {
			color: #c9c8c8;
		}
		.input-group {
			.form-control {
				&:focus,
				&:active {
					border-color: #ebedf2;
				}
			}
		}
	}
	.brand-logo {
		margin-bottom: 2rem;
		img {
			width: 220px;
		}
	}
	form {
		.form-group {
			margin-bottom: 1.5rem;
			label {
				font-size: .8125rem;
			}
		}
		.form-control {
			background: transparent;
			border-radius: 0;
			font-size: .9375rem;
			padding: .7rem 1rem;
		}
		.auth-form-btn {
			padding: .7rem 3rem;
			border-radius: 0;
			line-height: 1.5;
		}
		.auth-link {
			font-size: 14px;
			&:hover {
				color: initial;
			}
		}
	}
}
.login-box{
	width: 360px;
	margin: 7% auto
}

@media (max-width:768px) {
	.login-box{
		width: 90%;
		margin-top: 20px
	}
}

.login-box-body{
	background: #fff;
	padding: 20px;
	border-top: 0;
	color: #666
}

.login-box-body .form-control-feedback{
	color: #777
}

.login-box-msg{
	margin: 0;
	text-align: center;
	padding: 0 20px 20px 20px
}


/* Profile Page */
.user-profile-sec {
    background-color: #F4F5FA;
    padding-bottom: 60px;
}

.my-profile-sec {
  .profile-wrapper-box{
    border-radius: 45px !important;
    box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
    .title {
        // background-color: #AFB2C3;
        background-color: #f8f8f8;
        padding-inline: 25px;
        padding-block: 20px;
       

        h3 {
            font-size: 22px;
            line-height: 36px;
        }

        .profile-btn {
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
            color: #082040;
        }
    }

    .profile-sec {
      // .profile-wrapper-box
        padding: 23px 25px 38px 25px;
        background-color: #FFFFFF;
        // border-radius: 0 0 12px 12px;
        // border-radius:  0 0 45px 45px !important;
        // box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
        .profile-tab {
            padding-bottom: 20px;
            border-bottom: 1px solid #EDEDED;
        }

        .profile-tab-content {
            padding-top: 20px;
        }

        #user-form {
            .form-label {
                font-size: 14px;
                color: #6C6F74;
                line-height: 20px;
                margin: 0px;
            }

            .form-control {
                background-color: transparent;
                color: #082040;
                font-size: 16px;
                line-height: 24px;

            }

            .was-validated .form-control:valid {
                background-image: unset;
            }

            .form-control:focus {
                box-shadow: 0 0 0 0
            }

            .user-form-gap {
                gap: 33px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .profile-score-sec {
        .profile-name {
            font-size: 26px;
            line-height: 42px;
        }

        .profile-desc {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .my-profile-sec {
        .title {
            h3 {
                font-size: 20px;
                line-height: 33px;
            }
        }

        .profile-sec {
            #user-form {
                .form-mob-gap {
                    gap: 16px;
                }

                .user-form-gap {
                    gap: 16px;
                }
            }
        }
    }
}
.navigation-bar{
    background-color: #43A66A;
    .navbar-nav {
        display: flex;
        flex-direction: row;
      }
      
      .nav-item {
        position: relative;
        padding-inline: 22px;

      }
      
      .nav-link {
        color: #ffffff;
        font-size: 16px;
        line-height: 26px;
        padding: 0px;
        &.active{
        font-weight: bold;
        } 
        &.active::before{
          content: "";
          position: absolute;
          bottom: -5px;
          left: 16%;
          width: 68%;
          height: 3px;
          background-color: #43A66A;
        }
      }
      .nav-item::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #53647A;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }
      .nav-item:last-child::before {
        display: none;
      }     
      
    }

.profile-score-sec{
    padding-block: 45px;
    .profile-name{
        font-size: 32px;
        line-height: 52px;
    }
    .profile-desc{
        font-size: 16px;
        line-height: 24px;
    }
}
.Toastify__toast-container--top-right{
    z-index: 99999999;
}
.shortlisted {
    // animation: heartBeat 0.8s alternate;
    animation: grow 0.4s ease-in-out;
    svg {
      #Ellipse_112 {
        stroke: transparent;
  
        circle {
          fill: #42B57E;
        }
      }
  
      #Icon_ionic-ios-heart {
        fill: #42B57E;
        stroke: #ffffff;
        stroke-width: 2;
      }
    }
}
@keyframes heartBeat {
    from {
      transform: scale(1);
    }
    to {
        transform: scale(1.4);
    }
}
@keyframes grow {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
  }
.register-btn{
    border-radius: 8px!important;
    padding: 10px 40px!important;
    height: auto!important;
}
.error-msg{
    position: absolute;
    bottom: -22px;
    left: 19px;
    font-size: 12px
}
.overlay-content{
    .close{
      top: 4px;
      right: 10px !important;
      font-size: 45px;
      background-color: #fff;
      border-radius: 100%;
      line-height: normal;
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // @media (min-width:1024px) and (max-height:650px){
      //   top: 6px;
      //   right: -2px !important;
      //   font-size: 40px;
      //   width: 30px;
      //   height: 30px;
      // }
        @media (max-width:992px) {
            font-size: 35px;
            top: 10px;
            right: 10px !important;
            width: 30px;
            height: 30px;
        }
        @media (max-width:767px) {
          top: 9px;
          right: 7px !important;
        }
    }
    .priceForm{
      .close{
        @media (max-width:767px) {
          top: 10px;
          right: 15px !important;
        }
      }
    }
}
.register-img{
    height: 513px;
    width: 526px;
    border-radius: 20px;
    position: relative;
    @media (max-width:600px) {
        width: 100%;
        height: 440px;
    }
    &.bg-blue{
        background-color: #082040;
    }
    &.bg-green{
        background-color: #43A66A;
    }
    img{
        position: absolute;
        bottom: 30px;
    }
    .register-img-overlay{
        position: absolute;
        top: 20px;
        left: 40px;
        @media (max-width:600px) {
            left: 20px;
        }
        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            color: #fff;
            @media (max-width:600px) {
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
            }
        }
        ul{
            padding-left: 20px;
            padding-top: 10px;
            li{
                list-style-type: disc;
                font-size: 16px;
                line-height: 24px;
                color: #fff;
                padding: 5px 0 5px 0;
            }
        }
    }
}
.tooltip.bs-tooltip-top{
    &.show{
        z-index: 9999999!important;
    }
    .tooltip-arrow{
        &:before{
            border-right-color:transparent;
            border-top-color:#43a66a;
        }
    }
}

.editphone{
  cursor: pointer;
  svg{
    margin-top: -3px;
    margin-left: 5px;
  }
}
.otpsubmit{
  display: block!important;
  margin: auto;
}
.logininput{
  border-radius: 8px!important;
  background: #F7F7F7!important;
  border: none!important;
}
.logininput::placeholder {
  opacity: 0;
}
.loginpopupcontent .form-floating > label{
  color: #BFBFBF;
}
.logininput::-ms-input-placeholder { 
  opacity: 0;
}

.tickthank {
  width: 100px;
  display: block;
  margin: 40px auto 0;
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash 2s ease-in-out, greenfill 0.5s 2s forwards;
      animation: dash 2s ease-in-out, greenfill 0.5s 2s forwards;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 1.5s 1s ease-in-out forwards;
      animation: dash 1.5s 1s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 1.5s 1s ease-in-out forwards, change-stroke-color 0.5s 2.5s forwards;
      animation: dash-check 1.5s 1s ease-in-out forwards, change-stroke-color 0.5s 2.5s forwards;
    }
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@-webkit-keyframes greenfill {
  0% {
    fill: transparent;
  }
  100% {
    fill: #42B57E;
  }
}

@keyframes greenfill {
  0% {
    fill: transparent;
  }
  100% {
    fill: #42B57E;
  }
}

@-webkit-keyframes change-stroke-color {
  0% {
    stroke: #42B57E;
  }
  100% {
    stroke: #fff;
  }
}

@keyframes change-stroke-color {
  0% {
    stroke: #42B57E;
  }
  100% {
    stroke: #fff;
  }
}
.irfsoverlay{
  .tickthank{
    .path {
      stroke: #FFB03D;
      &.circle {
        stroke: #FFB03D;
        -webkit-animation: dash 2s ease-in-out, fill 0.5s 2s forwards;
        animation: dash 2s ease-in-out, fill 0.5s 2s forwards;
      }
    }
    @-webkit-keyframes fill {
      0% {
        fill: transparent;
      }
      100% {
        fill: #FFB03D;
      }
    }
    
    @keyframes fill {
      0% {
        fill: transparent;
      }
      100% {
        fill: #FFB03D;
      }
    }
    @-webkit-keyframes change-stroke-color {
      0% {
        stroke: #FFB03D;
      }
      100% {
        stroke: #fff;
      }
    }
    
    @keyframes change-stroke-color {
      0% {
        stroke: #FFB03D;
      }
      100% {
        stroke: #fff;
      }
    }
  }
}
.popcol{
  padding: 0px!important;
}

.cardrot {
  width: 71.5vw;
  height: 100vw;
  @media screen and (min-width: 600px) {
    width: 400px;
    height: 80vh;
  }
  position: relative;
  // overflow: hidden;
  margin: 20px auto;
  z-index: 10;
  touch-action: none;
  border-radius: 5% / 3.5%;
  // box-shadow: 
  //   -5px -5px 5px -5px var(--color1), 
  //   5px 5px 5px -5px var(--color2), 
  //   -7px -7px 10px -5px transparent, 
  //   7px 7px 10px -5px transparent, 
  //   0 0 5px 0px rgba(255,255,255,0),
  //   0 55px 35px -20px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  transform-origin: center;
  perspective: 1000px;
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 5% / 3.5%;
  background-size: cover;
  background-position: center;
  z-index: 2;
  // overflow: hidden;
}

.card-front {
  position: relative;
  .cupon-box{
    // background-color: rgb(192 192 192 / 44%);
    background-image: url(../img/cp-bg.svg);
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 5% / 3.5%;
    background-size: cover;
    background-position: center;
    z-index: 2;
    // overflow: hidden;
    padding: 15px;
    padding-top: 45px;
    background-size: contain;
    .cupon-inner{
      background-image: url(../img/COUPON.svg);
      width: 100%;
      background-size: 100% 160%;
      background-repeat: no-repeat;
      background-position: 100% 42%;
      .buildbg{
        max-width: 100%;
        height: auto;
      }
    }
  }
  .irfs-logo{
    width: 120px;
    z-index: 1;
    position: relative;
    margin: auto;
    margin-right: 5px;
    display: block;
    margin-top: 10px;
    margin-bottom: 0px;
    margin: auto;
    top: -35px;
  }
  .irfs-typo{
    margin: auto;
    width: 75%;
    display: block;
    margin-top: -30px;
  }

  .cupon-content{
    position: relative;
    z-index: 1111111;
    // top: -25px;
    padding: 0px 20px;
    // margin-top: -20px;

    .cupon-desc{
      font-size: 16px;
      line-height: 20px;
      color: #000;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .cuponflex {
      display: flex;
      justify-content: space-between;
      text-align: center;
      background-color: rgb(219 219 219);
      padding: 0px 10px;
  }
    .cupon-code{
      font-size: 25px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
    .expidiv{
      text-align: center;
      margin-top: 10px;
    }

  }
  .hrbr{
    height: 1px;
    background-color: #000;
  }
  .cupon-footer {
    // background: rgb(137 137 137 / 41%);
    padding-top: 7px;
    margin-bottom: -10px;
    padding-bottom: 15px;
    z-index: 11111;
    .nameflex {
      display: flex;
      justify-content: space-between;
      padding: 0px 25px;
  }
  .hrbrflex{
    margin: 0 20px;
  }
}

}
.coupon-wrapper{
  background-color: #1e1e1e;
  border-radius: 30px;
  box-shadow: rgb(0 0 0) 0px 3px 8px;
  width: fit-content;
  // width: 400px;
  height: auto;
  position: relative;
  @media (max-width:767px){
    border-radius: 0px;
    width: 100%;
  }
  .thank-you-step{
    padding: 20px;
    // width: 400px;
    // width: fit-content;
    width: 100%;
    height: auto;
    // @media (min-width:1024px) and (max-height:650px){
    //   width: 360px;
    //   padding: 15px;
    // }
    @media (max-width:992px){
      width: auto;
      height: auto;
    }
    @media (max-width:767px){
      width: 100%;
      padding: 15px 12px;
    }
    .download-btn{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 5px;
      width: 100%;
      @media (max-width:767px){
        position: absolute;
        bottom:8px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
      .btn.sc{
        background-color: #FFB03D;
        padding: 8px 20px !important;
        span{
          @media (min-width:1024px) and (max-height:650px){
            font-size: 14px;
            line-height: 14px;
          }
        }
        &:hover{
          border-color: #FFB03D;
        }
      }
    }
    .cuponwrap{
      background-image: url(../img/cuponbg.png);
      background-size: 100% 100%;
      height: 100%;
      padding-bottom: 35px;
      @media (max-width:767px){
        padding-bottom: 38px;
      }
      .tokentitle{
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
        text-align: center;
        margin-bottom: 0px;
      }
      .cuponheader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 20px 0px 20px;
        @media (min-width:1024px) and (max-height:650px){
          padding: 25px 20px 0px 20px;
        }
        .irfs-logobw{
          width: 140px;
          display: block;
          margin-top: -15px;
        }
      }
      .cuponheaddiv{
        margin-top: 5px;
      }
      .cuponhead{
        font-size: 12px;
        line-height: 16px;
        color: #000;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 0px;
        &.exphead{
          margin-top: 18px;
          width: fit-content;
          position: relative;
          margin: auto;
          &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: -25px;
            transform: translateY(-50%);
            height: 1px;
            width: 20px;
            background-color: #000;
          }
          &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
            height: 1px;
            width: 20px;
            background-color: #000;
          }
        }
      }
      .expdate{
        font-size: 22px;
        font-weight: 700;
        color: #0D4286;
        margin-bottom: 0px;
        text-align: center;
      }
      .tokenid{
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 7px;
      }
      .tokenidnum {
        border-radius: 17px;
        background: #FFB03D;
        width: 80%;
        margin: auto;
        padding: 7px 30px;
        color: #0D4286;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
       
    }
      .offerdiv{
        margin-top: 50px;
        @media (min-width:1024px) and (max-height:650px){
          margin-top: 35px;
        }
        .text-offerdiv {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-around;
          .text-offer{
            color: #0D4286;
            text-align: center;
            font-size: 24px;
            font-style: italic;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            max-width: 320px;
            @media (max-width:640px) {
              font-size: 18px;
            }
          }
        }
        .text-offerdiv1{
          display: flex;
          width: 90%;
          justify-content: space-between;
          margin: auto;
          margin-top: -10px;
        }
        .cupon-proj{
          color: #000;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          margin-top: 10px;
          span{
            font-weight: 600;
          }
        }
        .logodiv{
          position: relative;
          .logodivflex {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 11;
            width: 100%;
            @media (max-width:767px){
              height: 195px;
            }
          }
          .celleft{
            width: 85px;
            @media (min-width:1024px) and (max-height:650px){
              width: 80px;
            }
            @media (max-width:992px){
              width: 75px;
            }
            @media (max-width:767px){
              width: 80px;
            }
          }
          .celright{
            width: 85px;
            @media (min-width:1024px) and (max-height:650px){
              width: 80px;
            }
            @media (max-width:992px){
              width: 75px;
            }
            @media (max-width:767px){
              width: 80px;
            }
          }
          .irfslogobg{
            position: absolute;
            top: 52%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 250px;
            height: 210px;
            margin-top: 10px;
            @media (min-width:1024px) and (max-height:650px){
              width: 210px;
              height: 170px;
              top: 45%;
            }
            @media (max-width:767px){
              top: 50%;
              width: 220px;
              height: 195px;
            }
          }
          .irfs-logo{
            display: block;
            margin: auto;
            width: 170px;
            margin-top: 10px;
            @media (min-width:1024px) and (max-height:650px){
              width: 150px;
              margin-top: 0px;
            }
            @media (max-width:767px){
              width: 160px;
              margin-top: 10px;
            }
          }
        }
      }
      .cuponfooter {
        background: #D9D9D9;
        padding: 0px 0px 0px 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        @media (min-width:1024px) and (max-height:650px){
          margin-top: 33px;
        }
        @media (max-width:992px){
          margin-top: 50px;
        }
        @media (max-width:767px){
          margin-top: 25px;
        }
        .uhead{
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 0px;
          span{
            font-weight: 400;
          }
        }
        .sirfs{
          margin-top: -70px;
          width: 210px;
          margin-right: -22px;
          @media (min-width:1024px) and (max-height:650px){
            width: 190px;
          }
          @media (max-width:767px){
            width: 198px;
            margin-right: -13px;
          }
        }
      }
    }
  }
}

.card-back {
  transform: rotateY(180deg);
  top: 0;
  z-index: 1111;
  background-color: #C0C0C0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 5% / 3.5%;
  background-size: cover;
  background-position: center;
  z-index: 2;
  overflow: hidden;
}

.card-face:before {
  // content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: .5;
  mix-blend-mode: color-dodge;
  transition: all .33s ease;
  z-index: 1;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  background-size: 300% 300%;
}

.cardrot.cuponcard {
  --color1: #fac;
  --color2: #ddccaa;
}

.cardrot.animated .card-face:before {
  // animation: holoGradient 12s ease 0s 3;
  opacity: 1;
  background-position: 100% 63%;
  filter: brightness(0.6) contrast(1);
  z-index: 1;
}

@keyframes holoGradient {
  0%, 100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(.5) contrast(1);
  }
  5%, 9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
  13%, 17% {
    background-position: 0% 0%;
    opacity: .88;
  }
  35%, 39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
}

.cardrot.animated {
  // animation: holoCard 12s ease 3;
}

@keyframes holoCard {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg); /* Flip to the backside */
  }
}
