@media (max-width: 991px) {
    .navbar {
      .navbar-brand-wrapper {
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .row-offcanvas {
      position: relative;
      -webkit-transition: all .25s ease-out;
      -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  
      &.row-offcanvas-right {
        right: 0;
  
        ::ng-deep .sidebar-offcanvas {
          right: -100%; // 12 columns
        }
  
        &.active {
          right: calc(50% + 30px); // 6 columns
  
          ::ng-deep .sidebar-offcanvas {
            right: calc(-50% - 15px); // 6 columns
          }
        }
      }
  
      &.row-offcanvas-left {
        left: 0;
  
        ::ng-deep .sidebar-offcanvas {
          left: -100%; // 12 columns
        }
  
        &.active {
          left: 50%; // 6 columns
  
          ::ng-deep .sidebar-offcanvas {
            left: -50%; // 6 columns
          }
        }
      }
  
      ::ng-deep .sidebar-offcanvas {
        position: absolute;
        top: 0;
        width: calc( 50% + 30px); // 6 columns
      }
    }
  }
  @media (max-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }

    .col-sm {
        flex: 1 0 0%
    }

    .col-sm-auto,.row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-12,.row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .col-sm-6,.row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-4,.row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-3,.row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .col-sm-2,.row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,.gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,.gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,.gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,.gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,.gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,.gy-sm-5 {
        --bs-gutter-y: 3rem
    }
  }