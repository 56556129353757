@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:wght@100..900&display=swap');

.urbanist-font {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.navbar {
  font-weight: $font-weight-light;
  background: $navbar-bg;
  transition: background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: background $action-transition-duration $action-transition-timing-function;
  -moz-transition: background $action-transition-duration $action-transition-timing-function;
  -ms-transition: background $action-transition-duration $action-transition-timing-function;

  .navbar-brand {
    width: 110px;
  }

  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $navbar-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;

    :host-context(.sidebar-dark) & {
      background: $sidebar-dark-bg;
    }

    @media (max-width: 991px) {
      width: 55px;
    }

    .navbar-brand {
      color: #27367f;
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: #27367f;
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px);
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }

      &.brand-logo-mini {
        display: none;

        img {
          width: calc(#{$sidebar-width-icon} - 50px);
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;

    @media (max-width: 991px) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      height: $navbar-height;
      padding-left: 5px;
      padding-right: 20px;

      &:not(.navbar-toggler-right) {
        font-size: 1.5rem;

        @media (max-width: 991px) {
          display: none;
        }
      }

      &.navbar-toggler-right {
        @media (max-width:991px) {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .search-field {
      .input-group {
        input {
          font-size: $default-font-size;
          padding: .5rem;
        }

        i {
          font-size: 17px;
          margin-right: 0;
          color: $navbar-menu-color;
        }

        .input-group-text {
          background: transparent;
        }
      }
    }

    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
      }

      .count-symbol {
        top: 18px;
        right: 0px;
        width: 7px;
        height: 7px;
      }

      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center;
      }

      &:after {
        display: none;
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;

      .nav-item {
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          height: $navbar-height;
          cursor: pointer;
          display: flex;
          align-items: center;

          @media (max-width: 767px) {
            margin-left: .8rem;
            margin-right: .8rem;
          }

          i {
            font-size: $navbar-icon-font-size;
          }
        }

        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            font-weight: $font-weight-light;

            &:focus {
              box-shadow: none;
            }

            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }

          .dropdown-menu {
            margin-top: 0;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);

            &.navbar-dropdown {
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                margin-bottom: 0;
                padding: 11px 13px;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .rtl & {
                  i {
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }

          @media (max-width: 767px) {
            position: static;

            .navbar-dropdown {
              top: $navbar-height;
              width: 100%;
            }
          }
        }

        &.nav-settings {
          padding-left: 20px;
          padding-right: 5px;

          .nav-link {
            margin-right: 0;
            margin-left: 0;
            background-color: transparent;
          }
        }

        &.nav-profile {
          .nav-link {
            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;

              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }

              .availability-status {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 2px solid #ffffff;
                bottom: 5px;
                right: -5px;

                &.online {
                  background: theme-color(success);
                }

                &.offline {
                  background: theme-color(danger);
                }

                &.busy {
                  background: theme-color(warning);
                }
              }
            }

            .nav-profile-text {
              margin-left: 1.25rem;

              .rtl & {
                margin-left: 0;
                margin-right: 1.25rem;
              }

              p {
                line-height: 1;
              }

              @media (max-width: 767px) {
                display: none;
              }
            }

            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }

      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;

          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .navbar {
    flex-direction: row;

    .navbar-brand-wrapper {
      width: 75px;

      .navbar-brand {
        &.brand-logo {
          display: none;
        }

        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;

      .brand-logo-mini {
        padding-top: 0px;
      }
    }
  }
}

/* Navbar color variations */
@each $color, $value in $theme-gradient-colors {
  .navbar {
    &.navbar-#{$color} {
      .navbar-menu-wrapper {
        background: $value;
        color: $white;

        .nav-profile-text {
          p {}
        }

        .search-field {
          .input-group {
            input {}

            i {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.header {
  &.irfsheader {
    @media (max-width: 992px) {
      background-color: #FFB03D !important;
      box-shadow: 0px 3px 6px #FFFFFF28;
    }

    .btn {
      &.sc {
        background-color: #FFB03D !important;
      }
    }

    .searchBtn {
      background-color: #FFB03D !important;
      right: 6px;
    }

    .cityDropdown {
      color: #FFB03D !important;
    }

    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
      background-color: #FFB03D !important;
    }

    .searchPopup .nextBtn {
      background-color: #FFB03D !important;
    }
  }

  &-btns {
    .btn-two {
      .dropdown-toggle::after {
        display: none;
      }

      ul.nav-linkss.show {
        display: flex;
        flex-direction: column;
        gap: 125px;
        background-color: #F5F6F7;
        max-height: calc(100vh - 120px);

        li {
          font-size: 16px;
          font-weight: 400;
          color: #252525;
          line-height: 38px;
        }
      }
    }
  }

  .login-main {
    background-color: #F5F6F7;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;

    .login-btn {
      border-bottom: 1px solid #E6E7E8;
      border-radius: 6px;
      gap: 20px;

      &:hover {
        background-color: #43A66A;
        color: #ffffff;

      }
    }
  }

  .search-header {
    // max-width: 400px;
    // border: 1px solid #B3B2B2;
    border-radius: 25px;
  }

  .nav-flex-box {
    gap: 3rem;

    @media ($max1024) {
      gap: 2rem;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    .search-header {
      width: auto;
      border: unset;
      border-radius: unset;
    }
  }
}

@media ($max992) {
  .header {
    .search-header {
      width: auto;
      border: unset;
      border-radius: unset;

      .flex-1 {
        flex: 1 1 auto;
      }
    }
  }

}

@media (max-width: 767px) {
  .header-img {
    img {
      width: 153px;
    }
  }

  .header {
    .search-header {
      width: auto;
      border: unset;
      border-radius: unset;

      .flex-1 {
        flex: 1 1 auto;
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .navbar-toggler {
    padding: 5px;
  }

  .header-btns {
    .searchIcon img {
      @media ($max640) {
        margin-top: 0px;
      }
    }

    .btn-two {
      ul.nav-linkss.show {
        gap: 30px
      }

    }
  }
}

@media (max-width: 767px) {
  .header-img {
    img {
      width: 150px;
    }

    .secondlogo {
      width: 85px;
    }
  }
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.corporate-btn {
  background-color: #192951 !important;
  margin-right: 15px;

  @media ($max1024) {
    margin-right: 0;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 9999;
  // border-bottom: 1px solid #D6D6D6;
  box-shadow: 0px 3px 6px #00000015;

  @media (max-width: 767px) {
    height: auto;
  }
}

#myHeader {
  transition: all .4s ease-in-out;

  .search-wrap {
    top: unset;
  }

  .custompadding {
    padding: 0 1.5rem;

    @media ($max640) {
      padding: 5px 1rem;
    }
  }
}

.user-name {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  font-weight: 600;
  line-height: 45px;

  @media (max-width:767px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }
}

.user-drop-btn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: -14px;
  top: 50%;

  @media (max-width:767px) {
    right: -10px;
  }
}

.header-btns {
  gap: 1.5rem;

  @media ($max1024) {
    gap: 1rem;
  }

  @media ($max640) {
    gap: 0.5rem;
  }

  .dropdown-menu.show {
    left: -110px !important;
  }

  .btn-flex-box {
    gap: 10px;
  }
}

.user-drop-btn:hover,
.user-drop-btn:focus,
.user-drop-btn:active {
  background: transparent;
  background-color: transparent !important;
  color: #252525 !important;
}

.select2-container--open {
  .select2-dropdown--below {
    z-index: 1000000000;
    border: 0px;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
    // border-radius: 0 0 30px 30px;
  }

  .select2-dropdown--above {
    z-index: 1000000000;
    border: 0px;
    filter: drop-shadow(0px -5px 5px rgba(0, 0, 0, 0.25));
    // border-radius: 30px 30px 0 0;
  }
}

.header {

  .select2-container {
    border: solid black 1px;
    outline: 0;
    padding: .6rem 90px .6rem 80px !important;
    padding-bottom: 5px !important;
    border-radius: 100px !important;
    white-space: nowrap;
    // overflow: auto;
    scrollbar-width: none;
    width: 100% !important;
    // max-width: 71vw;
    transition: all 0.2s ease-in-out;
    // @media (max-width:1600px) {
    //   max-width: 65vw;
    // }
    // @media (max-width:1440px) {
    //   max-width: 61vw;
    // }
    // @media (max-width:1280px) {
    //   max-width: 56vw;
    // }
    // @media (max-width:1200px) {
    //   max-width: 54vw;
    // }

    @media (max-width:1024px) and (min-width:993px) {
      max-width: 35vw;
    }
    @media (max-width:992px) {
      max-width: unset;
    }

    &.select2-container--open {
      border-radius: 20px 20px 0 0 !important;
    }
  }

  .searchBtn {
    // right: 80px;
    right: 6px;
    padding: 8px 20px;
    display: flex;
    position: absolute;
    transform: unset;
    font-size: 16px;
    height: 42px;
    top: 50%;
    transform: translateY(-50%);
  }

  .select2-container--default .select2-selection--multiple {
    border: 0px;
    padding-bottom: 5px;
    // width: auto !important;
    // max-width: inherit;
    // white-space: nowrap;
    // /* Prevent line breaks */
    // overflow-x: auto;
    // /* Enable horizontal scrolling */
    // overflow-y: hidden;
    // @media ($max1024) {
    //   width: 100% !important;
    // }
    // &::-webkit-scrollbar {
    //   width: 1px;
    //   height: 1px;
    //   border-radius: 1px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: #ededed;
    //   border-radius: 1px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: grey;
    //   border-radius: 1px;
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //   background: grey;
    //   border-radius: 1px;
    // }
  }

  span.select2-search.select2-search--inline {
    height: 30px;
    display: inline-block;
    width: 100% !important;

    textarea {
      margin-top: 0px !important;
      height: 30px !important;
      line-height: 32px;

      @media ($max640) {
        font-size: 14px;
      }
    }
  }
}

.header.irfsheader .select2-container {
  // max-width: 77vw;
  // @media (max-width:1600px) {
  //   max-width: 73vw;
  // }
  // @media (max-width:1440px) {
  //   max-width: 69vw;
  // }
  // @media (max-width:1280px) {
  //   max-width: 66vw;
  // }
  // @media (max-width:1200px) {
  //   max-width: 64vw;
  // }
  @media ($max1024) and (min-width:993px) {
    max-width: 40vw !important;
  }
  @media (max-width:992px) {
    max-width: unset;
  }
}

@media screen and (max-width: 992px) {
  header {
    .global-header {
      position: absolute !important;
      top: 80px;
      background-color: #ECF6F0;
      width: 100%;
      left: 0;
      border-bottom: 1px solid #43a66a;
      border-top: 1px solid #43a66a;
    }

    .cityDropdown {
      // left: 0px;
      background-color: #ECF6F0;
      // position: relative;
      // transform: unset;
      // padding: 0 10px;
    }
  }

  .hero-searchmain {
    .input-group {
      display: block;
      width: 100%;
    }

    .select2-container {
      width: 100%;
      display: block;
    }

    .select2-selection__rendered .select2-selection__choice button span {
      top: 4px !important;
      ;
    }
  }

  .select2-dropdown {
    z-index: 999999999 !important;
  }

  .select2-container--open .select2-dropdown--below {
    border-top: 1px solid;
  }

  .search-header {
    .select2-container--default .select2-selection--multiple {
      background-color: transparent;
    }

    .select2-container {
      border: 0px solid;
      padding: 0.6rem 10px 0.6rem 10px !important;
    }
  }
}

.search-component .select2-selection {
  @media (max-width:992px) {
    width: 97%;
    padding: 10px 10px 17px 80px;
    height: 65px;
    margin: 0px 12px;
    text-align: center;
    --bs-gutter-x: 0rem;
  }

  @media (max-width:767px) {
    width: 93%;
  }
}

@media ($max640) {
  header {
    .global-header {
      top: 60px;
    }
  }
}

.cityDropdown {
  position: absolute;
  z-index: 2;
  border: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: #43A66A;
  font-weight: 700;
  outline: none;
  pointer-events: none; //temporary to disable dropdown event
  appearance: none;
  /* For modern browsers */
  -webkit-appearance: none;
  /* For Safari and Chrome */
  -moz-appearance: none;
  /* For Firefox */

  @media screen and (max-width: 767px) {
    left: 30px;
  }
}

.searchBtn {
  background-color: #43A66A;
  padding: 15px 30px;
  color: #fff;
  position: absolute;
  z-index: 2;
  right: 11px;
  border-radius: 48px;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
}