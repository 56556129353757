
.discimgoffer{
    position: relative;
    .discimg{
        @media (max-width:767px){
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 25px;
        }
    }
    .carousel{
        @media (max-width:767px){
            padding-left: 30px;
        }
    }
}
@media (max-width:767px){
    .irfsactive-mob{
        display: block;
        margin-top: 0px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        z-index: 1111;
        box-shadow: 0px -4px 6.5px 0px rgba(0, 0, 0, 0.25);
        transition: transform 0.3s ease-in-out;
        @media (max-width:767px){
            margin-top: 5px;
            padding: 2px 15px;
            padding-bottom: 40px;
            bottom: 50px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 6px;
        }
        &.slide-up {
            transform: translateY(0);
          }
        
          &.slide-down {
            transform: translateY(90%);
          }
        .offerdesc{
            font-size: 12px;
            margin-bottom: 0px;
        }
        .irfsdisc{
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 0px;
            span{
                font-weight: 600;
            }
            @media (max-width:767px){
                font-size: 14px;
                font-weight: 700;
            }
            @media (max-width:370px){
                font-size: 12px;
                font-weight: 700;
            }
        }
        .dflex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 30px 6px 0px;
            @media (max-width:767px){
                padding: 6px 0px 6px 0px;
                flex-direction: row-reverse;
            }
            .carousel{
                width: 240px;
                @media (max-width:767px){
                    width: 200px;
                }
            }
        }
        .btnflex {
            display: flex;
            align-items: center;
            .cupon-btn{
                background: #FFB03D;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.07px;
                padding: 8px 17px 10px;
                border-radius: 0px;
                @media (max-width:767px){
                    font-size: 11px;
                    padding: 5px 8px;
                    border-radius: 8px;
                }
            }
        }
    }
}
.pdp-class{
    .project-main{
        @media (max-width:767px){
            margin-top: 0rem!important;
            padding-top: 0rem!important;
        }
    }


    .proj-overview-head-wrapper .proj-overview-head.sticky{
        @media (max-width:767px){
            top: 60px;
        }
    }
    .btn.sc {
        background: #FFB03D;
    }
    .textorange{
        color: #FFB03D!important;
        font-weight: 500;
    }
    .project-detail-main .realestate-form #enquiry-form-pdp .form-check {
        margin-bottom: 0.325rem;
    }
    .requesttitle{
        color: #ffb03d!important;
    }
    .border-primary{
        border: 1px solid #FFB03D!important;
    }
    .pdpcolor{
        color: #FFB03D!important;
    }
    .project-detail-main{
        @media (max-width: 767px) {
            margin-top:0rem!important;
        }
        .plp-card-detail{
            .plp-price-cards.parent{
                .card{
                    .card-header{
                        background-color: #FED79A;
                    }
                }
            }
        }
        .pdp-details-card{
            .image-side{
                @media (max-width: 767px) {
                    border-radius: 0px;
                    height: 330px!important;
                    padding-bottom: 10px;
                    box-shadow: none;
                }
                .carousel-item{
                    @media (max-width: 767px) {
                        padding-bottom: 10px;
                    }
                    img{
                        @media (max-width: 767px) {
                            border-radius: 0px 0px 42px 42px;
                            height: 300px!important;
                            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        }
                    }
                }

                .carousel-indicators{
                    @media (max-width: 767px) {
                        display: flex;
                        bottom: -25px;
                    }
                    [data-bs-target]{
                        @media (max-width: 767px) {
                            background-color: #D4D4D4;
                            opacity: 1;
                        }
                    }
                    .active{
                            @media (max-width: 767px) {
                                background-color: #FFB03D;
                            }
                    }
                }
                .project-img{
                    height: 100% !important;
                }
            }
            .extraGalleryImg{
                @media (max-width: 767px) {
                    display: none!important;
                }
            }
            .bhk-color.active {
                border-color: #FFB03D !important;
                background-color: #FFB03D;
            }
            @media(max-width:767px){
                height: 100%!important;
            }
        }
        .rera-main-div{
            background-color: #FFB03D;
            @media (max-width: 767px) {
                display: none!important;
            }
            .pos-date{
                background-color: #FFB03D;
            }
            .top-picks{
                color: #FFB03D;
            }
        }
    }
    .tabcontentirfs{
        display: block;
        @media (max-width:767px){
            margin-top: 20px!important;
        }
        .shortlisted svg #Icon_ionic-ios-heart{
            fill: #F8990C;
        }
        .shortlisted svg #Ellipse_112 circle{
            fill: #F8990C;
        }
        .irfs-exl {
            display: inline-block;
            position: absolute;
            top: 0px;
            left: 10px;
            margin: 20px 0;
            z-index: 1111;
            @media (max-width: 767px){
                top: -10px;
                left: 5px;
            }
            img{
                width: 87px!important;
                @media (max-width: 767px){
                    width: 70px!important;
                    height: auto!important;
                }
            }
        }
        .plp-card{
            position: relative;
            @media ($max640) {
                margin: auto;
            }
            &.insideCardWrapper{
                @media ($max640) {
                    margin: 0;
                }
            }
            .image-side{
                border-radius: 0px;
                max-width: 100%;
                width: 100%;
                height: 100%!important;
                z-index: 111;
                @media (max-width: 767px){
                    height: 300px!important;
                    padding-bottom: 0px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(12, 12, 12, 0) 0%, rgba(3, 3, 3, 0.77) 64.5%, #000000 100%);
                    width: 100%;
                    height: 87px;
                    @media (max-width: 767px){
                        background: linear-gradient(180deg, rgba(12, 12, 12, 0) 0%, rgba(3, 3, 3, 0.77) 85%, #000000 100%);
                        height: 80px;
                    }
                }
                .carousel-indicators {
                    display: flex;
                    bottom: 30px;
                    [data-bs-target] {
                        width: 40px !important;
                        height: 7px !important;
                        border-radius: 4px;
                        background: #7F7F7F;
                        border-top: none;
                        border-bottom: none;
                        opacity: 1;
                    }
                    .active {
                        background: #fff;
                    }
                }
                .carousel{
                    height: 100%;
                    .carousel-inner{
                        height: 100%;
                        .carousel-item{
                            height: 100%;
                            img{
                                @media (max-width:767px){
                                    border-radius: 0px 0px 0px 0px;
                                }
                            }
                        }
                    }
                }
                img {
                    border-radius: 0px;
                    width: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    height: 100%;
                    // aspect-ratio: 3/2;
                }
                .elevationImg{
                    aspect-ratio: 3/2;
                }
                .project-img{
                    height: 100%!important;
                }
            }
        }
        .project-right-content {
            background-color: unset;
            display: flex;
            align-items: center;
            justify-content: start;
            z-index: 11;
            @media ($max1024) {
                justify-content: center;
                text-align: center;
            }
        }
        .plp-card-main {
            background: linear-gradient(93.79deg, #FFDF85 4.04%, #FBF8E6 45.62%, #FFDF85 96.84%);
            border-radius: 20px;
            padding: 0px;
            overflow: hidden;
            min-height: 520px;
            height: auto;
            position: relative;
            display: grid;
            grid-template-columns: 50% 50%;
        }
        .plpcard-name, .developer-name, .discount {
            @media (max-width: 1024px) {
                text-align: center;
            }
        }
        .btnflex{
            @media (max-width: 1024px) {
                justify-content: center;
            }
        }
    }
    .tabcontentproject{
        display: none;
    }
    .btn-theme {
        background-color: #FFB03D !important;
        border-color: #FFB03D !important;
    }
    .review-btn{
        background-color: #FFB03D !important;
        border-color: #FFB03D !important;
    }
    .fill-review-form .project-parameter-list p input:checked ~ label{
        background-color: #FFB03D;
    }
    .pdp-main{
        .pdplist-background{
            background-color: rgba(255, 177, 61, 0.52); 
        }
        .proj-overview-head{
            .btn.active {
                border-bottom: 3px solid #FFB03D;
            }
        }
        .releated-dev-wrap{
            .releated-dev-inner {
                background: #FFB03D 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 10px #00000029;
                img {
                    bottom: -13px;
                    height: auto;
                    width: 308px;
                    left: 55%;
                    transform: translateX(-50%);
                    position: relative;
                }
            }
        }
        .highlight-ul li::before {
            background-color: #FFB03D;
        }
        .wishlist-banner{
            .wishlist-inner {
                margin-top: -35px;
                padding: 0px 15px;
            }
        }
        .highligh-img{
            .inner-wrap:after {
                content: unset;
            }
        }
        .amenitiesNearBywrap{
            .amenitiesNearBy{
                ul{
                    li.active {
                        background-color: #FFB03D;
                    }
                }
            }
        }
        .tab__item.is--active {
            background-color: #FFB03D;
        }
        .tab__item {
            background-color: #FED79A;
        }
        .plp-card-main {
            // background: linear-gradient(93.79deg, #FFDF85 4.04%, #FBF8E6 45.62%, #FFDF85 96.84%);
            @media (max-width: 1024px) {
                padding: 0px;
                display: flex!important;
                flex-direction: column-reverse;
            }
            &.insideCard{
                @media (max-width: 1024px) and (min-width: 768px) {
                    display: grid!important;
                    padding: 16px;
                }
            }
        }
        .fill-review-form .recommend-friend input{
            accent-color:#FFB03D;
        }
        .plpcard-name {
            font-size: 48px;
            font-weight: 400;
            line-height: 56px;
            margin-bottom: 15px;
            display: block;
            @media ($max1024) {
                width: 100%;
                font-weight: 600;
            }
            @media (max-width:767px){
                font-size: 34px;
                line-height: 45px;
                &.innerTitle{
                    width: 80%;
                }
            }
        }
        .configflex{
            @media ($max1024) {
                justify-content: center;
            }
            .bhkp{
                @media ($max1024) {
                    width: unset;
                }
            }
        }
        .developer-name {
            color: #2E2F38;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.07px;
            @media (max-width:767px){
                font-size: 13px;
                line-height: 18.07px;
            }
        }
        .discount {
            font-size: 72px;
            font-weight: 600;
            line-height: 80px;
            margin-bottom: 25px;
            @media (max-width:767px){
                font-size: 38px;
                line-height: 42px;
                margin-top: 10px;
            }
        }

        .btnflex {
            display: flex;
            align-items: center;
            .cupon-btn{
                background: #2E2F38;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.07px;
                padding: 8px 17px 10px;
                border-radius: 0px;
                @media (max-width:767px){
                    font-size: 12px;
                    padding: 5px 8px;
                    border-radius: 8px;
                }
            }
        }
        .hurryCoupon{
            margin-top: 10px;
            .btnflex {
                display: flex;
                align-items: center;
                @media (max-width:767px) {
                    justify-content: center;
                }
                .cupon-btn{
                    background: #fff;
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22.07px;
                    padding: 8px 17px 10px;
                    border-radius: 24px;
                    @media (max-width:767px){
                        font-size: 12px;
                        line-height: normal;
                        padding: 8px 8px;
                    }
                }
            }
        }
        .disclaimerTxt{
            font-size: 20px;
            @media ($max640) {
                font-size: 12px;
                text-align: center;
            }
        }
        .secondcupon{
            .cupon-btn{
                background-color: #FFB03D;
            }
        }
        .vector1{
            position: absolute;
            top: -2px;
            right: -205px;
        }
        .vector2{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }
    .brochure-wrap{
        .brochure-inner{
            .user-info{
                border-bottom: 1px solid #FFB03D;
                .user-name-info{
                    p{
                        span {
                            color: #FFB03D;
                        }
                    }
                }
                .nameTitle {
                    color: #FFB03D;
                    border: 2px solid #FFB03D;
                }
            }
            .download-btn-info{
                .happy-face{
                    svg {
                        background-color: #FFB03D;
                    }
                }
            }
        }
    }
    .dnone{
        .plpcard-name {
            font-size: 26px;
            line-height: 36px;
            font-weight: 700;
            @media (max-width:767px){
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 3px;
            }
        }
    }
    .lmore{
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        color: #2E2F38;
        font-size: 18px;
        font-weight: 400;
        line-height: 22.07px;
        @media (max-width:767px){
            margin-left: 12px;
            font-size: 14px;
        }
        &::after{
            content: '';
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            right: -35px;
            width: 34px;
            height: 30px;
            background-image: url('../img/arrow-more.svg');
            background-size: contain;
            background-repeat: no-repeat;
            @media (max-width:767px){
                right: -29px;
                width: 28px;
                height: 28px;
            }
        }
    }
    .closebtn{
        position: absolute;
        top: -10px;
        right: -10px;
        background: #FFB03D;
        color: #fff;
        padding: 2px 10px;
        border-radius: 100%;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
        box-shadow: 2px 2px 5px gray;  

    }

    .wishlist-banner .inner h3 {
        @media (max-width:767px){
            font-size: 26px;
            line-height: 32px;
            margin-top: 40px;
        }
    }
    .map-card-btn-wrapper{
        .map-card-btn{
            background-color: #FFB03D;
            border-color: #FFB03D;
        }
    }
}