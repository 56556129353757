.overlayCreateUser {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    display: none;
    overflow-x: hidden;
    overflow-y: scroll;
    .overlay-content-createUser {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        max-width: max-content;
        // display: flex;
        align-items: center;
        transition: transform 0.3s ease-in-out;
        @media (max-width:1366px) and (min-width:1025px){
            transform: translate(-50%, -50%) scale(0.85);
        }
        @media (max-width:767px){
            max-width: 100%;
            display: block;
        }
    }
    .close{
        top: 4px;
        right: 10px !important;
        font-size: 45px;
        background-color: #fff;
        border-radius: 100%;
        line-height: normal;
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
          @media (max-width:992px) {
              font-size: 35px;
              top: 10px;
              right: 10px !important;
              width: 30px;
              height: 30px;
          }
          @media (max-width:767px) {
            top: 9px;
            right: 7px !important;
          }
      }
}