.popupShareBtn {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}
.shareBtn {
    background-color: #192951;
    border-radius: 100%;
    padding: 6px 6px 7px;
    margin-left: 10px;
    @media (max-width:767px){
        padding: 6px 5px 6px;
    }
}
.popup {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s linear, opacity 0.1s ease;
    opacity: 0;
    position: absolute;
    top: 35px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.popup.show {
    max-height: 500px;
    opacity: 1;
    z-index: 111;
}
.popupShareBtn::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 5px; /* Adjust this value to align the arrow with the button */
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}

.popupShareBtn-content {
    display: flex;
    align-items: center;
    gap: 10px;
    .social-icons{
        display: grid;
        grid-gap: 10px 0;
        button{
            background: none;
            border: none;
            cursor: pointer;
            padding: 5px;
            &:hover{
                transform: scale(1.1);
            }
            &.close-btn{
                background: none;
                border: none;
                font-size: 16px;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
}
