.devListing{
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, transparent 10%); /* Adjust the size and color of the dots */
    background-size: 50px 50px; /* Adjust the size of the dots and spacing */
    background-repeat: repeat;
    background-position: center;
    padding: 70px 0;
    @media ($max992) {
        padding: 30px 0;
    }
}
.developer-box-container {
    padding: 10px 0;
    .slick-slide{
        padding: 0 10px;
        @media ($max640) {
            padding: 0 20px;
        }
    }
    .slick-arrow{
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    .slick-prev, .slick-next {
        color: #000; // Customize arrow colors
        z-index: 1;  // Make sure they appear above the carousel content
    }

    .slick-dots {
        bottom: -30px; // Position of dots (if you're using dots)
        li{
            &.slick-active button:before {
                opacity: .75;
                color: #FFB03D;
            }
        }
    }

    .developer-box-grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;
        margin: 15px 20px;
        @media ($max1024) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media ($max992) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media ($max640) {
            grid-template-columns: auto;
        }
    }

    .developer-card {
        // padding: 20px;
        // border: 1px solid #ddd;
        // border-radius: 8px;
        text-align: center;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        // min-height: 220px;

        .infoWrapper{
            // width: 100%;
            // height: 100%;
            // display: grid;
            // grid-template-rows: 1fr 1fr;
            // align-items: center;
            // gap: 10px 0;
            padding-block: 5px;
            .imgWrap{
                // margin: 0 auto;
                text-align: center;
                border: 1px solid #ffb03945;
                border-radius: 20px;
                background-color: #fff;
                box-shadow: 0px 0px 13px 1px #ffb03931;
                
                .developer-logo {
                    aspect-ratio: 3 / 2;
                    margin: 0 auto;
                    width: 240px;
                    max-width: 100%;
                    padding: 10px;
                    object-fit: contain;
                    object-position: center center;
                    vertical-align: middle;
                    @media ($max640) {
                        width: 220px;
                    }
                }
            }
    
            .developer-name {
                font-size: 18px;
                font-weight: 600;
                color: #000;
                line-height: 23px;
                margin-bottom: 0;
            }
        }
    }
    a{
        text-decoration: none;
    }
}
