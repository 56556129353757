.sec-head {
    font-size: 44px;
    font-weight: 700;
    line-height: 54px;
    @media (max-width:767px){
        font-size: 32px;
        line-height: 39px;
    }
}
.btn-darkblue{
    background-color: #192951;
    color: #fff;
    &:hover{
        background-color: #192951;
        color: #fff;
    }
}
.btn-bk-home{
    border-radius: 12px!important;
    &:hover{
        background-color: #192951!important;
        color: #fff;
    }
}

.proconimg{
    height: 100%;
    border-radius: 40px;

    @media screen and (max-width:767px) {
        border-radius: 40px 40px 0 0;
    }
}
.subtractbg{
    .patternbg{
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width:767px){
            padding: 0 10px;
        }
    }
    .subtractbgcontainer{
        background: #F0F0F0;
        border-radius: 80px;
        padding-top: 60px;
        position: relative;
        // &::before{
        //     content: '';
        //     position: absolute;
        //     width: 20%;
        //     height: 60%;
        //     bottom: 0;
        //     left: 0;
        //     background: #fff;
        // }
        @media (max-width:992px){
            padding-top: 50px;
        }
        @media (max-width:767px){
            border-radius: 30px;
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column-reverse;
        }
    }
    .bnrrow2 {
        position: relative;
        z-index: 111;
        align-items: flex-end;
        padding-top: 20px;
        padding-bottom: 40px;
        @media ($max640) {
            padding-bottom: 10px;
            padding-top: 0;
        }
        // padding-left: calc(var(--bs-gutter-x)* 0.5);
        // @media (min-width:767px) and (max-width:1080px){
        //     align-items: flex-start;
        // }
    }
    .greenbgdiv {
        position: relative;
        width: fit-content;
        @media ($max640) {
            width: 100%;
        }
        // top: 25px;
        // left: -12px;
        // @media (max-width:1400px) and (min-width:1100px) {
        //     top: 65px;
        //     left: -10px;
        // }
        // @media (max-width:992px){
        //     top:60px;
        // }
        // @media (max-width:767px){
        //     width: 100%;
        //     top:30px;
        // }
        // @media(max-width:540px){
        //     top: -10px;
        // }
        .greenbgcont {
            position: absolute;
            width: 100%;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding-left: 20px;
            padding-right: 15px;
            @media (max-width:767px){
                padding-left: 10px;
                position: relative;
                transform: unset;
                top: unset;
                left: unset;
            }
            .intro {
                color: #fff;
                font-size: 24px;
            }
            .bnrsubhead {
                font-size: 40px;
                line-height: 46px;
                color: #fff;
                font-weight: 700;
                @media (max-width :1024px) {
                    font-size: 20px;
                    line-height: normal;
                }
                @media (max-width:992px){
                    font-size: 16px;
                    line-height: 23px;
                }
                @media(max-width:767px){
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .greenbg{
            @media (min-width:992px) and (max-width:1400px){
                width: 380px;
            }
        }
        .explore-project-btn {
            border-radius: 25px;
            background-color: #192951;
            height: 43px;
            color: #fff;
            padding: 10px 15px;
            text-decoration: none;
            font-size: 14px;
            width: fit-content;
            @media (max-width:992px){
                font-size: 10px;
                height: 37px;
                padding: 10px;
            }
            @media ($max640) {
                margin-bottom: 0 !important;
            }
            img{
                margin-top: -4px;
                margin-left: 5px;
                @media (max-width:767px){
                    width: 12px;
                }
            }
        }
    }
    .banner-head{
        font-size: 65px;
        font-weight: 700;
        line-height: 75px;
        // @media (min-width:1100px) and (max-width:1400px){
        //     font-size: 70px;
        //     line-height: 80px;
        // }
        @media (min-width:993px) and (max-width:1080px){
            font-size: 50px;
            line-height: normal;
        }
        @media (max-width:992px){
            font-size: 32px;
            line-height: 36px;
        }
        @media ($max640) {
            padding-bottom: 5px;
        }
    }
    .bnrrow{
        position: relative;
        z-index: 11;
        align-items: center;
        @media (max-width:767px){
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            gap: 10px 0;
        }
        // div{
        //     &:first-child{
        //         @media ($max640) {
        //             order: 2;
        //         }
        //     }
        // }
        .greenMobBox{
            @media ($max640) {
                background: #42B57E;
                padding: 10px;
                border-radius: 30px;
            }
        }
        .bwicon{
            width: 230px;
            @media (min-width:1100px) and (max-width:1400px){
                width: 145px;
            }
            @media (max-width:992px){
                width: 90px;
            }
            @media (min-width:993px) and (max-width:1080px){
                width: 140px;
            }
        }
    }
    .banner-slider-wrap{
        .slick-slide {
            padding: 0px 10px;
        }
        .looking-card-inner {
            .looking-for-card {
                // margin: 25px 0;
                cursor: pointer;
                @media (min-width:1100px) and (max-width:1400px){
                    margin: 0px;
                }
                @media (min-width:993px) and (max-width:1080px){
                    margin-top: 10px;
                }
                .looking-for-img{
                    // padding: 40px 0;
                    img{
                        // width : 828px;
                        // height: 548px;
                        width: 100%;
                        aspect-ratio: 3/2;
                        height: auto;
                        object-fit: cover;
                        border-radius: 30px;
                        // @media (max-width:1080px){
                        //     width: 100%;
                        //     height: auto;
                        // }
                        // @media screen and (max-width: 992px) {
                        //     width: 100%;
                        //     height: 220px;
                        // }
                    }
                }
            }
        }
        .slick-arrow{
            width: 80px;
            height: 80px;
            z-index: 11;
            background-size: contain;
            background-repeat: no-repeat;
            &::before{
                content: unset;
            }
            @media (max-width:767px){
                width: 40px;
                height: 40px;
            }
        }
        .slick-prev{
            left: 20px;
            background-image: url('../../Assets/img/prev-btn.svg');
        }
        .slick-next{
            right: 20px;
            background-image: url('../../Assets/img/next-btn.svg');
        }
    }
}

.rightcol {
    background-color: #F6F6F6;
    height: 100%;
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0px 60px;
    @media (max-width:992px){
        padding: 0px 10px 20px;
       
        box-shadow: 0px 2px 12px 0px #00000040;
        border-radius: 20px;
    }
    @media(max-width:767px){
        text-align: center;
    }
    &.container{
        @media (max-width:992px){
            margin-top: 40px;
        }
    }
    .price-box {
        position: absolute;
        top: 30px;
        right: 30px;
        @media (max-width:992px){
            position: unset;
        }
        .price {
            font-size: 45px;
            font-weight: 700;
            line-height: 55px;
            @media (max-width:992px){
                font-size: 20px;
                line-height: 50px;
            }
        }
    }
    .sec-head {
        margin-bottom: 20px;
        margin-top: 50px;
        @media (max-width:992px){
            margin-top: 30px;
        }
    }
}
.location-price-img{
    width : 306px;
    height: 694px;
    object-fit: cover;
    border-radius: 80px;
    @media screen and (max-width: 992px) {
        border-radius: 20px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        margin-top: 30px;
        border-radius: 30px;
    }
}


.dream{
    background-color: #42B57E;
    border-radius: 63px;
    @media screen and (max-width: 992px) {
        border-radius: 30px;
        text-align: center;
        padding-top: 30px;
    }
    .housebox{
        @media screen and (max-width: 992px) {
            margin-top: 90px;
        }
    }
    .family{
        margin-bottom: -50px;
        z-index: 11;
        position: relative;
    }
    .housebr{
        position: absolute;
        top: -30px;
        @media screen and (max-width: 992px) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}


.location{
    @media (max-width:992px){
        margin: 0 15px;
    }
    .loc-container{
        background-color: #F6F6F6;
        border-radius: 80px;
        padding: 60px;
        @media (max-width:992px){
            padding: 40px 0px 90px;
            box-shadow: 0px 0px 15px 0px #0000001A;
            border-radius: 20px;
        }
        .loc-head{
            font-size: 44px;
            line-height: 54px;
            margin-bottom: 30px;
            @media (max-width:992px){
                font-size: 32px;
                line-height: 39px;
                text-align: center;
            }
            span{
                font-weight: 700;
            }
        }
        .location-sliderwrap{
            .slick-slider{
                margin-left: -20px;
                @media (max-width:992px){
                    margin-left: 0px;
                }
                .slick-track{
                    display: flex!important;
                    .slick-slide{
                        height: inherit!important;
                        border-radius: 40px;
                        padding:20px 30px;
                        background-color: #fff;
                        margin: 0 10px;
                        transition: all 0.5s linear;
                        @media (max-width:992px){
                            padding: 20px 15px;
                        }
                        @media (max-width:767px){
                            background: #42B57E;
                            color: #fff;
                        }
                        .loc-slide{
                            height: 100%;
                            .loc-box{
                                .loc-no {
                                    font-size: 70px;
                                    text-align: right;
                                }
                                .loc-title {
                                    font-size: 34px;
                                    @media (max-width:992px){
                                        font-size: 24px;
                                    }
                                }
                                .loc-desc{
                                    margin-top: 10px;
                                    li{
                                        margin: 8px 0px;
                                    }
                                }
                            }
                        }
                        &.slick-current{
                            background-color: #42B57E;
                            color: #fff;
                        }
                    }
                }
            }
            .slick-arrow{
                &::before{
                    content: unset;
                }
                &.slick-next{
                    width: 100px;
                    height: 100px;
                    right: -30px;
                    background-image: url('../../Assets/img/next.svg');
                    background-size: contain;
                    @media (max-width:992px){
                        width: 44px;
                        height: 44px;
                        right: -30px;
                        bottom: -60px;
                        top: unset;
                        transform: translateX(-50%);
                        left: 60%;
                        box-shadow: 0px 0px 4px 0px #00000040 !important;
                        padding: 0;
                    }
                }
                &.slick-prev{
                    display: none!important;
                    @media (max-width:992px){
                        display: block!important;
                        background-image: url('../../Assets/img/next.svg');
                        background-size: contain;
                        width: 44px;
                        height: 44px;
                        left: 40%;
                        bottom: -60px;
                        top: unset;
                        transform: translateX(-50%) rotate(180deg);
                        box-shadow: 0px 0px 4px 0px #00000040 !important;
                        padding: 0;
                    }
                }
            }
        }
        
    }
}
.text-center-sm{
    @media (max-width:992px){
        text-align: center;
    }
}
.projects-sliderwrap{
    .loc-slide{
        .loc-img{
            img{
                height: 336px;
                width: 509px;
                object-fit: cover;
                border-radius: 40px 40px 0 0;
            }
        }
    }
}
.locality-sliderwrap{
    margin-top: 30px;
    .slick-slider{
        margin-left: -15px;
    }
    .loc-box {
        width: 320px;
        padding-top: 20px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.11);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.11);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.11);
        margin: 10px 15px;
        border-radius: 35px;
        height: 330px;
        .locality{
            color: #42B57E;
            font-size: 24px;
            font-weight: 700;
            padding:0px 20px;
            margin-bottom: 5px;
        }
        .loc-price {
            font-size: 14px;
            font-weight: 700;
            padding:0px 20px;
        }
        .loc-rating {
            display: flex;
            font-size: 15px;
            padding:0px 20px;
            margin: 5px 0px;
            .stars{
                font-weight: 700;
            }
        }
        .loc-desc{
            font-size: 14px;
            padding:0px 20px;
            margin-bottom: 20px;
        }
        .view-btn{
            display: flex;
            justify-content: space-between;
            background-color: #42B57E;
            color: #fff;
            border-radius: 0px 0px 35px 35px;
            align-items: center;
            padding: 10px 20px;
            text-decoration: none;
            font-weight: 700;
            position: absolute;
            width: inherit;
            bottom: 10px;
        }
    }
    .slick-arrow{
        &::before{
            content: unset;
        }
        &.slick-prev{
            display: none!important;
            @media (max-width:992px){
                display: block!important;
                background-image: url('../../Assets/img/next.svg');
                background-size: contain;
                width: 44px;
                height: 44px;
                left: 40%;
                bottom: -60px;
                top: unset;
                transform: translateX(-50%) rotate(180deg);
                box-shadow: 0px 0px 4px 0px #00000040 !important;
                padding: 0;
            }
        }
    }
    .slick-next{
        width: 100px;
        height: 100px;
        right: -30px;
        margin-top: -25px;
        background-image: url('../../Assets/img/next.svg');
        background-size: contain;
        @media (max-width:992px){
            width: 44px;
            height: 44px;
            right: -30px;
            bottom: -60px;
            top: unset;
            transform: translateX(-50%);
            left: 60%;
            box-shadow: 0px 0px 4px 0px #00000040 !important;
            padding: 0;
        }
    }
    .slick-list{
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: -50px;
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
            height: 100%;
            width: 250px;
            @media (max-width:992px){
                content: none;
            }
        }
    }
}

.smallbanner{
    background-image: url('../../Assets/img/small-banner.png');
    background-size: 100% 100%;
    height: 260px;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.projects-sliderwrap{
    margin-top: 30px;
    .slick-slider{
        margin-left: -15px;
    }
    .slick-list{
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: 0px;
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
            height: 100%;
            width: 210px;
            @media (max-width:992px){
                content: none;
            }
        }
    }
    .slick-arrow{
        &::before{
            content: unset;
        }
        &.slick-prev{
            display: none!important;
            @media (max-width:992px){
                display: block!important;
                background-image: url('../../Assets/img/next.svg');
                background-size: contain;
                width: 44px;
                height: 44px;
                left: 40%;
                bottom: -60px;
                top: unset;
                transform: translateX(-50%) rotate(180deg);
                box-shadow: 0px 0px 4px 0px #00000040 !important;
                padding: 0;
            }
        }
    }
    .slick-next{
        width: 100px;
        height: 100px;
        right: 0px;
        margin-top: -25px;
        background-image: url('../../Assets/img/next.svg');
        background-size: contain;
        @media (max-width:992px){
            width: 44px;
            height: 44px;
            right: -30px;
            bottom: -60px;
            top: unset;
            transform: translateX(-50%);
            left: 60%;
            box-shadow: 0px 0px 4px 0px #00000040 !important;
            padding: 0;
        }
    }
    .loc-box{
        margin: 15px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        border-radius: 40px;
        .pr-content {
            background: #fff;
            margin-top: -40px;
            z-index: 11;
            position: relative;
            padding: 20px;
            border-radius: 0px 0px 40px 40px;
            .projectname {
                font-size: 32px;
                font-weight: 700;
            }
            .project-desc {
                font-size: 14px;
            }
        }
    }
}

.get-dir{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.tabscontainer {
    background: #42B57E;
    border-radius: 40px;
    padding: 40px;
    position: relative;
    height: 100%;
    .thumb{
        position: absolute;
        width: 100px;
        right: 20px;
        top: 20px;
        display: none;
    }
    .thumbdown{
        position: absolute;
        width: 100px;
        right: 40px;
        top: 30px;
        display: none;
    }
    .arrownext{
        position: absolute;
        bottom: 40px;
        right: 40px;
        cursor: pointer;
    }
    .nav-tabs{
        border: 1px solid #fff;
        width: fit-content;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 50px;
        .nav-link{
            color: #fff;
            background-color: transparent;
            border: none;
            border-radius: 0px;
            &.active{
                color: #42B57E;
                background-color: #fff;
                // border-radius: 15px 0px 0px 15px;
            }
        }
    }
    .tab-points{
        color: #fff;
        list-style: disc;
        margin-left: 20px;
        line-height: 32px;
    }
    @media screen and (max-width:767px) {
       border-radius: 0px 0px 40px 40px;
       .nav-tabs{
              margin-top: 20px;
       }
        
    }
}

.tabscontainer:has(.nav-tabs .nav-item:last-child .nav-link.active) {
    background-color: #DB4F6D;
    .thumbdown{
        display: block;
    }
}
.tabscontainer:has(.nav-tabs .nav-item:first-child .nav-link.active) {
    background: #42B57E;
    .thumb{
        display: block;
    }
}