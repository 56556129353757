.profile-section{
    padding-top: 100px;
}
.activitysection{
    padding-bottom: 100px;
}
.activity-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px #00000029;
border-radius: 40px;
overflow: hidden;
.accordion{
    padding: 30px;
    @media (max-width:992px){
        padding: 15px;
    }
    .accordion-item{
        border: none;
        position: relative;
        &:has(.accordion-collapse.show){
            &::after{
                content: '';
                position: absolute;
                height: 0%;
                width: 2px;
                background-color: #082040;
                top: 37px;
                z-index: 1;
                opacity: 0.7;
                animation: accheight 2s forwards;
                @keyframes accheight {
                    from {height: 0%;}
                    to {height: 95%;}
                }
                @media (max-width:992px){
                    @keyframes accheight {
                        from {height: 0%;}
                        to {height: 96%;}
                    }
                }
            }
        }
        .titleicon{
            width: 18px;
            height: 18px;
            border: 1px solid #092040;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            position: absolute;
            left: -8px;
            top: 20px;
            @media (max-width:992px){
                width: 14px;
                height: 14px;
                left: -5px;
                top: 23px;
            }
            .circle{
                background-color: #082040;
                width: 8px;
                height: 8px;
                border-radius: 100%;
            }
        }
        &:last-child{
                &::after{
                    display: none;
                }
        }
    }
    .accordion-collapse{
        .accordion-body{
            @media (max-width:992px){
                padding: 10px;
            }
        }
    }
}
.accordion-button:not(.collapsed){
    background-color: #fff;
}
    .title-card{
        color: #082040;
        font-weight: 600;
        background: #EBF4F1;
        padding: 12px 30px 8px;
        .activity-head{
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            letter-spacing: 0.44px;
            color: #082040;
        }
    }
    .custom-accordion-header{
        padding: 0rem 1.25rem;
        @media (max-width:992px){
            padding: 0rem 1rem;
        }
        .accordion-button{
            font-size: 22px;
            line-height: 27px;
            letter-spacing: 0.44px;
            font-weight: 600;
            color: #082040;
            border-bottom: 1px solid #BFCFE5;
            padding: 1rem 0rem;
        }
    }
    .activity-detailsul{
        background: #FAFAFA;
        border-radius: 26px;
        padding: 5px 55px 5px 30px;
        @media (max-width:992px){
            padding: 5px 26px 5px 25px;
        }
        .activity-details{
            position: relative;
            a{
                display: flex;
                justify-content: space-between;
                margin: 30px 0px;
                cursor: pointer;
                @media (max-width:992px){
                    font-size: 14px;
                    margin: 20px 0px;
                }
                .activity-detail{
                    @media (max-width:992px){
                        padding-right: 10px;
                    }
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: 13px;
                    left: -15px;
                    border: 1px dashed #CCCCCC;
                    height: 199%;
                    @media (max-width:992px){
                        top: 38px;
                        height: 113%;
                    }
                }
            }
            &::after{
                content: '';
                border: solid black;
                border-width: 0 3.5px 3.5px 0;
                position: absolute;
                right: -28px;
                top: 27%;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                padding: 3.5px;
                @media (max-width:992px){
                    border-width: 0 2.5px 2.5px 0;
                    right: -16px;
                    padding: 2.5px;
                }
            }
            &::before{
                content: '';
                position: absolute;
                border: 1px solid #BFCFE5;
                opacity: 0.5;
                width: 104%;
                bottom: -15px;
            }
            .listicon {
                background: #CCCCCC;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -18px;

            }
            &:last-child{
                &::before{
                    display: none;
                }
                .listicon{
                    &::after{
                        display: none;
                    }
                }
                a{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}