$popup-background-color: rgba(0, 0, 0, 0.5);
$card-background-color: white;
$btn-interested-color: linear-gradient(90deg, #F44336 0%, #F44336 83%);
$btn-close-color: #dc3545;
$btn-hover-interested-color: #d0483e;
$btn-hover-close-color: #c82333;

.popup-overlay.exitComp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $popup-background-color; // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .popup-card {
    border-radius: 24px;
    background: linear-gradient(94deg, #FFDF85 4.04%, #FBF8E6 45.62%, #FFDF85 96.84%);
    box-shadow: 0px 0px 11.9px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;
    padding-top: 0;
    max-width: 500px;
    width: 90%; 
    font-family: "century-gothic";
    font-display: swap;
    // text-align: center;
    position: relative;
    @media (max-width:1366px) and (min-width: 1025px) {
      scale: 0.8;
    }
    p{
        margin-bottom: 5px;
    }
    .propertyInfo{
      text-align: center;
      background: #F44336;
      width: 60%;
      margin: 0 auto;
      // height: 150px;
      position: relative;
      padding: 20px 10px;
      color: #FCF1CB;
      @media ($max640) {
        width: 70%;
      }
      .developerName{
        font-size: 16px;
      }
      .projectName{
        font-size: 26px;
        font-style: normal;
        line-height: normal;
        font-weight: 600;
        // margin-bottom: 20px;
      }
    }
    .dp{
      // padding-top: 100px;
      text-align: center;
      .projectImg{
          aspect-ratio: 3/2;
          border-radius: 24px;
          // scale: 0.9;
          width: 90%;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          margin-bottom: 20px;
      }
      .offerDesc{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .offerName{
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .close{
        position: absolute;
        top: 10px;
        right: 15px !important;
        font-size: 45px;
        background-color: #fff;
        // border: 1px solid #000;
        box-shadow: 0px 0px 11.9px 0px rgba(0, 0, 0, 0.25);
        border-radius: 100%;
        line-height: normal;
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      gap: 0 30px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        &.cupon-btn {
          background: $btn-interested-color;
          color: #FCF6DD;

          &:hover {
            // background-color: $btn-hover-interested-color;
          }
        }
      }
    }
    .btn-close-pop {
        padding-top: 10px;
        font-size: 12px;
        color: grey;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
      }
  }
}
