.pune-banner {
    background-color: #42B57E;
    border-radius: 50px;
    height: calc(100vh - 70px);
    .bannercontent{
        padding-top: 100px;
        padding-left: 30px;
    }
    .bnrsubhead {
        font-size: 40px;
        line-height: 46px;
        color: #fff;
        font-weight: 700;
        margin-top: 40px;
    }
    .bannerimg{
        position: relative;
        right: 160px;
        bottom: -120px;
        width: 850px;
    }
    .cloud1{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        opacity: 0.8;
    }
    .cloud2{
        position: absolute;
        top: 66px;
    }
    .cloud1{
        position:absolute;
        z-index:1;
        animation:cloud-animation;
        animation-duration:30s;
        animation-timing-function:linear;
        animation-iteration-count:infinite;
        animation-fill-mode:forwards;
        transition-delay:2s;
    }
    .cloud1{
        bottom:150px;
        right:-200px;
        animation-duration:30s;
        width: 200px;
        opacity: 0.6;
        @media (min-width:1600px){
            bottom: 230px;
        }
        @media (max-width:992px){
            opacity: 0.9;
            width: 80px;
            bottom:100px;
        }
    }
    .cloud2{
        bottom:100px;
        left:30px;
        animation-duration:60s;
        position:absolute;
        z-index:1;
        animation:cloud-animation2;
        animation-duration:30s;
        animation-timing-function:linear;
        animation-iteration-count:infinite;
        animation-fill-mode:forwards;
        transition-delay:2s;
        width: 150px;
        opacity: 0.9;
        z-index: 1111;
        @media (min-width:1600px){
            bottom: 420px;
        }
        @media (max-width:992px){
            bottom: unset;
            top: 0px;
            opacity: 0.9;
            width: 70px;
            z-index: 11;
        }
    }
        @keyframes cloud-animation{
            from{right:0%;}
            to{right:100%;}
        }
        @keyframes cloud-animation2{
            from{left:0%;}
            to{left:100%;}
        }
    }

.punelocal{
    .punelocalrow{
        background-color: #F7F8F8;
        border-radius: 60px;
        padding: 10px 30px 60px;
        .bnrsubhead {
            font-size: 40px;
            line-height: 46px;
            color: #050F27;
            font-weight: 700;
            margin-top: 40px;
        }
        .location-title{
            color: #fff;
        }
        .buildflex {
            display: flex;
            margin-top: 20px;
            .bluehead {
                background: #192951;
                width: fit-content;
                display: flex;
                align-items: center;
                border-radius: 10px;
                padding: 10px 20px;
                .linkimg{
                    margin-left: 25px;
                }
                .build-box {
                    background: #42B57E;
                    display: flex;
                    border-radius: 10px;
                    color: #fff;
                    margin-left: 120px;
                    padding: 10px;
                    .build-cont{
                        p{
                            margin-bottom: 0px;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .build-card{
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0px 20px;
                width: 250px;
                box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
                &.build-card1{
                    margin-left: 40px;
                    margin-right: 10px;
                }
                &.build-card2{
                    margin-left: 10px;
                }
                p{
                    margin-bottom: 0px;
                }
            }
        }
        .local-slider{
            margin-top: 30px;
            .local-card{
                .card-inner{
                    width: 366px;
                    margin: 0 7px;
                    .card-content{
                        background-color: #fff;
                        margin-top: -45px;
                        border-radius: 0px 0px 10px 10px;
                        z-index: 111;
                        position: relative;
                        padding: 20px;
                    }
                }
            }
            .slick-arrow{
                &::before{
                    content: unset;
                }
                &.slick-next{
                    width: 100px;
                    height: 100px;
                    right: -30px;
                    background-image: url('../../Assets/img/next.svg');
                    background-size: contain;
                }
                &.slick-prev{
                    display: none!important;
                }
            }
        }
    }
    .row-btm {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        button{
            margin: 0 20px;
        }
        .subhead {
            font-size: 20px;
            font-weight: 700;
        }
        .rate-txt {
            font-size: 17px;
            font-weight: 500;
        }
    }
}

.bgimg-row{
    
}