.content404 {
    background: #42B57E;
    border-radius: 60px;
    padding:0px 30px 30px;
    height: calc(100vh - 84px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .head404{
        font-size: 200px;
        font-weight: 700;
        color: #fff;
        line-height: 135px;
        margin-top: -90px;
        @media (max-width:992px){
            font-size: 100px;
            line-height: 120px;
            margin-top: -50px;
        }
    }
    .subhead404{
        font-size: 80px;
        font-weight: 700;
        color: #fff;
        @media (max-width:992px){
            font-size: 65px;
        }
    }
    .desc404{
        color: #fff;
        font-size: 30px;
        @media (max-width:992px){
            font-size: 26px;
            text-align: center;
            line-height: 32px;
        }
    }
    .banner404{
        position: absolute;
         bottom: 20px;
         padding: 0 30px;
    }
}