#sales-choose {
    height: 850px;
    .expertise-video{
        height: 100%;
        object-fit: cover;
      }
  }
  
  @media (max-width: 992px) {
    #sales-choose {
      height: 600px;
      
    }
  }
  
  .video-content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(137, 137, 137, 0.8);
  }
  
  .exp-slider-wrapper {
    margin-top: 20px;
  }
  
  .exp-box {
    text-align: center;
    padding: 15px 10px;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.59);
    border: 1px solid #fff;
    border-radius: 12px;
    margin-bottom: 20px;
    img{
        margin: 0 auto;
    }

  }
  
  .exp-Num {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .subheading {
    margin-top: 5px;
  }

  .exp-slider-wrapper{
    .slick-slide{
        padding: 0px 20px;
    }
  }
  