.top-projects-slider.mapView {
    .top-projects-card {
        min-height: 490px;
        width: 100%!important;
        .top-projects-img {
            img{
                height:auto;
                width: 100%!important;
                object-fit: cover;
                aspect-ratio: 3 / 1.8;
            }
        }

        @media ($max1440) and ($min1025) {
            min-height: 490px;
        }
        @media ($max1024) and ($min992) {
            min-height: 490px;
        }

        .top-projects-details {
            .project-price {
                font-size: 24px;
            }
        }
    }
}

//map filters
.filter-sticky-map {
    justify-content: space-between;
}

.single-accordion {
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    gap: 0 10px;
    position: relative;
    @media (max-width:1250px) {
        gap: 0 2px;
    }

    .accordion-item {
        // flex: 1;
        // margin-right: 10px;
        position: relative;
        border: none;

        button {
            border: 1px solid #c5c5c5;
            border-radius: 18px !important;
            padding: 10px;
            padding-left: 10px;
            font-size: .8rem !important;
            gap: 0 5px;
            @media ($max1366) {
                font-size: 0.7rem !important;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .accordion-header {
            cursor: pointer;
            position: relative;
            .accordion-button:not(.collapsed){
                background-color: transparent;
                color: #000;
            }
            &.active{
                button, .accordion-button:not(.collapsed){
                    background-color: rgb(67 166 106 / 50%);
                    // color: #fff;
                }
                // &::before{
                //     background-color: green;
                //     border-radius: 100%;
                //     content: "";
                //     height: 8px;
                //     left: 8px;
                //     position: absolute;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     width: 8px;
                //     z-index: 9 !important;
                // }
            }
            button{
                color: #000;
            }
        }

        .accordion-collapse {
            position: absolute;
            top: 100%; // Position right below the header
            left: 0;
            width: 100%;
            min-width: 250px;
            z-index: 1000;
            background-color: white;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            display: none;

            &.show {
                display: block;
            }

            .accordion-body {
                .filter-options {
                    .btn{
                        background-color: #ebebeb;
                        border: 0;
                        border-radius: 6px;
                        color: #082040;
                        font-size: 12px;
                        padding: 5px 10px;
                        text-transform: capitalize;
                        &.active{
                            background-color: #43a66a;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.irfslist-class .single-accordion .accordion-item .accordion-collapse .accordion-body .filter-options .btn.active{
    background-color: #FFB03D;
    color: #fff;
}
.irfslist-class .single-accordion .accordion-item .accordion-header.active::before{
    background-color: #FFB03D;
}
.irfslist-class .single-accordion .accordion-item .accordion-header.active button{
    background-color: rgb(255 176 61 / 70%);
}
.irfslist-class .single-accordion .accordion-item .accordion-header.active button, .irfslist-class .single-accordion .accordion-item .accordion-header.active .accordion-button:not(.collapsed){
    background-color: rgb(255 176 61 / 70%);
}