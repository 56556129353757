#revenue .padding {
    padding: 60px 0;
  }
  
  .revenue-wrapper {
    margin-bottom: 20px;
  }
  
  .text-extraBold {
    font-weight: 800;
  }
  
  .text-darkPurple {
    color: #4c2a85;
  }
  