.bloglisting-banner{
    border-radius: 80px;
    background: #42B57E;
    .banner-img{
        padding-top: 40px;
        .bannerimg{
            width: 550px;
        }
        .emiimg{
            position: absolute;
            top: 50px;
            left: 115px;
        }
        .loansimg{
            position: absolute;
            top: 55px;
            right: 30px;
        }
        .costsimg{
            position: absolute;
            bottom: 40px;
            right: 4px;
        }
        .paymentimg{
            position: absolute;
            bottom: 60px;
            left: 0;
        }
    }
    .hero-cont{
        @media (max-width:767px) {
            min-height: 80vh;
        }
    }
    .banner-content{
        padding: 0 40px;
        .banner-title{
            color: #fff;
            font-size: 80px;
            font-weight: 600;
            line-height: 109%; /* 104.64px */
            letter-spacing: -2.88px;
            margin-top: 30px;
        }

        @media(max-width:767px){
            padding: 0 15px;
            margin-top: 30px;
            .banner-title{
                font-size: 40px;
            }
            img{
                max-width: 50px;
            }
        }
    }

    @media (max-width:767px) {
        margin-top: 40px;
    }
}
.details-sec{
    .tabs-sec{
        border-radius: 49px;
        background: #F6F6F6;
        padding: 40px 10px;
        .slide-flex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            border-radius: 24px;
            // overflow: hidden;
            height: 380px;
            margin-right: 30px;
            .know-more{
                background-color: #fff;
                border-radius: 24px;
                padding: 10px 20px;
                width: fit-content;
                text-decoration: none;
                cursor: pointer;
                margin-top: 20px;
                color: #000;
                font-weight: 600;
            }
            .sbox1 {
                background: #000;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0 25px;
                padding-left: 55px;
                border-radius: 24px 0px 0px 24px;
                .sbox-title{
                    color: #fff;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 105%; /* 52.32px */
                    letter-spacing: -1.44px;
                }
            }
            .sbox2 {
                background: #FFC876;
                height: 100%;
                position: relative;
                border-radius: 0px 24px 24px 0px;
                .sbox2img{
                    position: absolute;
                    bottom: 0;
                    right: -40px;
                }
            }
        }
        .nav-tabs{
            border: none;
            .nav-link{
                border: none;
                color: #959595;
                &.active{
                    color: #000;
                    font-weight: 600;
                    background: transparent;
                    &:after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 6px;
                        background: #42B57E;
                        bottom: -6px;
                        position: relative;
                        margin: auto;
                        border-radius: 10px;
                    }
                }
            }
        }
        .tab-content{
            padding-top: 30px;
            .slick-dots{
                bottom: 15px;
                li{
                    width: 10px;
                    height: 10px;
                    background-color: #fff;
                    border-radius: 100%;
                    opacity: 0.5;
                    button{
                        opacity: 0;
                    }
                    &.slick-active{
                        background-color: #fff;
                        opacity: 1;
                    }
                }
            }
            .slick-arrow{
                &.slick-prev {
                    left: 12px!important;
                }
                &.slick-next {
                    right: 42px!important;
                }
            }
        }
    }
    .blogs-box{
        margin-top: 15px;
        grid-template-columns: auto auto;
        margin-right: 30px;
        @media (max-width: 767px){
           margin-right: 0px;
            
        }
    }
    .blog-sidebar{
        padding-left: 20px;
        .sidebox1{
            .sidecontent{
                .side-title{
                    font-size: 28px;
                    line-height: normal; /* 42px */
                    letter-spacing: unset;
                }
            }
        }
        .sidebox2{
            height: 280px;
        }
    }

    .blog-listing-head{
        @media(max-width:767px){
            display: none;
        }
    }
}


/* styles.css */
.expand-sec{
    .exp-container {
        display: flex;
        padding: 0;
      }
      
      .panel {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 90vh;
        border-radius: 24px;
        color: #fff;
        cursor: pointer;
        flex: 1.8;
        margin: 5px;
        position: relative;
        -webkit-transition: all 700ms ease-in;
      }
      
      .panel .exp-content {
        font-size: 24px;
        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 15px;
        margin: 0;
        opacity: 0;
        border-radius: 24px;
        border: 1px solid rgba(255, 255, 255, 0.60);
        background: rgba(255, 255, 255, 0.47);
        backdrop-filter: blur(22.200000762939453px);
        padding: 20px 20px 30px;
        display: flex;
        align-items: flex-end;
        transition: all .25s linear;
        .exp-title{
            color: #0D0D0D;
            font-size: 40px;
            font-weight: 600;
            line-height: 42px; /* 104.167% */
        }
        .exp-arrow{
            margin-bottom: 10px;
        }
      }
      .panel.active {
        flex: 3.2;
        height: 90vh;
      }
      .panel.active .exp-content {
        opacity: 1;
        transition: all .5s linear;
        transition-delay: .7s;
      }
}

  .listing-sec{
    margin-top: 50px;
    .list-container{
        border-radius: 49px;
        background: #F6F6F6;
        padding: 40px 15px;
        .section-title{
            color: #172A39;
            font-size: 80px;
            font-weight: 600;
            line-height: 99%; /* 104.64px */
            letter-spacing: -2.88px;
            margin-bottom: 30px;
        }
    }
    .blog-sidebar{
        .sidebox1{
            .sidecontent{
                .side-title{
                    font-size: 40px;
                    line-height: 105%; /* 42px */
                    letter-spacing: -1.44px;
                }
            }
        }
        .sidebox2{
            height: 420px;
        }
    }
    .blog-listing {
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        border-bottom: 1px solid #000;
        padding: 30px 0px;
        .imgdiv{
            img{
                border-radius: 16px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
        .blog-content{
            padding-left: 20px;
            .blogtitle{
                color: #000;
                font-size: 26px;
                font-weight: 600;
                letter-spacing: 0.48px;
                text-transform: capitalize;
            }
            .blogdesc{
                color: #202020;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px; /* 137.5% */
                letter-spacing: 0.32px;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* Number of lines to show */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 4.5em; /* Adjust this value based on your line-height */
            }
            .readmore{
                position: relative;
                text-decoration: none;
                color: #000;
                border-radius: 8px;
                background: #F1F1F1;
                padding: 10px 40px 10px 10px;
                cursor: pointer;
                &::after{
                    content: '>';
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
  }

  .expert-sec{
    margin-top: 50px;
    .card-img{
        img{
            border-radius: 24px;
        }
    }
  }

  .blog-listing-head {
    background-color: #F4F5FA;
    &.sticky{
        z-index: 1111;
        top: 84px;
        position: fixed;
        width: 100%;
        
        
        @media screen and (max-width:600px){
            top: 113px;
            position: fixed;
            width: 100%;
            left: 0;
        }
    }
    .menu-scroll-container{
        max-width: 1320px;
    }
    .layout-next,
    .layout-prev {
        top: 13%;
        z-index: 1;
    }
    .slick-list {
        padding: 0 20% 0 0 !important;
        color: #E6E6E6 !important;
    }
    .slick-initialized .slick-slide {
        color: #E6E6E6;
        &.slick-active {
            color: #202227;
        }
    }
    .btn{
        border:0px;
        @media (max-width:768px) {
            text-align: left;
            white-space: nowrap;
        }
        &.active{
            border:0px;
            border-bottom: 3px solid #43A66A;
        }
    }
    @media (max-width:768px) {
        top: 60px;
    }
    @media (max-width:400px) {
        .slick-list {
            padding: 0 17% 0 3% !important;
            color: #E6E6E6 !important;
        }
    }
}