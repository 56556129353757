#services {
    background-color: #192951;
    margin-top: -5px;
  }
  
  .services-wrapper .sec-desc {
    font-size: 25px;
  }
  
  .flex-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .flex-title {
    color: #f1f1f1;
    position: relative;
    font-size: 16px;
    text-align: center;
    transform: rotate(90deg) translate(-50%, -50%);
    left: calc(50% - 195px);
    top: calc(50% + 17px);
    width: 360px;
    text-align: left;
    transition: all 0.5s ease;
  }
  
  .flex-about {
    opacity: 0;
    color: #f1f1f1;
    position: relative;
    font-size: 16px;
    top: 12%;
    line-height: 22px;
    margin: auto;
    text-align: left;
    transition: all 500ms ease;
  }
  
  .flex-slide-no {
    color: #fff;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    margin-top: 30px;
  }
  
  .flex-slide {
    flex: 1;
    cursor: pointer;
    height: 700px;
    overflow: hidden;
    border-right: 1px solid rgba(90, 100, 173, 0.4);
    transition: all 500ms ease;
  
    &.active {
      flex-grow: 4;
  
      .flex-about {
        opacity: 1;
        width: 90%;
      }
  
      .flex-slide-no {
        text-align: left;
        font-size: 120px;
        margin-left: 30px;
      }
  
      .flex-title {
        text-align: left;
        font-size: 20px;
        font-weight: 800;
        margin-left: 20px;
        left: 0;
        transform: rotate(0deg);
        top: 10%;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .flex-slide.active:hover {
      padding: 10px !important;
    }
  }
  
  @media (max-width: 992px) {
    .flex-container {
      display: block;
    }
  
    .flex-title {
      transform: unset !important;
      text-align: left !important;
      color: rgba(255, 255, 255, 0.4);
      left: 0;
      top: 25px;
    }
  
    .flex-slide {
      height: 90px;
      padding: 0 15px !important;
      border-bottom: 1px solid rgba(90, 100, 173, 0.4);
      border-right: unset;
      overflow-x: hidden;
  
      &.active:hover .flex-about {
        margin-left: 5px;
      }
      &.active{
        height: auto;
      }
    }
  
    .flex-about {
      top: 5% !important;
    }
  
    .flex-slide.active .flex-title {
      top: 3% !important;
      margin-left: 5px;
      color: #fff;
    }
  
    .flex-mob-no {
      font-weight: 500;
      color: #fff !important;
      margin-right: 10px;
    }
  }
  
  .services-wrapper .sec-desc {
    font-size: 16px;
    line-height: 26px;
  }
  