#awards-and-accolades {
    .sec-heading2 {
      font-weight: 700;
      font-size: 24px;
    }
  
    .awards-tabs-wrapper {
      display: inline-flex;
      margin: 20px 0;
      border-radius: 12px;
      overflow: hidden;
      background-color: #f0f0f0;
  
      button {
        color: #3e4b72;
        padding: 10px 30px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        transition: background-color 0.3s, color 0.3s;
        border-radius: 0;
        
        &:hover {
          color: #192951;
        }
  
        &.active-awards,
        &.active-accolades {
          background-color: #28a745; /* Green background for active tab */
          color: #fff;
          border-radius: 12px;
        }
      }
  
      // Remove any unintended spacing or borders between tabs
      button + button {
        margin-left: 0;
      }
    }
  
    .award-wrapper, .accolades-wrapper {
      .award-slider, .accolades-slider {
        .award-slide, .accolade-slide {
          padding: 10px;
          img {
            max-width: 100%;
            border-radius: 8px;
            margin: 0 auto;
          }
        }
      }
  
      .pnext, .pback {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #ccc;
        border-radius: 50%;
        &.pnext {
          right: -25px;
        }
        &.pback {
          left: -25px;
        }
      }
    }
  }
  