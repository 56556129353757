.gamification-card{
    p{
        margin-bottom: 0;
        color: rgb(216,35,35);
        font-weight: 600;
        font-size: 14px;
        @media ($max640) {
            font-size: 11px;
        }
    }
}
.text-center-mob{
    @media ($max640) {
        text-align: center;
    }
}