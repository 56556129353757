footer {
    background: $footer-bg;
    color: $footer-color;
    // border-top: 1px solid darken($footer-bg, 5%);
    // padding: 30px 1rem;
    transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    font-size: calc(#{$default-font-size} - 0.05rem);
    font-family: $type1;
    a {
      color: theme-color(success);
      font-size: inherit;
      margin: 8px 0px;
    }
    @media (max-width: 991px) {
      margin-left: 0;
      width: 100%;
      position: relative;
    }
  }
  .footer-main{
    .footercontainer{
        background-color: #192951;
        padding: 60px 60px 55px;
        border-radius: 48px 48px 0 0;
        @media(max-width:992px){
            padding: 40px 30px 45px;
        }
    }
    *{
        color: #FFFFFF;
    }
    .parent {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        // grid-template-rows: repeat(2, 1fr);
        // grid-column-gap: 0px;
        // grid-row-gap: 75px;
        margin-bottom: 40px;
        // &::before {
        //     content: "";
        //     border-top: 1px solid #707070;
        //     width:100%;
        //     position:absolute;
        //     left:0;
        //     top: 50%;
        // }
        ul{
            li{
                line-height: 24px;
            }
        }
        .div1 { 
            grid-area: 1 / 1 / 2 / 3; 
            p{
                line-height: 20px;
            };
        }
        .div2 { grid-area: 1 / 3 / 2 / 4; }
        .div3 { grid-area: 1 / 4 / 2 / 5; }
        .div4 { grid-area: 1 / 5 / 2 / 6; }
        .div5 { grid-area: 2 / 1 / 3 / 3; }
        .div6 { grid-area: 2 / 3 / 3 / 5; }
        .div7 { grid-area: 2 / 5 / 3 / 6; }

        .footer-h4{
            font-size: 18px;
            line-height: 26px;
        }
        .circle-icons{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: 707070;
        }
        
    }
    .tandc{
        padding-top: 20px;
        border-top: 1px solid #707070;
    }

    @media (max-width: 767px){
        .parent {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(7, auto);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            .list-unstyled{
                column-gap: 12px ;
                li{
                    position: relative;
                    &.vert-line{
                        &::after{
                            content: "";
                            border-right: 1px solid #ffffff;
                            height: 60%;
                            position: absolute;
                            right: -6px;
                            top: 57%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            &:before {
                top: 55%;
            }
            .div1 { grid-area: 1 / 1 / 2 / 2; }
            .div2 { grid-area: 2 / 1 / 3 / 2; }
            .div3 { grid-area: 3 / 1 / 4 / 2; }
            .div4 { grid-area: 4 / 1 / 5 / 2; }
            .div5 { grid-area: 5 / 1 / 6 / 2; }
            .div6 { grid-area: 6 / 1 / 7 / 2; }
            .div7 { grid-area: 7 / 1 / 8 / 2; }
            }
            
    }
}
.footer-title{
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
    .line{
        width: 58px;
        height: 4px;
        background-color: #42B57E;
        display: block;
        margin-top: 5px;
        @media screen and (max-width: 767px){
            margin: 5px  auto;
        }
    }
}
.socialicons{
    &:hover{
        svg{
            circle{
                fill: #43A66A;
            }
        }
    }
}
.social-title{
    margin-top: 25px!important;
}
.social-flex{
    margin-top: 5px;
    margin-left: -6px;
    @media (max-width:992px){
        justify-content: center;
    }
    a{
        margin: 0 6px;
    }
}
.compareupdown{
    @media screen and (max-width: 767px){
        position: fixed;
        bottom: 82px;
        z-index: 1111;
        transition: all .6s linear;
    }
    .compare-wrap{
        @media (max-width:767px){
            position: relative;
        }
    }
    &.slide-up {
        @media screen and (max-width: 767px){
            bottom: 82px;
        }
      }
    
      &.slide-down {
        @media screen and (max-width: 767px){
            bottom: 2px;
        }
      }
}
.compare-wrap{
    background-color: #43A66A;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 999;
    text-align: center;
    p{
        margin-bottom: 0;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: #fff;
    }
    a{
        color: #FFF038;
        text-decoration: underline;
    }
    @media screen and (max-width:600px){
        bottom: 38px;
        width: 100%;
        z-index: 9999;
        p{
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
    }
}