.blogdetail{
    padding-top: 120px;
    padding-bottom: 40px;
    h1, h2, h3, h4{
        font-weight: 600;
    }
    img{
        margin: 30px auto;
        display: block;
    }
}
.details-sec{
    ol li{
        font-size: 24px;
        font-weight: 600;
    }
    ol p{
        text-align: justify;
        font-size: 1rem;
        font-weight: 400;
    }
}

.blogbannerbg{
    height: 86vh;
    border-radius: 60px;
    background-size: 100% 100%;
    @media ($max1024) {
        height: 40vh;
    }
}
.icon-circle {
    width: 310px;
    height: 310px;
    border-radius: 100%;
    background: linear-gradient(90deg, #71AEA7 0%, #AACDCD 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0px;
    z-index: 11;
    @media ($max1440) {
        width: 260px;
        height: 260px;
    }
    .icon-circleimg{
        width: 200px;
        @media ($max1440) {
            width: 180px;
        }
    }
    @media(max-width: 992px){
        width: 210px;
        height: 210px;
    }

    @media(max-width: 767px){
        width: 100px;
        height: 100px;
        img{
            width: 50px;
        }
    }
}
.bgblogwhite {
    position: absolute;
    bottom: 0;
    right: -17px;
    width: 393px;
    @media ($max1440) {
        width: 320px;
    }
    @media(max-width: 992px){
        bottom: -2px;
        width: 275px;
    }
    @media(max-width: 767px){
        bottom: -5px;
        right: -12px;
        width: 144px;
    }
}

.details-sec{
    padding: 50px 0px;
    .blog-title {
        color: #192951;
        font-size: 36px;
        font-weight: 600;
        line-height: 109%;
        letter-spacing: -1.92px;
        margin-bottom: 20px;
        @media(max-width: 767px){
            font-size: 40px;
        }
    }
    .blog-content {
        color: #050F27;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        div{
            text-align: justify!important;
            @media (max-width: 767px){
                .img-full{
                    width: 100%!important;
                }
                
            }
        }
        h2{
            font-size: 24px;
            font-weight: 600;
        }
        h3{
            font-size: 20px;
            font-weight: 600;
        }
        p{
            text-align: justify;
        }
        ul{
            list-style-type: disc;
            padding-left: 30px;
            @media ($max640) {
                padding-left: 15px;
            }
        }
        table, td, th{
            border: 1px solid $black;
            border-collapse: collapse;
        }
        td, th{
            padding: 10px;
            @media ($max640) {
                padding: 2px 5px;
            }
        }
    }
}

.blog-sidebar {
    padding-left: 30px;
    @media (max-width: 767px){
        padding-left: 0px;
    }
    .sidebox1 {
        border-radius: 24px;
        overflow: hidden;
        .sidecontent {
            background: #000;
            padding: 30px 20px;
            .side-title{
                color: #fff;
                font-size: 28px;
                font-weight: 600;
                line-height: 109%; /* 52.32px */
                letter-spacing: -1.44px;
            }
            .gr-right{
                display: block;
                margin: auto;
                margin-top: 0px;
                margin-right: 0;
            }
        }
    }
    .sidebox2{
        margin-top: 20px;
        border-radius: 24px;
        background: #42B57E;
        height: 450px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        .side-title{
            color: #fff;
            font-size: 28px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.48px;
            text-transform: capitalize;
        }
    }
}

.recomend-blogs{
    border-radius: 49px;
    background: #F6F6F6;
    padding: 20px;
    .title-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        padding-right: 20px;
        @media (max-width: 767px){
            flex-direction: column;
            align-items: flex-start;
        }
        .recom-title{
            color: #172A39;
            font-size: 96px;
            font-weight: 600;
            line-height: 109%; /* 104.64px */
            letter-spacing: -2.88px;
            @media (max-width: 767px){
                font-size: 40px;
                line-height: 103%;
            }
        }
    }
}
.blogs-box {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 12px;
    @media (max-width: 767px){
        grid-template-columns: auto;
        column-gap: 0;
        row-gap: 0px;
        margin-right: 0px;
    }
   
}
.blog-card{
    text-decoration: none;
    .cardimg{
        img{
            border-radius: 24px;
        }
    }
    .blog-card-content{
        padding: 20px 0px;
        .blog-card-title{
            color: #000;
            font-size: 26px;
            font-weight: 600;
            line-height: 109%; /* 52.32px */
            letter-spacing: -0.44px;
        }
        .blog-card-desc{
            color: #000;
            font-size: 16px;
            font-weight: 400;
            text-decoration-line: underline;
            text-transform: capitalize;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Number of lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 4.5em; /* Adjust this value based on your line-height */
        }
    }
    .blog-card-date{
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.32px;
        opacity: 0.5;
    
    }
}

.bloglisting-card{
    .blogs-box {
        row-gap: 40px;
        grid-template-columns: 33% 33% 33%!important;
        column-gap: 40px;
        @media (max-width: 767px){
            grid-template-columns: auto !important;
            column-gap: 0px;
            row-gap: 30px;
            margin-right: 0px 
        }
        
       .blog-card{
        background-color: #fff;
        border-radius: 24px;
        img{
            width: 418px;
            height: 232px;
            object-fit: cover;
        }
        .blog-card-content{
            padding-inline: 15px;
            .blog-card-desc{
                text-decoration-line: none;
            }
        }
        transition: all 0.3s ease;
        &:hover{
            box-shadow: 0px 0px 10px 7px rgb(0 0 0 / 9%);
            
        }
       }
    }
}