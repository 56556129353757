$baseGreen: #43A66A;
$baseBlue: #192951;
.graylight{
	color: #6D6D6D;
}
.baseBlue{
	color: $baseBlue;
}
body {
	background: #eee;
	font-family: $type1;
}
.btn,
.btn:active, 
.btn:focus, 
.btn:visited, 
.btn:hover,
button,
button:active, 
button:focus, 
button:visited, 
button:hover {
	outline: none !important;
  	box-shadow: none !important;
}
body::-webkit-scrollbar-track,
.scroll-style::-webkit-scrollbar-track,
.right-side-plp-filter::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar,
.scroll-style::-webkit-scrollbar,
.right-side-plp-filter::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
	@media (min-width:1300px) and (max-width:1380px){
		width: 6px;
	}
}

body::-webkit-scrollbar-thumb,
.scroll-style::-webkit-scrollbar-thumb,
.right-side-plp-filter::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb
{
	background-color: #43A66A;
	border: 2px solid #43A66A;
	border-radius: 10px;
}
.hr-scroll-style::-webkit-scrollbar {
height: 5px;
}
.overflow-auto::-webkit-scrollbar {
	height: 6px;
}

.hr-scroll-style::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}
.overflow-auto::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.8);
}

.hr-scroll-style::-webkit-scrollbar-thumb {
height: 5px;
background-color: #43A66A;
}

.hr-scroll-style::-webkit-scrollbar-thumb:hover {
background-color: #43A66A;
}

.hr-scroll-style::-webkit-scrollbar:vertical {
display: none;
}
ul {
	list-style: none;
	padding: 0;
	}
	.user-list {
	display: grid;
	grid-gap: 1rem;

	.project-img{
		width: 200px;
	}
	.project-img img{
		width: 100%;
	}
	.amenity-wrap ul li{
		display: inline-block;
		}
	@media (min-width: 640px) {
		grid-template-columns: 1fr;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 1fr;
	}

	& > * {
		background: white;
	}
}
.search-input{
	font-size: 1rem;
	padding: 0.8rem 1rem .8rem 2.5rem;
	border-radius: 25px;
}
.search-btn.theme-btn{
	padding: 1.07rem 1rem;
	border-radius: 8px!important;
}
  .customSelectStyle{
	.css-13cymwt-control{
		height: calc(3.5rem + var(--bs-border-width) * 2);
		min-height: calc(3.5rem + var(--bs-border-width) * 2);
		line-height: 1.25;
	}
	.css-t3ipsp-control{
		height: calc(3.5rem + var(--bs-border-width) * 2);
		min-height: calc(3.5rem + var(--bs-border-width) * 2);
		line-height: 1.25;
	}
  }
.dashboard-table{
	width: 100%;
	border-radius: 8px;
	> thead{
	  background-color: #F8F8F8;
		th{
			padding: 1.5rem 0.6rem 1.2rem;
			font-weight: 400;
			background-color: #F8F8F8;
			min-width: 150px;
			&:first-child, &:nth-child(2){
				border-radius: 8px 0 0 0;
				&:after{
					content: '';
					border-right: 1px solid #dee2e6;
					position: absolute;
					height: 100%;
					width: 1px;
					right: 0;
					top: 0;
				}
			}
			&:last-child{
				border-radius: 0 8px 0 0;
				min-width: 50px;
				&:after{
					content: '';
					border-left: 1px solid #dee2e6;
					position: absolute;
					height: 100%;
					width: 1px;
					left: 0;
					top: 0;
				}
			}
	  	}
	}
	> tbody{
		tr{
			td{
				&:first-child, &:nth-child(2){
					&:after{
						content: '';
						border-right: 1px solid #dee2e6;
						position: absolute;
						height: 100%;
						width: 1px;
						right: 0;
						top: 0;
					}
				}
				&:last-child{
					&:after{
						content: '';
						border-left: 1px solid #dee2e6;
						position: absolute;
						height: 100%;
						width: 1px;
						left: 0;
						top: 0;
					}
				}
			}
		}
  
	  	> tr:last-child{
			> td{
				border-bottom: 0px;
				&:first-child{
					border-radius: 0 0 0 8px;

				}
				&:last-child{
					border-radius: 0 0 8px 0;
				}
			}
		}
		> tr{
			> td{
				// color: #979797;
				padding: 1.5rem 0.6rem;
				// background-color: #fff;
				&:first-child{
					color: $black;
				}
				&:last-child{
					color: $black;
				}
			}
		}
	}
	.projectForm{
		width: calc(100vw - 290px);
	}
  }
  .sticky-column {
	position: sticky;
	left: 0;
	// background-color: #f1f1f1;
	z-index: 1;
	&.column-snd{
		left: 40px;
	}
  }
  
  .sticky-column:last-child {
	position: sticky;
	right: 0;
	// background-color: #f1f1f1;
	z-index: 1;
  }
  .table-container {
	max-width: 100%;
	overflow-x: auto;
	border:1px solid #B3B2B2;
	border-radius: 8px;
  }
  .configuration-table,.tower-table{
	//   border:1 px solid #B3B2B2;
	tr{
		// border:1 px solid #B3B2B2;
		td{
			padding: 0.5rem 0.5rem;
			vertical-align: middle;
		}
	}
	.delete-config, .add-config{
		padding: 5px!important;
		width: 36px;
		height: 36px;
		line-height: 20px;
		&:hover{
			color: #fff!important;
		}
	}
	.add-config{
		color: #42b57e;
	}
  }
  .configuration-table{
	border: 1px solid #0000002d;
	background-color: #fff!important;
	
  }
  .configurationtd{
	padding-left: 20px!important;
	background-color: #e5e5e595!important;
}
  .addProjectTab{
	li{
	  padding: 1rem 0.5rem;
	  button{
		border-radius: 25px!important;
		border-color: $baseBlue!important;
		color: $baseBlue!important;
		padding: 0.5rem 1.5rem;
		&.active{
		  border-color: $baseGreen!important;
		  background-color: $baseGreen!important;
		  color: #fff!important;
		}
	  }
	}
}


@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #aaaaaa;
    }
    50% {
        background-color: #cccccc;
    }
    100% {
        transform: scale(1);
        background-color: #ffffff;
    }
}

@mixin dot-animation {
    animation: {
        direction: alternate;
        duration: 0.5s;
        fill-mode: none;
        iteration-count: infinite;
        name: stretch;
        play-state: running;
        timing-function: ease-out;
    }
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
}

/* Chetan */
div.loading{
	display: flex;
	justify-content: center;
	align-items: center;
}
.loaderWrapper{
	// height: 100vh;
	// display: flex;
}
.custom-loader {
	width:44px;
	height:44px;
	display: flex;
	color: #43A66A;
	margin-left: 5px;
  }
  
  .custom-loader:before,
  .custom-loader:after {
	content: "";
	flex: 1;
	margin:0 0 0 -2px;
	--c: linear-gradient(currentColor 0 0);
	background: 
	  var(--c) 0    0, 
	  var(--c) 100% 0, 
	  var(--c) 100% 100%, 
	  var(--c) 0    100%, 
	  var(--c) 0    50%;
	background-repeat: no-repeat;
	animation: l7 2s infinite alternate;
  }
  .custom-loader:before {
	transform: scale(-1);
	margin:0 -2px 0 0;
  }
  
  @keyframes l7 {
	0%,
	5%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px}
	20%  {background-size: 100% 4px,4px 0   ,0    4px,4px 0   ,0    4px}
	40%  {background-size: 100% 4px,4px 100%,0    4px,4px 0   ,0    4px}
	60%  {background-size: 100% 4px,4px 100%,100% 4px,4px 0   ,0    4px}
	80%  {background-size: 100% 4px,4px 100%,100% 4px,4px 50% ,0    4px}
	95%,
	100% {background-size: 100% 4px,4px 100%,100% 4px,4px 50% ,100% 4px}
  }

.btn__dots--loading {
	font-size: 0;
	padding: 1.3rem 3rem!important;
}
/* Chetan End */
.btn-theme{
	background-color: $baseGreen!important;
	border-color: $baseGreen!important;
	color: #fff!important;
}
.theme-btn-reverse{
	padding: 0.5rem;
	border-radius: 25px;
	border-color: #fff!important;
	background-color: #fff!important;
	border: 0px;
	color: $baseGreen!important;
	min-width: 115px;
	max-width: 100%;
}
.theme-btn{
	padding: 0.5rem;
	border-radius: 25px;
	border-color: $baseGreen!important;
	background-color: $baseGreen!important;
	border: 0px;
	color: #fff!important;
	min-width: 115px;
	max-width: 100%;
	&.secondary{
		background-color: transparent!important;
		color: #828282!important;
		border: 1px solid #828282!important;
	}
	&.border-dark{
		border: 1px solid #000!important;
	}
	&.btn-blue{
		background-color: $baseBlue!important;
		border-color: $baseBlue!important;
		color: #fff!important;
	}
	&.search-btn{
		height: 51px;
		padding: 0.5rem 0.5rem;
	}

	/* Chetan */
	&.btn__dots--loading {
        font-size: 0;
		padding: 1.4rem 3rem;
        .btn__dots {
            display: flex;
            i {
                @include dot-animation;
                animation-delay: .1s;
                margin: 0 5px;
                &:first-child {
                    animation-delay: 0s;
                    margin: 0;
                }
                &:last-child {
                    animation-delay: .2s;
                    margin: 0;
                }
            }
        }
    }
    i {
        font-weight: normal;
    }
    .btn__dots {
        display: none;
    }
	/* Chetan End */
}
// Pagination style
.pagination {
	& .page-link {
		color: #6c757d;  
		background-color: #fff;  
		border: 1px solid #dee2e6;
	}
	& .page-item.active .page-link {
		color: #fff;  
		background-color: #9d9d9d;
		border: 1px solid #9d9d9d;
	}
}
.showPerPage{
	border: 1px solid #C9C9C9;
	width: 50px;
	height: 32px;
	border-radius: 7px;
	background-color: transparent;
}
.search-wrap{
	z-index: 12;
    width: 500px;
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	top: 51px;
	left: 0px;
	@media (max-width: 767px){
		width: calc(100% - 0px);
	}
	.title-wrap{
		line-height: 34px;
		color: rgb(127, 127, 127);
		white-space: nowrap;
		text-overflow: ellipsis;
		position: relative;
		padding: 7px 20px 0px 7px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		overflow: hidden;
		pointer-events: none;
		font-size: 14px;
		span{
			background-color: #fff;
			padding-right: 10px;
		}
		&:after{
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: #e5e5e5;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.result-list{
		li{
			&.selected{
				background-color: $baseBlue;
				a{
					color: #fff!important;
				}
			}
			a{
				display: block;
				padding: 5px 20px;
				&:hover,&.selected{
					background-color: $baseBlue;
					color: #fff!important;
				}
			}
		}
	}
}
.editTowerBtn{
	&:hover{
		background-color: #dcdcdc!important;
	}
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border-color: #dcdcdc;
}
button:active {
    // border: 1px transparent;
	outline: none;
}
.bg-light-gray{
	background-color: $bg-light-gray!important;
}
.rc-slider{
	height: 45px;
	padding: 20px 0;
	margin-left: 12px;
	width: calc(100% - 24px);
	@media screen and (max-width:1024px){
		width: calc(100% - 35px);
		margin-left: 10px;
	}
}
.rc-slider-handle{
	background-color: $baseGreen;
	border: 2px solid $baseGreen;
	opacity: 1;
	width: 25px;
    height: 25px;
    margin-top: -11px;
	&:focus-visible{
		background-color: $baseGreen;
		box-shadow: 0 0 0 3px $baseGreen;
		border-color: $baseGreen;
	}
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	border-color: $baseGreen;
	box-shadow: 0 0 0 5px $baseGreen;
  }
.rc-slider-track, .rc-slider-tracks{
	background-color: $baseGreen;
}
.editableField{
	border: 1px dashed #fff;
	border: 2px dashed gray;
    margin: 5px 0;
    border-radius: 8px;
    padding: 4px 10px;
}
.inner-tower-edit-btn{
	right: 10px;
}
select.form-control{
	appearance: auto;
}
header{
	ul.selected-location {
		overflow: auto;
		white-space: nowrap;
		scrollbar-width: none;
		&.dropdownLocation-list{
			white-space: unset;
		}
	}
}
ul.selected-location {
    position: absolute;
    top: 50%;
    left: 40px;
    background-color: #fff;
    transform: translateY(-50%);
	width: calc(100% - 20px);
	height: calc(100% - 10px);
	padding: 8px 0;
	margin-bottom: 0;

	&.header-search-list{
		left: 10px;
		padding: 4px 0;
		width: calc(100% - 160px);
		@media screen and (max-width: 767px){
			padding: 6px 6px;
			background-color: #ECF6F0;
			width: 100%;
		}
	}
	&.dropdownLocation-list{
		position: relative;
		top: -10px;
		left: 30px;
		background-color: #fff;
		transform: unset;
		width: calc(100% - 80px);
		height: calc(100% - 10px);
		padding: 8px 0;
		li{
			margin-bottom: 6px;
		}
	}
	li{
		display: inline-block;
		position: relative;
		padding: 4px 31px 4px 14px;
		background-color: #192951;
		border-radius: 25px;
		color: #fff;
		text-transform: capitalize;
		margin-right: 6px;
		a{
			position: absolute;
			top: 0;
			right: 0;
			color: #fff;
			width: 20px;
			height: 20px;
			svg{
				position: absolute;
				top: 4px;
				right: -9px;
				width: 25px;
				height: 25px;
			}
		}
		@media screen and (max-width: 767px){
			padding: 4px 20px 4px 10px;
			font-size: 12px;
			a{
				svg{
					top: 3px;
					right: -12px;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
.select2-selection__rendered{
	.select2-selection__choice{
		position: relative!important;
		padding: 4px 31px 4px 14px !important;
		background-color: #192951!important;
		border-radius: 25px!important;
		color: #fff;
		text-transform: capitalize;
		margin-right: 6px;
		margin-top: 0px!important;
		button{
			position: absolute!important;
			top: 0!important;
			right: 0!important;
			left:unset!important;
			color: #fff;
			width: 20px;
			height: 20px;
			span{
				position: absolute;
				top: -4px;
				right: 3px;
				width: 25px;
				height: 25px;
				font-size: 27px;
				color: #fff;
				font-weight: 400;
			}
			&:hover,&:focus{
				background-color: transparent!important;
			}
		}
	}
}
.addProject{
	.form-control{
		min-height: 44px;
	}
}
.amenities-list-wrap{
	li{
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		.amenities-box{
			display: inline-block;
			color: #000;
			border: 1px slategrey solid;
			border-radius: 25px;
			padding: 8px 10px;
			text-decoration: none;
			span{
				padding: 0 10px;
			}
		}
	}
}
.loaderBox{
	// z-index: 99;
	// display: block;
	// position: fixed;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;
	.loaderBoxInner {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 180px;
		margin-top: 16px;
		border-bottom: solid 1px #e6e6e6;
		background-color: #fff;
	}
	.loaderBoxImg {
		width: 103px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.loaderBoxInfo{
		position: relative;
		width: calc(100% - 103px);
		padding: 16px 20px;
	}
	.loaderTitle {
		position: absolute;
		top: 16px;
		width: 100px;
		height: 19px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.loaderConfig {
		position: absolute;
		top: 47px;
		width: 180px;
		height: 26px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.css-rw3bo2 {
		position: absolute;
		top: 73px;
		width: 160px;
		height: 15px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.css-j67rfj {
		display: block;
		position: absolute;
		top: 104px;
		width: calc(100% - 40px);
		max-width: 315px;
		height: 32px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.css-1oqkpw8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: absolute;
		top: 107px;
		width: calc(100% - 40px);
		padding-top: 14px;
		border-top: solid 1px #ececec;
	}
	.css-1yzekmu {
		width: 50%;
		max-width: 150px;
		height: 40px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	.css-5amt6h {
		width: 80px;
		height: 30px;
		background: #f6f7f8 linear-gradient( to right,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100% ) 800px 104px no-repeat;
		-webkit-animation: textLoading-anim 1.25s linear infinite;
		animation: textLoading-anim 1.25s linear infinite;
	}
	@media (min-width: 1100px){
		.loaderBoxInner {
			height: 300px;
		}
		.loaderBoxImg {
			width: 350px;
		}
		.loaderBoxInfo {
			width: calc(100% - 230px);
		}
		.css-1oqkpw8 {
			top: 152px;
		}
		.css-1yzekmu {
			width: 200px;
		}
	}	
	@-webkit-keyframes textLoading-anim{
		0%{
			background-position:-400px 0;
		}100%{
			background-position:400px 0;
		}
	}
	@keyframes textLoading-anim{
		0%{
			background-position:-400px 0;
		}100%{
			background-position:400px 0;
		}
	}
}
.css-1nmdiq5-menu{
	z-index: 9999!important;
}
.search-header{
	.city-selector{
		height: 49px;
		border-top: 0;
		border-left: 0;
		border-bottom: 0;
		border-right: 1px solid #B3B2B2;
		background-color: transparent;
		padding: 10px;
		@media screen and (max-width: 767px){
			border-right: 1px solid #43A66A;
			
		}
	}
}
.configuration-table {
	td{
		&.active{
			background-color: #d2ffd5;
		}
	}
}

// .animated-box {
// 	display: none;
// 	visibility: hidden;
// 	opacity: 0;
// 	transition:visibility 0s, display 0.6, opacity 0.6s ease-in-out;
// 	&.visible {
// 		display: block;
// 		visibility: visible;
//   		opacity: 1;
// 	}
// }

.fadeOutRight {
	animation: fadeOutRight 0.5s forwards;
  }
  
  @keyframes fadeOutRight {
	from {
	  opacity: 1;
	  transform: translateX(0);
	}
	to {
	  opacity: 0;
	  transform: translateX(100%);
	}
  }