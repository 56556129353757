.noto-sans {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  #scrollableDiv{
    height: 630px;
    @media (max-width:992px){
        height: 270px;
    }
  }
.project-detail-main {
    background-color: #F4F5FA;
    .padinline{
        @media(max-width: 992px) {
           padding-inline: 1rem;
        }
    }
    .project-main {
        padding-top: 38px;
        // @media(max-width: 992px) {
        //    padding-inline: 1rem;
        // }
    }
    .pdp-price-cards {
        &.parent {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 12px;
            grid-row-gap: 0px;
            .card {
                border-radius: 6px;
                .card-body {
                    border-radius: 6px;
                    .card-title {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .fix-hw {
        width: 100%;
        height: 458px;
    }
    .multi-img {
        height: 121px;
        img {
            width: 121px;
        }
    }
    .rating-star-review {
        color: #E6E6E6;
        &.active-star {
            color: #DEA200;
        }
    }
    .back-stars {
        color: #E2E2E2;
        position: relative;
        display: flex
    }
    .front-stars {
        color: #FFBC0B;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: all 0.5s;
        display: flex;
    }
    .plp-term {
        .form-check-input:checked {
            background-color: #082040;
            border-color: #082040;
        }
    }
    .active-toogle {
        border-bottom: 2px solid#43A66A;
    }
    .pdplist-background {
        background-color: rgba(217,233,228,.5);
        border-radius: 40px 40px 0 0;
        p {
            font-size: 18px !important;
            line-height: 26px;
        }
    }
    .pt-36 {
        padding-top: 36px;
    }
    .highlight-bg {
        background-color: #082040;
        padding: 6px 12px;
    }
    .highlight-desc {
        border-radius: 0 0 40px 40px;
        padding: 32px 52px 34px 42px;
        gap: 26px;
        .highlight-ul {
            // list-style-image: url('/assets/images/check-circle.webp');
            padding-left: 25px;
        }
    }
    .layout-desc{
        padding: 10px 15px 10px;
        border-radius: 0 0 40px 40px;
    }
    .developer-desc, .faq-desc {
        padding: 28px 35px 32px;
        border-radius: 0 0 40px 40px;
        @media screen and (max-width: 767px){
            padding: 20px 15px 10px 0;
        }
    }
    .pdpcard-name-main {
        gap: 15px;
        .pdpcard-name-bhk {
            gap: 10px;
            padding-bottom: 20px;
            #project-name {
                font-size: 22px;
                line-height: 26px;
            }
            .bhk-tab {
                gap: 10px;
            }
        }
    }
    .pdp-card-detail {
        padding-block: 14px 18px;
        .amenities-gap {
            gap: 10px;
        }
        .pdp-amenties-tag {
            padding: 6px 12px 6px 6px;
            background-color: $white;
            border-radius: 6px;
        }
        .pdp-carpet-area {
            display: flex;
            gap: 8px;
            line-height: 24px;
            margin-bottom: 20px;
            margin-top: 18px;
        }
    }
    // Layout Plan
    .tab {
        &__list {
            display: flex;
            margin-bottom: 24px;
        }
        &__item {
            overflow: hidden;
            cursor: pointer;
            padding: 6px 12px;
            margin-right: 0px;
            text-shadow: 0 0 0.5px currentColor;
            letter-spacing: 1px;
            transition: 0.2s;
            font-size: 14px;
            position: relative;
            color: #082040;
            background-color: #DEEDE4;
            border-radius: 8px;
            height: 33px;
            min-width: 100px;
            text-align: center;
            @media (max-width:768px) {
                padding: 2px 5px;
                font-size: 12px;
                height: auto;
                min-width: 80px;
                border-radius: 4px;
            }
            &.is--active {
                color: #ffffff;
                background-color: #43A66A;
                position: relative;
            }
            &.tab__sub__item {
                background-color: transparent;
                color: #667486;
                border-radius: 0px;
                &.is--active {
                    color: #082040;
                    background-color: transparent;
                    border-bottom: 1px solid #B7B7B7;
                }
            }
        }
        .carpet-agreement-div {
            padding-bottom: 12px;
            border-bottom: 1px solid #f4f4f4;
        }
        &__content {
            padding: 0px 42px 42px;
            background: #ffffff;
            border-radius: 0 10px 10px;
            min-height: 400px;
        }
        &__content-item {
            display: none;
            &.is--active {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &__content-title {
            padding: 24px 24px 24px 0;
        }
    }
    .config-item{
        min-width: auto!important;
    }
    .tab .tab {
        .tab__item {
            font-size: 16px;
            white-space: nowrap;
            min-width: 121px;
            text-transform: capitalize;
        }
        .tab__content {
            padding: 0;
        }
    }
    // .layout-box-shadow {
    // }
    .layout-image-slider {
        .slick-list.draggable {
            box-shadow: 3px 3px 16px #0000001a;
        }
        .slick-track {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }
    .review-rating-circle {
        margin-top: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F3F3F3;
    }
    .region-review-people {
        padding-block: 20px;
    }
    .circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #43A66A;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .letter {
        font-size: 19px;
        line-height: 21px;
        color: white;
    }
    .text-plp {
        color: #202227;
        font-size: 14px;
    }
    // #element-1{
    .pdplist-background {
        padding: 19px 34px;
        @media screen and (max-width: 767px) {
            padding: 12px 20px;
        }
    }
    .amenities-desc {
        padding: 15px 0px;
        border-radius: 0 0 40px 40px;
        @media screen and (max-width: 767px) {
            padding: 15px;
            .text-plp{
                width: 100%!important;
            }
        }
    }
    .property-desc {
        padding: 38px 20px 42px 0px;
        border-radius: 0 0 40px 40px;
        .text-property ~ span{
            @media screen and (max-width:600px){
                font-size: 14px;
            }
            span{
                @media screen and (max-width:600px){
                    font-size: 14px;
                }
            }
        }
    }
    .project-loc-dec {
        padding: 28px 52px 38px 45px;
    }
    .rec-card {
        .rec-desc {
            padding: 14px 14px 19px;
        }
        input[type=checkbox] {
            width: 24px;
            height: 24px;
            accent-color: #ffffff;
        }
    }
    #rating-slider {
        // .slick-slide {margin: 0 5px;}
        // .slick-slide>div {padding: 0 5px;}
    }
    #reccomendate-slider {
        .slick-slide {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
        }
    }
    .contact-dev {
        padding-top: 18px;
    }
    .realestate-form {
        padding: 16px 20px 20px;
        border-radius: 12px;
        margin-top: 55px!important;
        #enquiry-form-pdp{
            .form-floating > .form-control{
                min-height: 50px;
                height: 50px;
            }
            .form-floating > label{
                top: -5px;
            }
            .form-check{
                margin-bottom:0.325rem;
            }
            .lasterror{
                bottom: -40px;
            }
        }
    }
    .similar-prop {
        background: #5d1a79;
        background: linear-gradient(271deg, #5d1a79 0%, #082040 50%);
        border-color: transparent;
        padding: 17px 20px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: #ffffff;
        display: inline-flex;
    }
    .similar-desc {
        padding: 20px;
    }
    .img-width {
        width: 32px;
    }
    .tab__list_sqft {
        border-bottom: 1px solid #f4f4f4;
    }

    .floorplan-unitplan-wrapper{
        .slick-slider{
            max-height: 500px;
            margin: auto;
            img{
                width: auto;
                max-height: 500px;
            }
        }
    }



    @media (max-width:1200px) {
        .fix-hw {
            width: 100%;
            height: 400px;
        }
    }
    @media (max-width:991px) {
        .amenities-desc {
            padding: 36.5px 42px;
        }
        .fix-hw {
            width: 100%;
        }
    }
    @media (max-width:480px) {
        .fix-hw {
            width: 100%;
            height: 288px;
        }
        .amenities-desc {
            padding: 5px 0;
        }
        .property-desc {
            padding: 10px 1px 10px 15px;
        }
        .project-loc-dec {
            padding: 26px 16px;
        }
        .highlight-desc {
            padding: 0px;
        }
        .layout-desc {
            padding: 20px 20px;
        }
        .tab__item {
            margin-right: 6px;
        }
        .tab__content {
            padding: 0px;
        }
        .tab__list_sqft {
            // width: 500px;
            // padding-bottom: 10px;
            // border-bottom: 1px solid;
        }
        .tab__item.tab__sub__item {
            margin-right: 0px;
            // padding: 0px;
            // width: 100%;
            // width: 700px;
            width: auto;
        }
    }
}

.pie_progress {
    text-align: center;
    position: relative;
    transform-style: preserve-3d;
}

.pie_progress svg {
    margin: 0 auto;
}

.pie_progress__content,
.pie_progress__number,
.pie_progress__label,
.pie_progress__icon {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.pie_progress__number {
    font-size: 42px;
}

.pie_progress__label {
    margin-top: 32px;
    font-size: 12px;
}

@media (max-width:1200px) {
    .projectdetail-card-main {
        gap: 20px !important;
    }
}
@media (max-width:992px){
    .project-detail-main{
        margin-top: 2rem !important;
    }
}
@media all and (min-width: 800px) {
    .fancybox-thumbs {
        top: auto;
        width: auto;
        bottom: 0;
        left: 0;
        right: 0;
        height: 95px;
        padding: 10px 10px 5px 10px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.3);
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 0;
        bottom: 95px;
    }
}

@media (max-width:767px) {
    .project-detail-main {
        margin-top: 1rem !important;
        .pdp-price-cards {
            &.parent {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 6px;
                grid-row-gap: 6px;
            }
        }
    }
}

.fancybox-thumbs__list a:before {
    border-color: $baseGreen !important;
}

.configflex{
    display: flex;
    align-items: baseline;
    @media (max-width:767px){
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .bhk-color{
        width: 55px;
    }
    .bhkp{
        width: max-content;
        color: #2E2F38;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        span{
            margin: 0 10px;
        }
    }
}
.proj-overview-head-wrapper{
    height: 60px;
    .proj-overview-head {
        position: relative;
        transition: all 0.7s ease-in-out;
        background-color: #F4F5FA;
        &.sticky{
            z-index: 1111;
            top: 84px;
            position: fixed;
            width: calc(75% - 4.8rem);
            transition: all 0.7s ease-in-out;
            @media ($max1024) {
                width: 90%;
            }
            @media ($max992) {
                top: 80px;
                width: 100%;
                left: 0px;
            }
            @media screen and (max-width:600px){
                top: 60px;
                position: fixed;
                width: 100%;
                left: 0;
            }
        }
        .layout-next,
        .layout-prev {
            top: 13%;
            z-index: 1;
        }
        .slick-list {
            padding: 0 20% 0 0 !important;
            color: #E6E6E6 !important;
        }
        .slick-initialized .slick-slide {
            color: #E6E6E6;
            &.slick-active {
                color: #202227;
            }
        }
        .btn{
            border:0px;
            @media (max-width:768px) {
                text-align: left;
                white-space: nowrap;
            }
            &.active{
                border:0px;
                border-bottom: 3px solid #43A66A;
            }
        }
        @media (max-width:768px) {
            top: 0px;
        }
        @media (max-width:400px) {
            .slick-list {
                padding: 0 17% 0 3% !important;
                color: #E6E6E6 !important;
            }
        }
    }
}

.marker-position {
    top: -35px;
    position: relative;
    padding: 5px;
    background-color: rgba(128, 128, 128, 0.5);
    word-wrap: break-word !important;
    word-break: break-word !important;
    // max-width: 150px;
    z-index: 9999;
}

.fancybox-slide--html {
    .map-popup-main {
        margin-top: 98px;
        width: 80%;
        background: transparent;
        .fancybox-close-small {
            color: #ffffff;
        }
    }
}
.right-filter-accordation,.top-filter-accordation{
    .accordion-item{
        border-color: transparent;
        .accordion-header{
            button{
                background-color: transparent;
                padding: 20px 0;
            }
        }
        .accordion-body{
            padding: 0px;
            &.filter-wrapbody{
                a{
                    font-size: 12px;
                    padding: 5px 10px;
                    text-transform: capitalize;
                    background-color: #EBEBEB;
                    border:0px;
                    color: #082040;
                    border-radius: 6px;
                    &.active{
                        background-color: #43A66A;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.top-filter-accordation{
    .accordion-header{
        &.active{
            button{
              padding-left: 20px!important;
            }
            &:after{
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: green;
                left: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
            .countNo{
                font-size: 12px;
            }
        }
        button{
            background-color: transparent;
            // padding: 10px 10px!important;
            border:1px solid #c5c5c5;
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
            padding-left: 0.5rem !important;
            font-size: 0.875rem !important;
            &:after{
                background-size: 12px;
                background-position: center;
            }
        }
    }
    .accordion-body{
        padding: 0 10px!important;
    }
   
}
.sort-by{
    font-size: 0.875rem !important;
}
.prjt-info-wrap{
    background-color: #f7f7f7;
    border-radius: 0 0 20px 20px;
}
.active-filter-tag{
    a{
        display: inline-block;
        position: relative;
        padding: 4px 5px 4px 14px;
        background-color: #192951;
        border-radius: 25px;
        color: #fff;
        text-transform: capitalize;
        margin-right: 6px;
        margin-bottom: 6px;
        font-size: 14px;
    }
}
.slick-prev:before, .slick-next:before{
    color: #000;
}
.specification-wrap{
    img{
        width: 80px;
        border: 1px solid #d3d3d3;
        padding: 10px;
        border-radius: 12px;
    }
    .inner-info{
        // position: absolute;
        // z-index: 1;
        // bottom: 0px;
        // left: 20px;
        // z-index: 2;
        .spec-title{
            font-size: 14px;
            line-height: 26px;
            color: #000;
            margin-bottom: 10px;
            font-weight: 500;
        }
        .spec-desc{
            font-size: 12px;
            line-height: 18px;
            color: #000;
        }
    }
    .accordion{
        button{
            background-color: rgba(217, 233, 228, 0.5) !important;
            background-color: #F4F5FA !important;
        }
    }
    // .inner-wrap:after{
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 150px;
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    // }
    @media screen and (max-width: 767px){
        .accordion-button{
            padding: 10px;
            font-size: 14px;
        }
    }
}
.specification-listing{
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 0;
}
.custom-loading-skeleton{
    background-color: #ededed!important;
}
// .mapwrap{
//     height: 450px;
// }
.mapwrap{
    position: relative;
    // &::after{
    //     content: "";
    //     position: absolute;
    //     left: 0px;    
    //     top: 0px;
    //     width: 100%;
    //     height: 100%;
    //     background-color: #00000071;
    //     z-index: 10000;
    // }
}
.project-detail-main{
    .rank-wrap{
        left: 10px;
        top: 10px;
    }
    .extraGalleryImg{
        width: 121px;
        max-width: 121px;
        flex: 0 0 121px;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        @media screen and (max-width: 767px){
            width: 100%;
            max-width: 100%;
            flex: 0 0 78px;
        }
        img{
            width: 132px;
            height: 132px;
            object-fit: cover;
            border-radius: 12px;
            margin: 10px 0;
            @media screen and (max-width: 767px){
                width: 80px;
                height: 60px;
                object-fit: cover;
                border-radius: 12px;
                // margin: 3px 10px;
                margin: 0;
            }
        }
        .total-img{
            position: relative;
            span{
                width: 100%;
                height: 132px;
                border-radius: 12px;
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 16px;
                line-height: 20px;
                color: #fff;
                position: absolute;
                top: 10px;
                left: 0;
                justify-content: center;
                display: flex;
                align-items: center;
                font-weight: 700;
                text-decoration: underline;
                @media screen and (max-width: 767px){
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                }
            }
        }
    }
    .project-right-content{
        border-radius: 20px;
        background-color: #F7F7F7;
        padding: 20px 30px;
        float: left;
        @media screen and (max-width: 767px){
            padding: 10px 17px;
        }
    }
    .theme-color{
        color: #082040;
    }
    .pdl-amenities{
        height: auto!important;
        width: 90%;
        overflow: hidden;
    }
    // .image-side{
    //     height: auto!important;
    // }
    .bw-score{
        color: #43A66A;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }
    .project-location{
        font-size: 16px;
        color: #667486;
        line-height: 30px;
        @media screen and (max-width: 767px){
            font-size: 12px;
            line-height: 18px;
        }   
    }
    .developer-stats{
        p{
            margin-bottom: 10px;
            font-size: 32px;
            line-height: 26px;
            color: #202227;
            font-weight: 700;
        }
        span{
            font-size: 12px;
        }
    }
    .image-side{
        width: 421px;
        flex: 0 0 auto!important;
        height: 450px!important;
        .project-img{
            height: 421px!important;
        }
        @media ($max1024) {
            width: 100%;
            height: auto;
        }
        @media screen and (max-width: 767px){
            width: 100%;
            height: auto;
            .project-img{
                height: 200px;
            }
        }
        @media screen and (min-width: 1600px){
            width: 500px;
            height: auto;
            .project-img{
                height: 421px;
            }
        }
    }
}
.top-picks{
    bottom: 0px;
    background-color: #FFFFFF;
    border-radius: 8px;
    color: #459A62;
    padding: 3px 7px;
    font-size: 11px;
    line-height: 20px;
    @media (max-width:767px) {
        font-size: 9px;
        padding: 3px;
        padding-right: 10px;
    }
    span{
        line-height: 12px;
    }
}
.project-detail-main{
    .top-picks{
        @media ($max640) {
            font-size: 9px;
            margin: 20px 5px !important;
        }
    }
    .plp-card .rera-main-div{
        @media ($max640) {
            padding: 10px 10px;
            display: none;
        }
        span{
            @media ($max640) {
                font-size: 12px;
            }
        }
     }
    .plp-card .rera-main-div p span{
        @media ($max640) {
            font-size: 10px;
        }
    }
}
.carpet-agreement-div{
    h5{
        font-size: 16px;
        line-height: 23px;
        color: #082040;
    }
}

.right-card-one{
    .right-card-one-inner{
        background: transparent linear-gradient(180deg, #082040 0%, #27589A 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 30px;
        height: 431px;
        .inner-content{
            padding: 25px 35px 0;
            z-index: 2;
            position: relative;
            z-index: 1111;
        }
        h3{
            font-size: 30px;
            line-height: 36px;
            color: #FFFFFF;
            font-weight: 700;
            padding: 5px 0;
        }
        a{
            
        }
        .scorecard{
            height: 280px;
            width: 100%;
            bottom: -12px;
            img{
                position: absolute;
                bottom: 0;
            }
            p{
                font-size: 18px;
                line-height: 27px;
                color: #43A66A;
                font-weight: 700;
                position: absolute;
                top: 115px;
                left: 45px;
                transform: rotate(3deg);
            }
            .scoreValue{
                position: absolute;
                top: 150px;
                left: 85px;
                transform: rotate(3deg);
            }
        }
        .popup-body{
            width: 70%;
            left: 50%;
            top: 50%;
            right: unset;
            bottom: unset;
            transform: translate(-50%, -50%);
            padding: 30px;
            z-index: 1111;
            .close{
                right: 10px;
                top: 0px;
            }
            .pop-cont{
                background-color: #F7F7F7;
                border-radius: 20px;
                .pop-head{
                    font-size: 32px;
                    line-height: 28px;
                    color: #082040;
                    font-weight: 700;
                    margin-top: 20px;
                }
                .pop-desc{
                    color: #667486;
                    margin-top: 20px;
                }
            }
            .score-div {
                display: grid;
                grid-template-columns: 33.33% 33.33% 33.33%;
                margin-top: 20px;
                .score-box{
                    margin: 0 10px;
                    background-color: #F7F7F7;
                    padding: 20px;
                    border-radius: 20px;
                    .score-head{
                        color: #43A66A;
                        font-weight: bold;
                        font-size: 24px;
                        margin-bottom: 10px;
                    }
                    .score-number{
                        font-size: 50px;
                        line-height: 60px;
                        font-weight: 600;
                    }
                    .score-number1{
                        color: #43A66A;
                    }
                    .score-number2{
                        color: #DEAC00;
                    }
                    .score-number3{
                        color: #DD1B0F;
                    }
                    .rate{
                        font-size: 40px;
                        line-height: 60px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.brochure-wrap{
    .brochure-inner{
        background-image: url('/Assets/img/download-brochure.png');
        // height: 154px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .user-info{
            border-bottom: 1px solid #43A66A;
            .nameTitle{
                font-size: 33px;
                line-height: 60px;
                color: #48AC70;
                background-color: #DBE9E2;
                width: 68px;
                height: 68px;
                border: 2px solid #48AC70;
                border-radius: 100%;
                display: inline-block;
                text-align: center;
                font-weight: 700;
            }
            .user-name-info{
                p{
                    font-size: 24px;
                    line-height: 30px;
                    color: #fff;
                    padding-top: 10px;
                    span{
                        color: #48AC70;
                        font-size: 24px;
                        line-height: 14px;
                        font-weight: 700;
                    }
                }
                span{
                    font-size: 14px;
                    line-height: 34px;
                    color: #CBC8C8;
                }
            }
        }
        .download-btn-info{
            .happy-face{
                background-color: #DCE8E2;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                display: inline-block;
                text-align: center;
                line-height: 24px;
                padding: 1px;
                svg{
                    background-color: #48AC70;
                    padding: 1px;
                    border-radius: 100%;
                }
            }
            p{
                color: #fff;
                font-size: 14px;
                @media ($max1280) {
                    font-size: 12px;
                }
            }
            a{
                position: relative;
                font-weight: 700;
                font-size: 13px;
                @media ($max1280) {
                    font-size: 10px;
                }
                svg{
                    position: absolute;
                    right: -15px;
                    top: 10px;
                }
            }
        }
    }
}
.releated-dev-wrap{
    .releated-dev-inner{
        background: #43A66A 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 30px;
        padding: 40px 30px 0;
        h3{
            font-size: 30px;
            line-height: 36px;
            color: #FFFFFF;
            font-weight: 700;
        }
        a{
            background-color: #082040!important;
            padding: 5px 20px;
            font-weight: 700;
        }
        img{
            margin-bottom: -25px;
        }
    }
}
$slideBgColor: rgba(255,255,255,0.3);
$slideBallColor: white;
$slideBallIconColor: black;
$slideTextColor: #3a3d55;
.wishlist-banner{
    background-image: url('../img/wishlist-bg-banner.png');
    height: 200px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media screen and (max-width:600px){
        height: auto;
        border-radius: 40px;
    }
    .wishlist-inner{
        margin-top: -49px;
        @media screen and (max-width:600px){
            margin-top: 0px;
            flex-direction: column-reverse;
        }
    }
    .inner{
        h3{
            font-size: 36px;
            line-height: 44px;
            font-weight: 700;
            &.hurryUp{
                @media (max-width:1440px) {
                    font-size: 24px;
                    line-height: normal;
                }
                @media ($max992) {
                    text-align: center;
                }
            }
            @media screen and (max-width:600px){
                font-size: 26px;
                line-height: 32px;
            }
        }
        #button-background {
            position: relative;
            background: rgb(36,85,224);
            background: linear-gradient(90deg, rgba(36,85,224,1) 0%, rgba(255,255,255,0) 100%);
            width: 272px;
            height: 33px;
            border: white;
            border-radius: 40px;
            text-align: center;
            margin-top: 20px;
            margin-left: 20px;
            @media(max-width:767px){
                margin-inline: auto;
            }
        }
        
        #slider {
            transition: width 0.3s,
                border-radius 0.3s,
                height 0.3s;
            position: absolute;
            left: -10px;
            top: -5px;
            background-color: $slideBallColor;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            &.unlocked {
                transition: all 0.3s;
                width: inherit;
                left: 0 !important;
                height: inherit;
                border-radius: inherit;
            }
        }
        
        .material-icons {
            color: $slideBallIconColor;
            font-size: 50px;
        }
        
        .slide-text {
            color: #fff;
            font-size: 16px;
            text-transform: capitalize;
            position: relative;
            top: 4px;
        }
        
        .bottom {
            position: fixed;
            bottom: 0;
            font-size: 14px;
            color: white;
            a {
                color: white;
            }
        }
        
    }
}
.similer-project{
    @media ($max640) {
        padding: 0 15px;
    }
    .slick-slider{
        // padding: 10px 0;
    }
    .slick-track{
        @media (min-width:1200px){
            display: flex!important;
        }
        .slick-slide{
            padding: 10px 0;
            @media (min-width:1200px){
                // height: inherit!important;
            }
            div:first-child{
                @media (min-width:1200px){
                    // height: 100%;
                }
            }
            .card{
                @media (min-width:1200px){
                    height: 100%!important;
                }
            }
        }
    }
    // img{
    //     height: 250px;
    //     object-fit: cover;
    // }
    .card-body{
        h5{
            font-size: 14px;
            line-height: 29px;
            font-weight: 700;
            color: #202227;
            font-family: "century-gothic";
            font-display: swap;
            max-width: 190px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
            color: #202227;
            font-weight: 700;
            min-width: 120px;
            text-align: right;
        }
    }
    .card-footer{
        background-color: #fff;
        p{
            font-size: 14px;
        }
    }
    p{
        font-size: 14px;
    }
    .top-projects-slider{
        // .slick-track{
        //     display: flex !important;
        // }
        @media ($max992) {
            .slick-dots{
                margin-top: 0px;
                display: flex;
                justify-content: center;
                gap: 10px;
                li{
                    background-color: $pc;
                    text-indent: -9999px;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    &.slick-active{
                        background-color: $sc;
                        width: 30px;
                        border-radius: 8px;
                    }
                    &::marker{
                        font-size: 0;
                    }
                    button{
                        &:before{
                            font-size: 0;
                        }
                    }
                }
            }
        }
        .slick-slide{
            margin-top: 0px;
            @media ($max640) {
                padding: 0 10px;
            }
            .top-projects-card{
                @media ($max640) {
                    padding: 10px 10px;
                }
            }
            
        }
        .slick-arrow{
            &.slick-prev{
                margin-left: -18px;
                @media screen and (max-width: 993px){  
                    margin-left: -10px;
                }
            }
            &.slick-next{
                @media screen and (max-width: 993px){  
                    margin-right: -10px;
                }                
            }
        }
        .text-grey{
            color: #667486;
        }
        .top-projects-card{
            // box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 15px 10px -15px #111;
            // box-shadow: unset;
            border-radius: 40px;
            overflow: hidden;
            padding: 10px;
            background-color: #fff;
            width: 370px!important;
            @media screen and (max-width: 767px){
                width: 100%!important;
                // margin: 0 10px;
            }
            .top-projects-img{
                img{
                    height: 215px;
                    width: 390px;
                    border-radius: 30px;
                    @media screen and (max-width: 767px){
                        width: 100%;
                        height: 200px;
                    }
                }
                svg{
                    background: #192951;
                    border-radius: 100%;
                    #Icon_ionic-ios-heart{
                        stroke: #fff;
                    }
                }
                a, a:hover, a:focus, a:active, a:visited{
                    outline: none;
                }
            }
            .top-projects-details{
                padding: 0px 20px;
                box-sizing: border-box;
                background-color: #fff;
                position: relative;
                width: 100%;
                height: 228px;
                padding-top: 20px;
                @media screen and (max-width:992px){
                    width: 100%;
                    height: unset;
                    min-height: 220px;
                    // padding-bottom: 20px;
                }
                @media ($max640) {
                    min-height: 250px;
                    padding-bottom: 10px;
                }
                // .project-price{
                //     color: #192951;
                //     // font-family: Poppins;
                //     font-size: 30px;
                //     font-style: normal;
                //     font-weight: 700;
                //     line-height: normal;
                //     text-transform: capitalize;
                //     margin-top: 15px;
                //     display: block;
                //     @media (max-width:992px){
                //         font-size: 24px;
                //     }
                // }
                .dev-name{
                    font-size: 14px;
                    color: #657489;
                    line-height: 18px;
                    margin-bottom: 10px;
                    width: 300px;
                    @media (max-width:992px){
                        width: auto;
                        text-wrap: balance;
                    }
                }
                .prj-name{
                    font-size: 22px;
                    font-weight: 800;
                    color: #000;
                    line-height: 28px;
                    margin-bottom: 15px;
                }
                .conf-desc{
                    color: #949494;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 10px;
                }
                .rera-desc{
                    color: #949494;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 7px;
                    }
                }
                // .explore-btn{
                //     background-color: #192951;
                //     border-radius: 100px;
                //     color: #fff;
                //     font-size: 22px;
                //     text-decoration: none;
                //     display: inline-block;
                //     width: 35px;
                //     height: 35px;
                //     display: block;
                //     text-align: center;
                //     line-height: 30px;
                //     position: absolute;
                //     bottom: 0px;
                //     right: 0px;
                //     @media (max-width:992px){
                //         right:-10px;
                //     }
                // }
                // .pricebox {
                //     position: relative;
                //     width: 100%;
                // }
            }
        }
    }
}
// .compare-wrap{
//     background-color: #F8990C;
// }
.project-ratting{
    .ratting-desc{
        border-radius: 40px;
        padding: 30px;
        @media (max-width:992px) {
            justify-content: center;
        }
        .project-img{
            img{
                border-radius: 40px;
                height: 234px;
                width: 254px;
                object-fit: cover;
                object-position: center;
                @media ($max992) {
                    width: 100%;
                    height: auto;
                }
                @media ($max640) {
                    height: 234px;
                    width: 254px;
                }
            }
            @media (max-width:992px) {
                padding-right: 0 !important;
            }
        }
        .ratting-info{
            h4{
                font-size: 32px;
                line-height: 38px;
                color: #082040;
                font-weight: 700;
            }
            p{
                font-size: 14px;
                line-height: 26px;
                color: #082040;
            }
            
        }
        .fill-review-form {
            .ratting-info{
                @media ($max640) {
                    display: grid !important;
                    grid-template-columns: auto auto auto;
                    justify-content: center !important;
                    grid-gap: 5px 0;
                }
                .review-form-title{
                    @media ($max640) {
                        grid-column: 1 / span 3;
                    }
                }
            }
        }
    }
}
.pdp-rank-wrap{
    .rank-in-locality{
        margin-left: 15px!important;
        padding-left: 0px!important;
        padding-right: 5px!important;
        position: relative;
        top: 1px;
        background: transparent linear-gradient(280deg, #43A66A 10%, #8DC356 41%, #4DAA68 100%) 0% 0% no-repeat padding-box!important;
        &:before{
            background-image: url('/Assets/img/patch-1.svg');
            position: absolute;
            left: -25px;
            top: 0px;
            content: '';
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }
        &:after{
            background-image: url('/Assets/img/patch-2.svg');
            border-right: 0!important;
            border-top: 0!important;
            border-bottom: 0!important;
            position: absolute;
            right: -25px !important;
            top: 0px !important;
            content: '';
            width: 30px;
            height: 30px !important;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }
    }
    .circle-1{
        background-color: transparent!important;
    }
}
.mainSectionAccordion{
    border-radius: 40px;
    > .accordion-item{
        border: 0;
        border-radius: 40px;
        > .accordion-header{
            border-radius: 40px;
            > button{
                padding: 0;
                border-radius: 40px!important;
                background-color: rgba(217, 233, 228, 0.5)!important;
                > .pdplist-background{
                    border-radius: 40px;
                }
                &:after{
                    position: relative;
                    right: 40px;
                }
            }
        }
    }
}
.fill-review-form{
    .review-form-title{
        font-size: 14px;
        line-height: 26px;
        color: #082040;
        font-weight: 700;
    }
    .ratting-info{
        .react-stars{
            span{
                line-height: 20px;
            }
        }
        .total-rating-emogi{
            width: 30px;
        }
    }
    .project-parameter-list {
        p {
            input{
                opacity: 0;
                position: absolute;
            }
            label{
                padding: 5px 10px;
                background-color: #EBEBEB;
                border-radius: 6px;
                font-size: 12px;
                line-height: 15px;
                color: #082040;
                cursor: pointer;
            }
            input:checked ~ label{
                background-color: #43A66A;
                color: #fff;
            }
        }
    }
    .write-review{
        margin-bottom: 10px;
        textarea{
            border: 1px solid #9A9A9A;
            border-radius:16px;
            padding: 10px;
            width: 100%;
            height: 68px;
            resize: none;
            font-size: 14px;
        }
        a{
            width: 95px;
            color: #fff;
            padding: 10px 5px; 
            text-align: center;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 700!important;
            line-height: 20px;
            height: 40px;
            background-color: #43A66A;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .recommend-friend{
        input{
            accent-color: #43A66A;
        }
        label{
            font-size: 12px;
            line-height: 26px;
            color: #9A9A9A;
            cursor: pointer;
        }
    }
}
.ReactModal__Overlay{
    z-index: 9999999!important;
}
@media ($max1024){
    .project-detail-main{
        .pdp-details-card{
            display: grid !important;
            grid-template-columns: 80% auto;
            .project-right-content{
                grid-column: 1/span 2;
                width: 98%;
            }
        }
    }
}
@media ($max992){
    .project-detail-main{
        .pdp-details-card{
            grid-template-columns: 75% auto;
            .project-right-content{
                // grid-column: 1/span 2;
                // width: 98%;
            }
        }
    }
    .layout-mobile-slider{
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
}
@media screen and (max-width: 767px){
    .layout-mobile-slider{
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
    .map-plp-price-cards{
        grid-template-columns:repeat(1, 1fr)!important;
    }
    .map-image-side{
        width: 120px;
        float: left;
    }
    .map-info-side{
        width: calc(100% - 120px);
        float: right;
        .plp-price-cards{
            min-width: 125px;
        }
    }
    .mapview-wrap{
        .mapview-overlay{
            position: absolute;
            width: calc(100% - 20px);
            height: 100%;
            border-radius: 25px;
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 10px;
            z-index: 9991;
        }
    }
    .project-detail-main{
        .pdp-details-card{
            display: block!important;
            .image-side{
                width: calc(100%);
                height: 200px!important;
                float: left;
                // border-radius: 0px;
                // height: 330px!important;
                .project-img{
                    height: 200px!important;
                }
                &.full-img{
                    width: 100%;
                }
            }
            .extraGalleryImg{
                // float: left;
                width: 100%;
                flex-direction: row !important;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: space-between !important;
                gap: 0 5px;
            }
            .project-right-content{
                width: 100%;
            }
        }
    }
}

.highligh-img{
    width: 200px;
    .inner-wrap{
        width: 200px;
        position: relative;
        img{
            position: relative;
            z-index: 1;
        }
        &:after{
            content: "";
            position: absolute;
            bottom: -1px;
            left: -11px;
            width: calc(100% - 25px);
            height: calc(100% - 15px);
            background: #43A66A;
            border-radius: 20px;
            z-index: 0;

        }
    }
}
.highlight-ul{
    list-style: none;
    counter-reset: my-awesome-counter;
    li{
        padding: 8px;
        position: relative;
        p{
            box-shadow: 0px 0px 10px #00000026;
            border-radius: 18px;        
            padding: 20px 20px 20px 20px;
            position: relative;
            height: 100%;
            font-size: 14px;
            color: #667486;
            @media screen and (max-width:767px) {
                padding: 10px 10px 10px 30px;
            }
        }
        &:nth-child(1),
        &:nth-child(2){
            flex: 1;
            flex-basis: 50%;
            &::before {
            top: -20px;
            right: 15px;
            @media screen and (max-width:767px) {
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
            }
            }
        }
        
        &::before {
            content: counter(my-awesome-counter);
            background-color: #43A66A;
            opacity: 1;
            box-shadow: 0px 0px 10px #00000029;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: inline-block;
            line-height: 2.5rem;
            color: white;
            text-align: center;
            margin-right: 0.5rem;
            position: absolute;
            font-size: 18px;
            font-weight: 700;
            z-index: 1;
        }
    }
    &.row-first{
        li{
            counter-increment: my-awesome-counter;
        }
    }
    &.row-second{
        counter-reset: my-counter 2;
        li{
            flex: 1;
            flex-basis: 33.33%;
            counter-increment: my-counter;
            &::before{
                content: counter(my-counter);
                top: unset;
                bottom: -15px;
                right: 15px;
                @media screen and (max-width:767px) {
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
.scroll-to-center {
    justify-content: center; /* Center the menu horizontally */
}
.amenitiesNearBywrap{
    background-color: #fff;
    padding: 10px 30px;
    border-radius: 0 0 40px 40px;
    margin-top: 0px;
    .amenitiesNearBy{
        ul{
            @media  screen and (max-width:767px){
                overflow: auto;
                padding-bottom: 20px;
            }
            
            li{
                margin-right: 10px;
                height: 83px;
                width: 81px;
                text-align: center;
                cursor: pointer;
                @media ($max640) {
                    // min-width: 81px;
                    height: auto;
                }
                &.active{
                    background-color: #43A66A;
                    border-radius: 10px;
                    color: #fff;
                    width: 100%;
                    path, circle{
                        fill: #fff;
                    }
                }
                padding: 10px;
                p{
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}
.map-inner-info{
    font-size: 14px;
}
.totPrice{
    color: #6C6F74;
}

.rera-qr-wrapper {
    display: grid;
    gap: 20px; // Spacing between items
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 20px; // Optional padding around the wrapper

    .rera-qr-image {
        text-align: center;
        padding: 15px;
        // border: 1px solid #ddd;
        border-radius: 8px;
        // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        img {
            width: 100%;
            max-width: 250px;
            margin: 0 auto 10px; // Center align and add spacing below the image
            display: block;
            border-radius: 4px;
        }

        p {
            margin: 5px 0;
            font-size: 14px; // Adjust as needed
            line-height: 1.5;
            color: #333;
        }

        a {
            // color: #007bff;
            text-decoration: none;
            font-size: 14px;
            word-break: break-word; // To prevent long URLs from breaking layout

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.rera-desc{
    border-radius: 0 0 40px 40px;
}

// Media Queries for Responsiveness
@media (min-width: 768px) {
    .rera-qr-wrapper {
        grid-template-columns: repeat(2, 1fr); // 2 in a row for tablets
    }
}

@media (min-width: 992px) {
    .rera-qr-wrapper {
        grid-template-columns: repeat(3, 1fr); // 3 in a row for desktops
    }
}
