.ourPerform {
    padding-top: 50px;
  }
  
  .cs-slider-wrapper {
    position: relative;
  }
  
  .cs-box {
    display: inline-block;
    position: relative;
    .border-radius-12 {
      border-radius: 12px;
    }
    .cs-slider-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  .cs-slider-content-wrapper {
    color: white;
    .cs-slider-head {
      font-weight: bold;
    }
  }
  
  .cs-slider-result-wrapper {
    .cs-slider-result {
      .text-bold {
        font-weight: bold;
      }
    }
  }
  
  .controls-div {
    display: inline-block;
    #cs-prev,
    #cs-next {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  
  .theme-btn {
    background-color: #192951;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    img {
      margin-left: 10px;
    }
  }
  