//   Christmas Banner
@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}
.hero-section{

  &.independece-banner{
      @media (min-width:992px){
          margin-top: 25px;
      }
      .hero-cont{
          @media (max-width:1440px){
              height: 610px;
          }
          @media (max-width:992px){
              height: auto;
          }
          @media (max-width:767px){
              height: 80vh;
          }
          // .hero-main-1{
          //     @media (min-width:992px){
          //         padding-top: 50px;
          //     }
          // }
      }
      .ind{
        position: absolute;
        bottom: 0;
        left: 0;
          @media (min-width:1400px){
              position: absolute;
              bottom: 0;
              left: 0;
          }
          @media (max-width:1025px){
              margin: auto;
              margin-left: 0;
              width: 560px;
              display: block;
          }
          @media (max-width:992px){
              margin: auto;
              width: 75%;
              margin-bottom: -16px;
              position: unset;
          }
      }
      
      .planessvg{
          position: absolute;
          top: -95px;
          right: 265px;
          @media (max-width:1025px){
              top: -82px;
              right: 120px;
              width: 650px;
              height: auto;
          }
          @media (max-width:992px){
              right: 50px;
              width: 718px;
              height: auto;
              top: 61px;
          }
          @media (max-width:767px){
              right: 0;
              width: 374px;
              height: auto;
              top: -40px;
          }
      }
      .whiteover3{
          animation: diagonal-move-anim3 2.5s linear forwards;
        animation-delay:0.4s;
        width: 30px;
        height: 600px;
        transform: translate(216px, 138px) rotate(-59deg) scaleY(1);
        fill-rule: nonzero;
        fill: #42B57E;
        stroke-width: 0px;
        stroke-opacity: 0;
      }
      @keyframes diagonal-move-anim3{
        0% {
          transform: translate(216px, 138px) rotate(-59deg) scaleY(1);
        }
        100% {
          transform: translate(216px, 138px) rotate(-59deg) scaleY(0);
        }
      }
      .whiteover2{
          animation: diagonal-move-anim 2.8s linear forwards;
        width: 30px;
        height: 720px;
        transform: translate(91px, 123px) rotate(-59deg) scaleY(1);
        fill-rule: nonzero;
        fill: #42B57E;
        stroke-width: 0px;
        stroke-opacity: 0;
      }
      @keyframes diagonal-move-anim {
        0% {
          transform: translate(91px, 123px) rotate(-59deg) scaleY(1);
        }
        100% {
          transform: translate(91px, 123px) rotate(-59deg) scaleY(0);
        }
      }
      .whiteover1{
          animation: diagonal-move-anim1 2.6s linear forwards;
          width: 30px;
          height: 590px;
          animation-delay: 0.2s;
          transform: translate(170px, 230px) rotate(-59deg) scaleY(1);
          fill-rule: nonzero;
          fill: #42B57E;
          stroke-width: 0px;
          stroke-opacity: 0;
      }
      @keyframes diagonal-move-anim1{
        0% {
          transform: translate(170px, 230px) rotate(-59deg) scaleY(1);
        }
        100% {
          transform: translate(170px, 230px) rotate(-59deg) scaleY(0);
        }
      }
      .plane1{
        animation: plane-move-anim 2.7s linear forwards;
      }
      @keyframes plane-move-anim {
        0% {
          transform: translate(600px, 360px);
        }
        100% {
          transform: translate(0, 0);
        }
      }
      .plane2{
        animation: plane-move-anim2 2.75s linear forwards;
      }
      @keyframes plane-move-anim2 {
        0% {
          transform: translate(650px, 392px);
        }
        100% {
          transform: translate(0, 0);
        }
      }
      .plane3{
        animation: plane-move-anim3 2.7s linear forwards;
      }
      @keyframes plane-move-anim3 {
        0% {
          transform: translate(650px, 392px);
        }
        100% {
          transform: translate(0, 0);
        }
      }
  }
  &.christmas-banner{
      @media (min-width:992px){
          margin-top: 40px;
      }
  
      .arrowd{
          bottom: 50px;
      }
      overflow: hidden;
      .snow{
          z-index: 1!important;
      }
      position: relative;
      > * {
          position: relative;
          // z-index: 2;
        }       
      // background: url(../images/christmas-banner.jpg) no-repeat center center;
      .hero-cont{
          position: relative;
          // height: 700px;
          overflow: unset;

          
          
          .heroimg{
              border-radius: 0px 0px 80px;
              right: 12px;
              @media (min-width: 1501px) {
                  width: auto;
                  position: absolute;
                  bottom: 0;
                  max-width: unset;
                  // right: 0;
              }
              @media (min-width: 1200px) and (max-width: 1500px) {
                  max-width: 500px;
                  width: auto;
                  position: absolute;
                  bottom: 0;
              }
              @media (max-width:992px){
                  border-radius: 0px 0px 50px;
              }
          }
          &:after{
              content: url(../img/xmas-deco-1.svg);
              position: absolute;
              bottom: -80px;
              left: 40px;
              width: 700px;
              height: auto;
              z-index: 1;
              @media (max-width:992px){
                 display: none;
              }
          }
          &:before{
              content: url(../img/xmas-santa.svg);
              position: absolute;
              bottom: 0px;
              left: -80px;
              width: 158px;
              height: 199px;
              z-index: 2;
              scale: 1;
              @media (min-width: 1200px) and (max-width: 1500px) {
                  scale: 0.7;
                  bottom: -25px;
                  left: -65px;
              }
              @media (max-width:992px){
                  scale: 0.6;
                  left: -40px;
                  bottom: -40px;
              }
          }
      }
  }
  // .hero-cont
}
