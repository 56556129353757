// Place all the styles related to the frontend controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
html,body{
    font-family: Poppins;
    background: #fff;
    scroll-behavior: smooth;
    transition: overflow 0.2s ease-in-out;
}
.century-font{
    font-family:"century-gothic";
    font-display: swap;
}
h1, h2, h3, h4, h5, h6{
    font-family:"century-gothic";
    font-display: swap;
}
.user-activity-data{
    .rounded-3{
        margin: 1rem!important;
    }
    .register-img{
        display: none!important;
        
    }
} 
.text-orange{
    color: #FFB03D !important;
}

//some common css use
.theme-btn-blue{
    border-radius: 24px;
    background-color: #fff;
    // height: 43px;
    color: #000;
    padding: 10px 10px;
    padding-right: 75px;
    text-decoration: none;
    font-size: 14px;
    width: fit-content;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 90%;
    @media (max-width:992px){
        margin: auto;
        margin-top: 10px;
    }
}
.hidden-ipro{
    display: block;
    @media ($max1024) {
        display: none;
    }
}
.visible-ipro{
    display: none;
    @media ($max1024) {
        display: block;
    }
}
.hidden-ipad{
    display: block;
    @media ($max992) {
        display: none;
    }
}
.visible-ipad{
    display: none;
    @media ($max992) {
        display: block;
    }
}
.hidden-mob{
    display: block;
    @media ($max640) {
        display: none;
    }
}
.visible-mob{
    display: none;
    @media ($max640) {
        display: block;
    }
}
.themeBtn{
    color: #fff;
    border-radius: 48px;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    background-color: #192951;
    padding: 10px 25px;
    display: inline-block;
    width: fit-content;
    @media ($max640) {
        font-size: 16px;
        border-radius: 12px;
        padding: 10px 15px;
    }
}

.ceosec{
    .sec-title{
        font-size: 33px;
    }
    .col-xl-6{
        padding: 0px;
    }
    .ceobox{
        background-color: #42B57E;
        border-radius: 40px;
        display: flex;
        align-items: center;
        color: #fff;
        margin-right: 12px;
        height: 100%;
        @media (max-width:992px){
            margin-left: 12px;
        }
        .ceoimg{
            margin-left: -26px;
            margin-top: -25px;
            border-radius: 0px 0px 0px 82px;
            @media (max-width:992px){
                margin-left: -12px;
                margin-top: -25px;
                border-radius: 0px 0px 0px 60px;
            }
        }
        .ceo-cont{
            margin-left: 10px;
            @media (max-width:992px){
                margin-left: 0px;
            }
        }
    }
    .ceodesc{
        border-radius: 40px;
        background-color: #F7F8F8;
        padding: 25px 20px;
        height: 100%;
    }
}
.ceovid{
    border-radius: 40px;
    width: 100%;
    height: 90vh;
    @media (max-width:992px){
        height: 35vh;
    }
}
.ceobtn{
    margin-right: 25px;
    @media (max-width:992px){
        margin-right: 0px;
    }
    a{
        border-radius: 12px;
        background: #42B57E;
        color: #fff;
        text-decoration: none;
        padding: 13px 23px;
        @media (max-width:767px) {
            padding: 9px 13px;
        }
    }
}
.customai{
    border-radius: 40px;
    background-color: #192951;
    .subhead{
        color: #FFD600;
        line-height: 0px;
        font-weight: 700;
    }
    .housebox{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -85px;
        margin-right: 50px;
        @media (max-width:992px){
            margin-top: 0px;
            margin-right: 0px;
        }
        .chipimg{
            margin-top: -10px;
        }
    }
}
.irfscominganchor{
    text-decoration: none;
}
.irfscoming{
    border-radius: 50px;
    background: url('../img/comingbg-single.png'), #FFB03D;
    background-repeat: repeat;
    padding-left: 35px;
    margin-top: 100px;
    @media (max-width:992px){
        padding-left: 0px;
        margin-top: 0px;
    }
    .irfstitle{
        color: #fff;
        text-shadow: 0px 2px 1.9px #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: normal;
        margin-left: 5px;
        @media (max-width:992px){
            font-size: 24px;
            text-align: center;
        }
    }
    .soon{
        color: #fff;
        text-shadow: 0px 4px 4px #000;
        font-size: 96px;
        font-style: normal;
        font-weight: 600;
        line-height: 102%; /* 97.92px */
        @media (max-width:992px){
            font-size: 48px;
            text-align: center;
        }
    }
    .local-project-btn{
        border-radius: 12px;
        background-color: #192951;
        height: 43px;
        color: #fff;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 14px;
        width: fit-content;
        margin-top: 20px;
        @media (max-width:992px){
            margin: auto;
            margin-top: 10px;
        }
    }
    .irfshome{
        @media (max-width:992px){
            position: absolute;
            width: 200px;
        }
    }
    .sharmanirfshome{
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 340px;
        @media (max-width:992px){
            display: block;
            position: relative;
            margin: auto;
            width: 230px;
            bottom: -24px;
            right: unset;
        }
    }
    .irfslogomob{
        margin: auto;
        width: 163px;
        margin-bottom: 20px;
    }
}
.hero-section {
    // background-image: url('img/home-page-bg.webp');
    // min-height: calc(100vh);
    background-position: center 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 90px;
    @media screen and (max-width: 375px){
        min-height: 100vh;
        margin-top: 50px;
        padding-top: 20px;
    }
    .arrowd{
        position: absolute;
        bottom: -60px;
        cursor: pointer;
        z-index: 1;
    }
    .hero-main{
        padding: 0;
    }
    .hero-cont{
        background-color: #42B57E;
        border-radius: 80px;
        padding: 0px 0px 0px 40px;
        overflow: hidden;
        height: 548px;
        @media (min-width:992px) and (max-width:1030px){
            padding: 25px 0px 0px 17px;
        }
        @media (max-width:992px){
            padding: 40px 0px 0px;
            border-radius: 50px;
            height: auto;
            margin: 0 10px;
        }
        @media (max-width:375px) {
            min-height: 80vh;
        }
        @media (min-width:1440px){
            height: 85vh;
        }
        @media (min-width:1800px){
            height: 70vh;
        }
        .hero-main-1{
            padding-left: 40px;
            @media (min-width:992px) and (max-width:1030px){
                padding-left: 30px;
            }
            @media (min-width:1800px){
                margin-left: 40px;
            }
            @media (max-width:992px){
                padding-left: 12px;
            }
        }
        .h100{
            height: 100%;
            align-items: center;
            position: relative;
            @media (min-width:1800px){
                align-items: end;
            }
        }
        .heroimg{
            float: right;
            width: 90%;
            @media (min-width:992px) and (max-width:1030px){
                position: absolute;
                bottom: 0;
                right: 0;
                width: 370px;
            }
            @media (min-width:1600px){
                width: auto;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            @media (min-width:1800px){
                width: auto;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
    .bwlogo{
        img{
            @media (max-width:992px){
                width: 120px;
                margin: auto;
            }
        }
    }
    .hero-main {
        // gap: 50px 0;

        &-1 {
            gap: 50px 0;
            @media screen and (max-width: 700px){
                gap: 20px 0;
            }
        }
    }

    .search-box {
        font-size:16px;
        font-weight:400;
        color:#657489;
        line-height:24px;
        padding: 17px 170px 17px 30px;
        background-color: #fff;
        border-radius: 100px;
        border: 2px solid #BCBCBC;
        height: 80px;
        width: 550px;
        @media (max-width: 767px){
            padding: 10px 10px;
            font-size: 14px;
            width: 100%;
            height: 60px;
        }

        ::placeholder {
            color: #BCBCBC;
        }

        &:focus {
            border: 2px solid #43A66AA7;
            box-shadow: 0 0 0 0;
        }
    }
    .city-selector{
        position: absolute;
        left: 0;
        font-size:16px;
        font-weight:400;
        color:#657489;
        line-height:28px;
        padding: 20px 0px 18px 18px;
        background-color: #BECBDD26;
        border-radius: 8px 0 0 8px;
        border: 2px solid #43A66AA7;
        @media screen and (max-width: 700px){
            padding: 14px 0px 12px 10px;
            font-size: 14px;
            border-radius: 0px;
        }
    }

    .filter-selection {
        background: #FFFFFF;
        // background: linear-gradient(271deg,#5d1a79 0,#082040 50%);
        color: #667486;
        padding: 25px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: #e1f7ea57;
        border: 1px solid #90cfa9;
        border-radius: 8px;
        margin-bottom: 20px;

        .filter-item {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: column;
            gap: 12px;

            .filter-label {
                font-size: 16px;
                color: #082040;
                // line-height: 38px;
            }

            .filter-checkboxes {
                display: flex;
                gap: 12px;
            }

            .filter-checkbox {
                padding: 5px 10px;
                border: 1px solid #667486;
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                justify-content: center;

                span {
                    font-size: 14px;
                    // line-height: 20px;
                }

                &.active {
                    background: #5d1a79;
                    background: linear-gradient(271deg, #5D1A79 0, #082040 50%);
                    color: #FFFFFF;
                }
            }

            .filter-checkbox:hover {
                background: #5d1a79;
                background: linear-gradient(271deg, #5D1A79 0, #082040 50%);
                color: #FFFFFF;
            }
        }

        :last-child {
            border-bottom: none;
            padding-bottom: 0px;
        }
    }

    
    .search-wrap{
        z-index: 12;
        width: 485px;
        max-height: 496px;
        overflow-y: auto;
        overflow-x: hidden;
        top: 71px;
        left: 33px;
        @media screen and (max-width:600px){
            width: calc(100% - 50px);
            left: 25px;
            top:52px;
        }
    }

    .location-btn {
        position: absolute;
        right: 155px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        background-color: #E7EBEE;
        height: 30px;
        width: 30px;
        display: flex;
        border-radius: 50% !important;
        align-items: center;
        justify-content: center;
        // @include tooltip();
    }

    .filter-btn {
        position: absolute;
        right: 196px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 20;
        // background-color: #e7ebee;
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        border-radius: 50% !important;
        align-items: center;
        justify-content: center;
    }

    .hero-desc {
        p {
            font-size: 24px;
            font-weight: 400;   
            color: #082040;
            line-height: 19px;
            letter-spacing: 0.48px;
        }

        img {
            display: inline-block;
        }
    }
}
.ceosection{
    padding-top: 65px!important;
    @media (max-width:992px){
        padding-top: 40px!important;
    }
}
.prcolleftpad{
    padding-left: 30px;
}
.home-features{
    padding-top: 100px!important;
    .sec-title{
        margin-bottom: 0px;
        @media (max-width:992px){
            margin-left: 0px;
        }
    }
    .hometitle{
        margin-left: 20px;
        @media (max-width:992px){
            margin-left: 0px;
        }
    }
    .project-container{
        background: #F1F1F1;
        border-radius: 48px;
        padding: 30px 15px;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
        @media (max-width:767px){
            padding: 30px 0px;
            padding-bottom: 0px;
        }
    }
        // .slick-prev{
        //     display: none!important;
        // }
}
.input-group-append {
    height: auto;
    @media (max-width: 767px){
        width: auto;
    }

    #home-search-btn {
        background-color: #43a66a;
        color: $white;
        border: unset;
        position: absolute;
        right: 15px;
        top: 15px;
        height: 52px;
        width: 151px;
        border-radius: 100px;
        font-size: 22px;
        line-height: 40px;
        @media (max-width: 767px){
            font-size: 16px!important;
            line-height: 20px!important;
            width: 85px!important;
            height: 40px!important;
            padding: 10px!important;
            margin-left: 1px!important;
            top: 8px!important;
            right: 10px!important;
        }
    }
}
.main-header-search-btn #home-search-btn {
    background-color: #43a66a;
    color: #ffffff;
    border: unset;
    position: absolute;
    right: -1px;
    top: 0;
    height: 50px;
    width: 143px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 38px;
    font-weight: 600;
}

.price-range-slider {
    width: 90%;
    // float:left;
    padding: 10px 0px;

    .range-value {
        margin: 0;

        input {
            width: 100%;
            background: none;
            color: #000;
            font-size: 16px;
            font-weight: initial;
            box-shadow: none;
            border: none;
            margin: 20px 0 20px 0;
        }
    }

    .range-bar {
        border: none;
        background: #d7d4d4;
        height: 6px;
        width: 100%;
        margin-left: 8px;

        .ui-slider-range {
            background: #43A66A;
        }

        .ui-slider-handle {
            border: none;
            border-radius: 25px;
            background: #43A66A;
            opacity: 1;
            // border:2px solid #06b9c0;
            height: 22px;
            width: 22px;
            top: -0.52em;
            cursor: pointer;
        }

        // .ui-slider-handle + span {
        //  background:#43A66A;
        //   opacity: 22%;
        // }
    }
}

.close {
    cursor: pointer;
    z-index: 12;
}

.search-input:focus {
    box-shadow: unset !important;
}
.image-side .carousel-indicators{
    display: none;
}
.overlay {
    position: fixed;
    top: 0px;
    /* same height as header */
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    display: none;
    &.irfsoverlay{
        .btn{
            &.sc{
                background-color: #FFB03D !important;
            }
        }
        .editphone{
            color: #FFB03D!important;
        }
        .steps-anim {
            span{
                &.active{
                    background: #FFB03D;
                    &::after{
                        background: linear-gradient(90deg, #FFB03D 0%, #ECECEC 80%);
                    }
                    &::before{
                        background: #FFB03D;
                    }
                }
            }
            .text-primary{
                color: #FFB03D!important;
            }
        }
        .resend-otp{
            button{
                color: #FFB03D!important;
            }
        }
    }
    /* initially hidden */
}
#overlayEnquiry{
    .overlay-content {
        width: 400px;
        margin: 0 auto;
        @media  screen and (max-width:767px) {
            width: 95%;
        }
        .priceTitle{
            @media ($max640) {
                font-size: 20px !important;
            }
        }
    }    
}
.overlay-content-container {
    width: 80%;
    margin: 0 auto;
}

.overlay-content, .profileListing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    max-width: max-content;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    @media (max-width:1366px) and (min-width:1025px){
        transform: translate(-50%, -50%) scale(0.85);
    }
    @media (max-width:767px){
        max-width: 100%;
        display: block;
    }
    .loginpopupcontent{
        // padding: 50px;
        width: auto;
        border-radius: 30px;
        height: auto;
        align-items: center;
        min-height: 450px;
        position: relative;
        @media(max-width:992px){
            // padding: 20px!important;
            width: auto !important;
            max-width: 100%!important;
            border-radius: 30px!important;
            // margin-top: 30px;
            margin-left: 0px!important;
        }
        .nextbtn{
            @media(max-width:992px){
                margin-top: 35px!important;
            }
        }
    }
    .log-content{
        text-align: center;
        p{
            color: #8D8D8D;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .log-title{
        font-size: 40px;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
        @media (max-width:767px){
            font-size: 34px;
            line-height: 38px;
            margin-bottom: 6px!important;
        }
    }
    .log-desc{
        color: #888484;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    .log-subtitle{
        font-size: 20px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 4px;
    }
    .txtcenter{
        text-align: center;
    }
    .resend-otp{
        text-align: center;
        button{
            color: #43A66A;
        }
    }
    .inputfield{
        input{
            background-color: #F7F7F7;
        }
        .form-floating > label{
            color: #BFBFBF;
        }
    }
}
.nextbtn{
    margin: 0 auto;
    margin-top: 35px;
    button{
        display: block;
        margin: auto;
    }
}
.enter-otp{
    text-align: center;
    font-size: 40px;
font-weight: 600;
}
.otpbtn{
    margin: auto;
    padding-bottom: 25px;
}
.loginfirst, .loginsecond, .thank-you-step, .loginthird {
    width: 520px;
    padding: 0px 20px 20px;
    @media (max-width:767px){
        width: 100%;
        padding: 0px 10px 20px;
    }
}
.loginthank{
    padding: 40px;
}
.loginthird1{
    padding: 0px 20px;
    @media (max-width:767px){
        padding: 0px 10px 20px;
    }
}
.download-btn{
    .btn{
        margin: auto;
        display: block;
    }
}
.steps-anim {
    display: flex;
    justify-content: space-around;
    width: 429px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    @media (max-width:992px){
        width: 300px;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 30px;
    }
    span{
        width: 39px;
        height: 38px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ECECEC;
        position: relative;
        border: 2px solid #ECECEC;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        background: transparent;
        transition: background 0.3s, color 0.3s, border 0.3s;
        transition-delay: 1.5s, 1.5s, 1.5s;
        &::after{
            content: '';
            position: absolute;
            width: 106px;
            height: 3px;
            background: #ECECEC;
            top: 50%;
            right: -106px;
            transform: translateY(-50%);
            @media (max-width:992px){
                width: 64px;
                right: -63px;
            }
        }
        &.active{
            background: #42B57E;
            border: 0px solid transparent;
            color: #fff;
            &::before{
                content: '';
                position: absolute;
                width: 106px;
                height: 3px;
                background: #42B57E;
                top: 50%;
                left: -106px;
                transform: translateY(-50%);
                width: 0px;
                animation: beforewidth 2s forwards;
                @keyframes beforewidth {
                    from {width: 0px;}
                    to {width: 106px;}
                }
                @media (max-width:992px){
                    left: -62px;
                    @keyframes beforewidth {
                        from {width: 0px;}
                        to {width: 65px;}
                    }
                }
            }
            &::after{
                content: '';
                background: linear-gradient(90deg, #42B57E 0%, #ECECEC 80%);
            }
        }
        &.first{
            &::before{
                content: unset;
            }
        }
        &.third{
            &::after{
                content: unset;
            }
        }
    }
    .fourth{
        display: none;
    }
}

.form-check-input:checked {
    background-color: #082040;
    border-color: #082040;
    cursor: pointer;
}
header{
    .search-input{
        padding: 0.8rem 1rem 0.8rem;
    }
    .btn.sc.btn-login{
        padding: 8px 14px;
        @media screen and (max-width: 767px){
            padding: 6px;
            height: 36px;
            margin: 0px 0;
        }
    }
    .btn.sc span{
        @media screen and (max-width: 767px){
            gap: 5px;
        }
    }
    .btn.sc{
        height: 40px;
    }
}
@media (max-width:600px) {
    .overlay {
        top: 0px;
        /* same height as header */
    }

    .overlay-content {
        width: 95%;
        height: auto;
        transform: translate(-50%, -50%);
        overflow-x: hidden;
        top: 50%;
        border-radius: 20px;
        background-color: #fff;
    }
}

.tooltip {
    position: absolute;
    top: -30px;
    left: 0;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    min-width: 60px;
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.filter-budget-slider {
    position: relative;
}

.filter-budget-slider input {
    width: 100%;
}

.filter-budget-slider .tooltip {
    visibility: hidden;
}

.filter-budget-slider:hover .tooltip {
    visibility: visible;
}

.filter-budget-slider input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #43A66A;
    height: 10px;
    margin: 0px 0;
    outline: none;
    cursor: pointer;
    width: 100%;
    margin: 10px 0;
}

.filter-budget-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #43A66A;
    opacity: 22%;
    border: 1px solid #43A66A;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.filter-budget-slider input[type="range"]::-moz-range-thumb {
    background-color: #fff;
    border: 1px solid #43A66A;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.filter-budget-slider .budget-markers {
    display: flex;
    justify-content: space-between;
    // margin-top: -20px;
}

.filter-budget-slider .budget-markers span {
    font-size: 12px;
}

.auto-suggestion-main {
    background-color: #fff;
    z-index: 99999;
    box-shadow: 0 13px 16px 0 rgba(0, 106, 194, 0.2);
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;
    border: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    ul {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: block;
        text-align: left;
        vertical-align: middle;
        max-height: 205px;
        overflow: auto;

        li {
            padding: 12px 20px;
            font-size: 14px;
            cursor: pointer;
            border-bottom: 1px solid #EBECF0;

            &:hover {
                background-color: #EBECF0;
            }
        }
    }



    @media (max-width:1024px) {
        display: none;
    }
}

.auto-suggestion-main ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
    border-radius: 10px;
    background-color: #f5f5f500;
}

.auto-suggestion-main ul::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f500;
}

.auto-suggestion-main ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #e6e6e6a4;
}

.tnc-error-login {
    cursor: default;
}

input#flexCheckChecked {
    cursor: pointer;
}
.tooltip{
    background-color: #43a66a;
    .tooltip-inner{
        background-color: #43a66a;
    }
    &:after{
        display: none;
    }
    .tooltip-arrow::before{
        border-right-color: #43a66a;
    }
}

/* New Home page Design CSS */
.hero-heading{
    .bwlogo{
        img{
            width: 100px;
        }
    }
    h1{
      font-size: 77px;
      font-weight: 700;
      color: #fff;
      line-height: 77px;
      margin: 15px 0px;
      @media screen and (max-width: 992px){
        font-size: 33px;
        font-weight: 600;
        line-height: 38px;
        }
    }
}
.map-btn{
    margin-top: -50px;
    display: block;
}
.sec-title{
    font-size: rem(64px);
    line-height: rem(64px);
    letter-spacing: .48px;
    @media (min-width:993px) and (max-width:1030px){
        font-size: rem(40px);
        line-height: rem(50px);
    }
    @media screen and (max-width: 992px){
        font-size: rem(38px);
        line-height: rem(50px);
    }
    @media screen and (max-width: 767px){
        font-size: rem(28px);
        line-height: rem(32px);
        font-weight: 700;
        // line-height: 30px;
    }
}
.locality-list-wrap{
    li{
        a{
            font-size: 16px;
            font-weight: 400;
            color: #657489;
            line-height: 24px;
            height: 43px;
            padding: 10px 15px;
            display: block;
            border: 1px solid #808080;
            border-radius: 100px;
            margin: 0 7px;
            cursor: pointer;
            text-decoration: none;
            font-weight: 500;
            &:hover{
                background-color: #f5f5f5;
            }
            &.active{
                background-color: #42B57E;
                color: #fff;
                border: 1px solid #42B57E;
            }
        }
    }
}
.looking-slider-wrap, .locality-list-wrap, .project-location-wrap{
    .slick-prev{
        @media screen and (max-width: 767px){
            // left: calc(00px)!important;
            z-index: 9999;
            width: 35px;
            height: 35px;
            // bottom: 25px;
            // top: unset;
            position: absolute;
        }
    }
    .slick-next{
        @media screen and (max-width: 767px){
            // right: calc(00px)!important;
            z-index: 9999;
            width: 35px;
            height: 35px;
            // top: unset;
            // bottom: 25px;
            position: absolute;
        }
    }
}
.project-location-wrap{
    .slick-arrow{
        // bottom: unset;
        // top: 50%;
        bottom: 10px;
        top: unset;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        background: #192951;
        @media (max-width:992px){
            transform: unset;
            top: 50%;
        }
        &.slick-next{
            @media (max-width:992px){
                right: -17px;
                left: unset;
            }
        }
    }
}
.looking-slider-wrap{
    .slick-prev{
        @media screen and (max-width: 767px){
            left: calc(20px)!important;
            bottom: 0px;
        }
    }
    .slick-next{
        @media screen and (max-width: 767px){
            right: 20px!important;
            bottom: 0px;
        }
    }
}
.explore-project-title{
    background-color: #192951;
    border-radius: 20px;
    padding: 13px;
    h2{
        color: #fff;
        font-size: 23px;
        line-height: 36px;
    }
    .total-project{
        background-color: #42B57E;
        border-radius: 12px;
        padding: 15px 20px;
        p{
            padding-left: 15px;
            vertical-align: baseline;
            color: #fff;
            margin-bottom: 0px;
            span{
                font-size: 25px;
                line-height: 31px;
                letter-spacing: -2px;
                display: block;
            }
        }
    }
}
.project-location-wrap{
    background-color: #DFDEDE;
    border-radius: 28px;
    padding: 13px;
    height: 100%;
    .project-list-slider{
        .slick-arrow.slick-prev {
            // left: -17px;
            top: -12px;
            left: 50%;
            @media screen and (max-width:600px){
                top: 50%;
                left: -17px;
            }
        }
        @media(min-width:1200px){
            min-height: 630px;
            .slick-slider{
                min-height: 630px;
            }
        }
    }
    .project-list-card{
        margin: 5px 0;
        display: inline-flex;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        padding: 7px;
        height: 195px;
        @media screen and (max-width: 767px){
            width: 96%;
            margin: 0 auto;
            flex-direction: column;
            height: 400px;
            display: block;
        }
    }
    .project-list-img{
        img{
            border-radius: 20px;
            width: 184px;
            min-width: 184px;
            height: 100%;
            @media screen and (max-width: 767px){
                width: 100%;
                height: 200px;
            }
        }
    }
    .project-list-details{
        background-color: #fff;
        border-radius: 0 0 20px 20px;
        padding: 9px 13px;
        position: relative;
        // max-height: 80px;
        // height: 80px;
        .project-list-title{
            h5{
                font-size: 26px;
                line-height: 32px;
                font-weight: 600;
                color: #000000;
                width: auto;
                margin-bottom: 5px;
                margin-top: 0px;
                text-wrap: balance;
                @media (max-width:992px){
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            p{
                color: #949494;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 0px;
                // span{
                //     font-size: 14px;
                //     font-weight: 600;
                //     color: #192951;
                //     line-height: 18px;
                // }
                &.conf-desc{
                    margin-bottom: 7px;
                }
                &.rera-desc{
                    display: flex;
                    margin-bottom: 10px;
                    img{
                        margin-right: 5px;
                    }
                }
            }
        }
        .project-price{
            font-size: 36px;
            line-height: 38px;
            font-weight: 700;
            color: #192951;
            @media (min-width:993px) and (max-width:1030px){
                font-size: 26px;
            }
            @media (max-width:992px){
                font-size: 20px;
                line-height: 30px;
            }
            @media (max-width:767px){
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}
.explore-project-map{
    background-color: #F6F6F6;
    border-radius: 28px;
    padding: 0px 13px;
    .explore-project-btn{
        border-radius: 25px;
        background-color: #192951;
        height: 43px;
        color: #fff;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 14px;
    }
}
.slick-arrow{
    background-color: #fff;
    border-radius: 100%;
    z-index: 100;
    &:hover{
        background-color: #43a66a;
        path{
            fill: #fff;
        
        }
    }
    &.slick-prev {
        // display: none!important;
        left: 0;
        &.iconify{
            width: 35px;
            height: 35px;
        }
        @media screen and (max-width: 767px){
            display: block!important;
            left: 0px;
            z-index: 9;
            width: 35px;
            height: 35px;
        }
    }
    &.slick-next {
        right: 0px;
        z-index: 3;
        &.iconify{
            width: 35px;
            height: 35px;
            
        }
        @media screen and (max-width: 767px){
           right: 0;
        }
    }
}
.locality-list-wrap{
    .slick-arrow{
        &.slick-prev {
            left: -35px;
            @media (max-width:992px){
                left: -5px;
            }
        }
    }
}

.local-sec{
    padding: 100px 0px;
    @media (max-width:992px){
        padding: 60px 0px;
    }
}
.localrow{
background-color: #42B57E;
height: 312px;
border-radius: 40px;
@media (max-width:992px){
    height: 450px;
    margin: 0;
}
.pdltcol{
    padding-left: 60px;
    @media (max-width:992px){
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    }
    .local-project-btn{
        border-radius: 12px;
        background-color: #192951;
        height: 43px;
        color: #fff;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 14px;
        width: fit-content;
        margin-top: 20px;
        @media (max-width:992px){
            margin: auto;
            margin-top: 10px;
        }
    }
}
    .sec-title{
        color: #fff;
        font-size: rem(52px);
        line-height: rem(52px);
    }
    .floatimg{
        transform: translatey(-40px);
        animation: float 12s ease-in-out infinite;
        position: absolute;
        top: 0;
        @media (max-width:992px){
            width: 320px;
            left: 50%;
            transform: translate(-50%, -40px);
        }
        @media(max-width:768px){
            left: unset;
        }
    }
    @media (max-width:992px){
        @keyframes float{
            0%{
                transform: translate(-50%, -40px);
            }
            50%{
                transform: translate(-50%, -60px);
            }
            100%{
                transform: translate(-50%, -40px);
            }
        }
    }
    @keyframes float{
        0%{
            transform: translateY(-40px);
        }
        50%{
            transform: translateY(-60px);
        }
        100%{
            transform: translateY(-40px);
        }
    }
}
.project-local{
    background: #EBEBEB;
    border-radius: 40px;
    padding: 40px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
    @media (min-width:993px) and (max-width:1030px){
        padding: 40px 20px;
    }
    @media (max-width:992px){
        padding: 25px 0px;
    }
    @media(max-width:767px){
        margin: 0;
    }
    .localitybg{
        height: 100%;
        img{
            height: 100%;
        }
    }
    .prcolleftpad{
        @media (max-width:992px){
            padding-right: calc(var(--bs-gutter-x)* 0.5);
            padding-left: calc(var(--bs-gutter-x)* 0.5);
        }
    }
    .search-component{
        position: relative;
        @media (max-width:767px){
            padding-inline: 10px;
        }
        .select2-container{
            width: 100%!important;
        }
        .select2-selection{
            width: 100% !important;
            padding: 17px 60px 17px 30px !important;
            
            @media (max-width:992px){
                // width: 90%;
                // padding: 17px 30px 17px 30px;
                // margin-inline: auto;
                margin-inline: 0px;
                
            }
            @media (max-width:767px){
                padding: 17px ;
            }
            .select2-search__field{
                @media (max-width:767px){
                    margin-top: 5px!important;
                }
            }
        }
    }
    .select2-dropdown{
        max-width: 320px;
        left: 2rem;
    }
    .locality-wrapper{
        position: relative;
        &:after{
            content: "";
            width: 120px;
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0px;
            background: linear-gradient(270deg, #EBEBEB 40.4%, rgba(235, 235, 235, 0.8) 60.4%, rgba(235, 235, 235, 0) 100%);
            @media(max-width:992px){
                display: none;
            }
        }
        .slick-arrow{
            path{
                fill: #000;
            }
            &:hover{
                path{
                    fill: #fff;
                }
            }
        }
        .locality-card-wrapper{
            padding: 10px;
            .locality-card{
                background-color: #fff;
                border-radius: 40px;
                max-width: 100%;
                padding: 15px;
                position: relative;
                .location-price-img{
                    width: 245px;
                    height: 210px;
                    object-fit: cover ;
                    border-radius: 30px;
                    @media(max-width:992px){
                        width:100%;
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                }
                .locality{
                    a{
                        font-size: 28px;
                        line-height: 34px;
                        color: #192951;
                        font-weight: 600;
                        text-decoration: none;
                        @media (max-width:992px) {
                            font-size: 24px;
                            line-height: 34px;
                        }
                    }
                }
                .loc-desc{
                    font-weight:500;
                    font-size: 12px;
                    line-height: 18px;
                    color:#949494;
                    padding-right: 2rem;
                }
                .loc-price{
                    font-size: rem(28px);
                    line-height: rem(46px);
                    font-weight: 700;
                    color: #192951;
                    @media (max-width:992px) {
                        font-size:  rem(22px);
                        line-height: rem(40px);
                    }
    
                    .view-btn{
                        display: inline-block;
                        float: right;
                        margin-top: 0px;
                        max-width: 50px;
                        @media (max-width:992px) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
                
            }
        }
        // .slick-slide {
        //     padding: 10px;
        //     .locality-card:nth-child(1){
        //         margin-bottom: 10px;
        //     }
        // }
    }
}

.localitybg{
    .localbgbox {
        display: flex;
        align-items: center;
        padding: 20px;
        background: #fff;
        border-radius: 20px;
        position: absolute;
        bottom: 20px;
        width: 94%;
        left: 50%;
        transform: translateX(-50%);
        padding-right: 60px;
        .localityhead{
            font-size: 38px;
            line-height: 38px;
            font-weight: 600;
        }
        .boxarrow{
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
}

.project-list-slider{
    &:after{
        background: linear-gradient(270deg, #F1F1F1 40.4%, rgba(241, 241, 241, 0.80) 60.4%, rgba(241, 241, 241, 0.00) 100%);
        content: "";
        position: absolute;
        width: 284px;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 1;
        @media screen and (max-width: 767px){
            display: none;
        }
    }
}
.top-projects-slider{
    &:after{
        background: linear-gradient(270deg, #F1F1F1 40.4%, rgba(241, 241, 241, 0.80) 60.4%, rgba(241, 241, 241, 0.00) 100%);
        content: "";
        position: absolute;
        width: 111px;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 1;
        @media ($max1024) {
            right: -2px;
        }
        @media screen and (max-width: 767px){
            display: none;
        }
    }
    &.white-gradient{
        &:after{
            background: linear-gradient(270deg, #ffffff 40.4%, rgb(255 255 255 / 80%) 60.4%, rgba(241, 241, 241, 0) 100%);
        }
    }
}
.locality-list-wrap{
    &:after{
        background: linear-gradient(270deg, rgb(235, 235, 235) 40.4%, rgb(235 235 235 / 70%) 60.4%, rgb(235 235 235 / 0%) 100%);
        content: "";
        position: absolute;
        width: 104px;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 1;
        @media screen and (max-width: 767px){
            display: none;
        }
    }
    &::before{
        background: linear-gradient(270deg, rgb(235, 235, 235) 40.4%, rgb(235 235 235 / 70%) 60.4%, rgb(235 235 235 / 0%) 100%);
        content: "";
        position: absolute;
        width: 104px;
        height: 100%;
        top: 0;
        left: -35px;
        z-index: 1;
        transform: rotate(180deg);
        @media screen and (max-width: 767px){
            display: none;
        }
    }
}
.project-list-slider{
    &:after{
        width: 100%;
        height: 150px;
        background: rgb(255,255,255);
        bottom: 0;
        top: unset;
        background: linear-gradient(0deg, rgb(223, 222, 222) 10%, rgba(7, 7, 7, 0) 100%);
    }
    .bluearrow {
        position: absolute;
        bottom: 20px;
        right: 20px;
        img{
            width: 50px;
        }
    }
}

.contentbox{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 15px;
    @media (max-width:992px){
        grid-template-columns: 1fr;
    }
    .box-wrapper{
        .content{
            padding-right: 50px;
            @media (max-width:992px){
                padding-right: 0px;
            }
        }
    }
    .box{
        border-radius: 34px;
        width: auto;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .hotsell{
            position: absolute;
            top: 0;
            right: 35px;
        }
        .imgbox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 70px;
            @media (max-width:992px){
                height: 100px;
            }
            .flowers{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 72%;
            }
            .sky1{
                position: absolute;
                bottom: 35px;
                left: 0;
            }
            .sky2{
                position: absolute;
                bottom: 0px;
                right: 30px;
            }
            .afford{
                position: absolute;
                bottom: 28px;
                left: 0;
            }
        }
        h2{
            font-size: 44px;
            line-height: 50px;
            color: #fff;
            font-weight: 600;
            @media (max-width:992px){
                font-size: 26px;
                line-height: 33px;
            }
        }
        p{
            color: #fff;
            margin-bottom: 20px;
        }
        &.box1{
            background: linear-gradient(90deg, #51AFA4 0%, #7ADFDF 100%);
        }
        &.box2{
            background: linear-gradient(180deg, #FBCACD 0%, #FE9EA0 34.44%, #F29499 64%, #E28890 100%);
        }
        &.box3{
            background: linear-gradient(180deg, #80CBE1 0%, #3FA8DC 100%);
        }
        &.box4{
            background: linear-gradient(0deg, #C9AA8E 0%, #C4977A 52%, #EECEB7 100%);
        }
        &.box5{
            background: linear-gradient(180deg, #44DCD1 0%, #019F94 100%);
        }
        &.box6{
            background: linear-gradient(0deg, #3286E7 0.23%, #98C7FE 99.85%);
        }
    }
    &.contentbox1{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 20px;
        @media (max-width:992px){
            grid-template-columns: 1fr;
            margin-top: 15px;
        }
    }
}

.ceocontainer{
    background-color: #42B57E;
    border-radius: 60px;
    @media (max-width:767px){
        margin: 0;
    }
    .ceobox{
        color: #fff;
        padding: 15px 80px 0px 40px;
        @media (max-width:992px){
            padding: 30px 20px 0px 20px;
        }
        .local-project-btn{
            border-radius: 12px;
            background-color: #192951;
            height: 43px;
            color: #fff;
            padding: 10px 15px;
            text-decoration: none;
            font-size: 14px;
            width: -moz-fit-content;
            width: fit-content;
            margin-top: 20px;
            @media (max-width:992px){
                margin: auto;
            }
        }
    }
    .ceoimgbox{
        position: relative;
        width: fit-content;
        @media (max-width:992px){
            text-align: center;
        }
        .ceocirc{
            height: 300px;
            width: 300px;
            border-radius: 100%;
            background: #5AEFA8;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 6%;
            left: 47%;
            transform: translateX(-50%);
            @media (max-width:992px){
                width: 250px;
                height: 250px;
            }
        }
        img{
            margin-top: -50px;
            width: 90%;
            z-index: 11;
            position: relative;
            @media (max-width:992px){
                margin-top: 0px;
                width: 80%;
            }
        }
    }
    .buildabstract{
        position: absolute;
        bottom: -26px;
        right: 0;
        width: 65%;
        z-index: 1;
        @media (max-width:992px){
            width: 100%;
            bottom: -12px;
        }
        img{
            width: 80%;
            @media (max-width:992px){
                width: 100%;
            }
        }
    }
}

.top-projects-slider{
    margin-top: 25px;
    margin-left: 12px;
    @media screen and (max-width: 767px){
        margin-left: 0px;
        margin-top: 5px;
    }
    .slick-slide{
        padding-left: 10px;
        margin: 20px 20px 0 0;
        @media screen and (max-width: 767px){
            padding: 0 10px;
            margin: 20px 0px;
        }
    }
    
    .slick-list{
        // height: 495px;
        @media ($max1440) and ($min640) {
            height: 495px;
        }
    }
    .top-projects-card{
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        overflow: hidden;
        padding: 10px;
        background-color: #fff;
        width: 390px!important;
        min-height: 465px;
        @media screen and (max-width: 767px){
            width: 100%!important;
            min-height: 450px;
        }
        .top-projects-img{
            img{
                height: 215px;
                width: 390px;
                border-radius: 30px;
                @media screen and (max-width: 767px){
                    width: 100%;
                    height: 200px;
                }
            }
            svg{
                background: #192951;
                border-radius: 100%;
                #Icon_ionic-ios-heart{
                    stroke: #fff;
                }
            }
        }
        .top-projects-details{
            padding: 0px 20px;
            box-sizing: border-box;
            // background-color: #fff;
            position: relative;
            width: 100%;
            // height: 228px;
            height: unset;
            padding-top: 20px;
            @media screen and (max-width:600px){
                width: 100%;
                height: unset;
                // overflow-y: scroll;
            }
            .project-price{
                color: #192951;
                font-family: Poppins;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
                // margin-top: 15px;
                display: block;
                @media (max-width:992px){
                    font-size: 24px;
                }
                @media (max-width:767px){
                    font-size: 20px;
                }
                @media (max-width:360px){
                    font-size: 18px;
                }
            }
            .devDataWrapper{
                height: 120px;
                display: block;
                overflow-y: scroll;
                overflow-x: hidden;
                &::-webkit-scrollbar {
                    width: 5px;
                    border-radius: 5px;
                  }
                &::-webkit-scrollbar-track {
                    // background: #f1f1f1; 
                    background: transparent; 
                    border-radius: 5px;
                  }
                &::-webkit-scrollbar-thumb {
                    // background: #f7c57b; 
                    background: #4db878;
                    border-radius: 5px;
                  }
                &::-webkit-scrollbar-thumb:hover {
                    // background: #FFB03D; 
                    background: #43A66A; 
                    border-radius: 5px;
                  }
                @media ($max640) {
                    height: 110px;
                }
            }
            .dev-name{
                font-size: 14px;
                color: #657489;
                line-height: 18px;
                margin-bottom: 10px;
                width: 300px;
                @media (max-width:992px){
                    width: auto;
                    text-wrap: balance;
                    margin-bottom: 7px;
                }
            }
            .prj-name{
                font-size: 24px;
                font-weight: 800;
                color: #000;
                line-height: 23px;
                margin-bottom: 15px;
                @media (max-width:370px){
                    font-size: 22px;
                }
            }
            .conf-desc{
                color: #949494;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10px;
                svg{
                    margin-right: 5px;
                }
            }
            .rera-desc{
                color: #949494;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                @media (max-width:992px){
                    margin-bottom: 0px;
                }
                img{
                    margin-right: 7px;
                }
            }
            .explore-btn{
                background-color: #192951;
                border-radius: 100px;
                color: #fff;
                font-size: 22px;
                text-decoration: none;
                display: inline-block;
                width: 35px;
                height: 35px;
                display: block;
                text-align: center;
                // line-height: 30px;
                // position: absolute;
                // bottom: 0px;
                // right: 0px;
                @media (max-width:992px){
                    right:-10px;
                    position: relative;
                }
            }
            .pricebox {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 10px;
                @media (max-width:767px){
                    margin-top: 15px;
                }
                .requesttitle{
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                    color: #192951;
                    cursor: pointer;
                }
            }
        }
    }
    .slick-arrow{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 100%;
    }
}
.looking-slider-wrap{
    .slick-slide{
        padding: 0px 10px;
    }
    .looking-card-inner{
        .looking-for-card{
            margin: 25px 0;
            cursor: pointer;
            h3{
                font-size: 18px;
                line-height: 20px;
                font-weight: 700;
                margin-top: 20px;
                margin-left: 20px;
                color: #202020;
                @media screen and (max-width: 767px){
                    margin-left: 0;
                }
            }
        }
    }
}
.looking-for-img{
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    &::after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        border-radius: 20px;
        transition: all 0.5s ease-in-out;
    }
    p{
        position: absolute;
        bottom: -100%;
        left: 0;
        color: #fff;
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 700;
        z-index: 2;
        text-align: center;
        transition: all 0.5s ease-in-out;
    }
    &:hover{
        &::after{
            top: 0;          
        }
        p{
            bottom: 0;
        }
    }
}
.blog-img{
    img{
        border-radius: 42px;
        height: 350px;
        @media  screen and (max-width: 767px){
            height: auto;
        }
    }
}
.blog-details{
    background-color: #F6F6F6;
    border-radius: 41px;
    padding: 30px 40px;
    height: 350px;
    @media  screen and (max-width: 767px){
        padding: 20px;
        height: auto;
        
    }
    .blog-title{
        font-size: 24px;
        line-height: 40px;
        font-weight: 400;
        color: #000;
        @media (max-width:1440px){
            font-size: 24px;
            line-height: 30px;
        }
        @media  screen and (max-width: 767px){
            font-size: 20px;
            line-height: 30px;
        }
    }
    .blog-desc{
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: #636466;
        letter-spacing: .32px;
    }
    .blog-link{
        background-color: #9AB7D3;
        border-radius: 25px;
        display: inline-block;
        padding: 10px 20px;
        color: #000;
        font-size: 14px;
        text-decoration: none;
    }
}
.search-component{
    .select2-container{
        .select2-selection{
            font-size: 16px;
            font-weight: 400;
            color: #657489;
            line-height: 24px;
            padding: 17px 60px 17px 90px;
            background-color: #fff;
            border-radius: 100px!important;
            border: none!important;
            height: 80px;
            width: 550px;
            text-align: left;
            transition: all 0.2s forwards;
            filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
            // @media ($max1024) {
            //     width: 440px;
            // }
            @media (max-width:992px){
                width: 97%;
                padding: 10px 10px 17px 80px;
                height: 65px;
                margin: 0px 12px;
                text-align: center;
                --bs-gutter-x: 0rem;
                margin: 0;
              }
              @media (max-width:767px){
                width: 93%;
              }
            li{
                margin-top: 0px!important;
                margin-bottom: 5px;
            }
        }
        &.select2-container--below{
            .select2-selection{
                &[aria-expanded="true"]{
                    border-radius: 30px 30px 0px 0px !important; 
                }
            }
        }
        &.select2-container--above{
            .select2-selection{
                &[aria-expanded="true"]{
                    border-radius: 0px 0px 30px 30px !important; 
                }
            }
        }
    }
    
    .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple{
        border-radius: 100px;
        border: 2px solid #43A66A;
    }
    .select2-container .select2-search--inline .select2-search__field{
        margin-top: 10px;
        margin-left: 1px;
        font-size: 16px;
        width: 75% !important;
        white-space: nowrap; /* Prevent line breaks */
        overflow-x: auto; /* Enable horizontal scrolling */
        overflow-y: hidden; /* Disable vertical scrolling */
        display: inline-block; /* Ensure it stays in one line */
        &::placeholder{
            font-size: 12px;
        }
        &::-webkit-scrollbar {
            width: 1px;
            height: 1px;
            border-radius: 1px;
          }
      
          &::-webkit-scrollbar-track {
            background: #ededed;
            border-radius: 1px;
          }
      
          &::-webkit-scrollbar-thumb {
            background: grey;
            border-radius: 1px;
          }
      
          &::-webkit-scrollbar-thumb:hover {
            background: grey;
            border-radius: 1px;
          }
        @media ($max1024) {
            width: 100%;
        }
        @media ($max992) {
            overflow-x: hidden;
            overflow-y: hidden;
            width: 100% !important;
        }
    }
    .select2-container {
        @media (max-width:992px){
            margin: auto;
        }
        textarea{
            height: 30px!important;
        }
    }
}
.select2-results__options{
    // max-height: 500px!important;
}
.select2-dropdown--below{
    // animation: scaleYIn 0.2s ease forwards !important; /* Adjust duration as needed */
    // opacity: 0;
}
.select2-container--default .select2-results > .select2-results__options{
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 5px;
      }
    &::-webkit-scrollbar-track {
        background: #ededed; 
        border-radius: 5px;
      }
    &::-webkit-scrollbar-thumb {
        background: #43A66A; 
        border-radius: 5px;
      }
    &::-webkit-scrollbar-thumb:hover {
        background: #2e744a; 
        border-radius: 5px;
      }
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
    background-color: #00c47b!important;
}
#irfsBannerSection .search-component .select2-selection{
    @media ($max1024) {
        width: 440px;
    }
}
@media screen and (min-width: 767px) {
.select2-dropdown{
    left: 0px!important;
    // width: 475px !important;
}
}

.view-btn {
    text-align: right;
    margin-top: -40px;
}
.blog-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
    gap: 20px;
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }
    .blog-title{
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.48px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    .blog-box{
        display: flex;
        flex-direction: column;
        border-radius: 19px;
        padding: 20px;
        justify-content: space-between;
        height: 428px;
        @media screen and (max-width: 767px) {
            height: auto;
        }
        .blog-link{
            background-color: #fff;
            border-radius: 20px;
            padding:10px 15px;
            text-decoration: none;
            margin-top: 20px;
            display: block;
            width: fit-content;
            font-weight: 500;
            &:hover{
                background-color: #192951;
                color: #fff;
            }
        }
        &:first-child{
            flex-direction: row;
            height: fit-content;
            background: linear-gradient(90deg, #71AEA7 0%, #AACDCD 100%);
            grid-column-start: 1;
            grid-column-end: 3;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                grid-column-end: 1;
            }
            .blog-link{
                background-color: #192951;
                color: #AACDCD;
            }
        }
        &:nth-child(2){
            background: linear-gradient(180deg, #70BED5 0%, #C9E1ED 100%);
            .blog-link{
                color: #70BED5;
            }
        }
        &:last-child{
            background: linear-gradient(180deg, #FBCACD 0%, #FE9EA0 34.44%, #F0DADC 100%);
            .blog-link{
                color: #fe9ea0;
            }
        }
        .blogicon{
            width: 100%;
            height: 165px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 160px;
            }
        }
    }
}
.blog-grid2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
    gap: 20px;
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }
    .blog-title{
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.48px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    .blog-box{
        display: flex;
        flex-direction: column;
        border-radius: 19px;
        padding: 20px;
        justify-content: space-between;
        height: 428px;
        @media screen and (max-width: 767px) {
            height: auto;
        }
        .blog-link{
            background-color: #fff;
            border-radius: 20px;
            padding:10px 15px;
            text-decoration: none;
            margin-top: 20px;
            display: block;
            width: fit-content;
            font-weight: 500;
            &:hover{
                background-color: #192951;
                color: #fff;
            }
        }
        &:first-child{
            background: #172532;
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
            .blog-link{
                background-color: #fff;
                color: #172532;
            }
        }
        &:nth-child(2){
            background: linear-gradient(180deg, #C9AA8E 0%, #C4977A 52%, #EECEB7 100%);
            .blog-link{
                color: #C4977A;
            }
        }
        &:last-child{
            flex-direction: row;
            height: fit-content;
            background: linear-gradient(90deg, #88B7DA 0.23%, #B7E4F6 99.85%);
            grid-column-start: 1;
            grid-column-end: 3;
            display: grid;
            grid-template-columns: auto auto;
            @media screen and (max-width: 767px) {
                grid-column-end: 1;
                grid-template-columns: auto;
            }
            .blog-link{
                background-color: #192951;
                color: #B7E4F6;
            }
        }
        .blogicon{
            width: 100%;
            height: 165px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 160px;
            }
        }
    }
}

.testimoni-slider {
    padding: 70px 0px;
    padding-top: 30px;
    .slick-prev{
        color: rgb(0, 0, 0);
        display: block;
        position: absolute;
        top: 50%;
        left: 185px;
    }
    .slick-next{
        color: rgb(0, 0, 0);
        display: block;
        position: absolute;
        top: 50%;
        right: 185px;
    }
    .slick-slide{
        transform: scale(0.8);
        transition: all 0.6s linear;
    }
    .slick-current{
        transform: scale(1);
    }
}

.hero-searchmain{
    width: 550px;
    @media (max-width:992px){
        width: 100%;
    }
    .search-component{
        position: relative;
        z-index: 2;
        &:has(.searchPopup.open) {
            z-index: 999999; // Adjust z-index when the child has the class
        }
    }
}
input[type=tel]::-webkit-outer-spin-button,
input[type=tel]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=tel],
input[type=number]{
    -moz-appearance: textfield;
}


.compare-sec{
    @media (min-width:993px){
        padding-top: 110px!important;
    }
    @media (max-width:992px){
        padding: 0 10px;
        padding-top: 50px;
    }
}
.comparesec {
    border-radius: 50px;
    background: #42B57E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 60px;
    @media (max-width:992px){
        flex-direction: column-reverse;
        padding: 20px 0px 0px;
    }
    .compareimg{
        margin-top: -110px;
        margin-left: -75px;
        @media (max-width:992px){
            margin-top: 0px;
            margin-left: 0px;
        }
    }
    .compare-head {
        color: #FFF;
        text-align: right;
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (min-width:993px) and (max-width:1030px){
            font-size: 36px;
        }
        @media (max-width:992px){
            text-align: center;
            font-size: 2.25rem;
            line-height: 3.25rem;
        }
    }
    .local-project-btn{
        border-radius: 12px;
        background-color: #192951;
        height: 43px;
        color: #fff;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 14px;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        margin-right: 0;
        margin-top: 20px;
        @media (max-width:992px){
            margin: auto;
        }
    }
}
.map-main-wrapper{
    @media(max-width:992px){
       padding-bottom: 0px!important;
    }
    &.plp-page{
        @media (max-width:992px) {
            min-height: unset;
        }
    }
}

.mam-project-listing-wrapper{
    .irfslist-class{
        @media(max-width:992px){
            margin-bottom: 0px;
            .irfs-exl{
                top: 20px;
            }
        }
    }
    .top-projects-slider{ 
        &:after{
            display: none!important;
        }
        @media(max-width:992px){
            margin-inline: auto!important;
            padding-bottom: 20px;
        }
        .top-projects-card{
            width: 100% !important;
            .top-projects-img{
                border-radius: 30px;
                overflow: hidden;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.14);
                margin-bottom: 10px;
                svg{
                    background-color: #fff;
                }
                img{
                    width: 100%;
                    object-fit: cover;
                    height: 290px;
                    @media (max-width:992px){
                        height: 240px;
                    }
                }
            }
            .top-projects-details{
                padding-top: 10px;
                @media (max-width:992px){
                    height: auto;
                    
                }
            }
        }
        .rera-main-div {
            // background: rgb(67, 166, 106);
            // background: linear-gradient(45deg, rgba(67, 166, 106, 1) 0%, rgba(67, 166, 106, 0.8491771708683473) 52%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            background-color: #43A66A;
            border-radius: 0px 20px 0px 0px;
            justify-content: flex-end;
            @media (max-width:992px) {
                border-radius: 0px 14px 0px 0px;
                display: flex;
                justify-content: flex-end;
            }
            .list-rerabox{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                background-color: #fff;
                height: 100%;
                border-radius: 20px 0px 0px 0px;
                padding: 10px 0px 10px 7px;
                align-items: center;
                width: 60%;
                @media (max-width:992px) {
                    border-radius: 14px 0px 0px 0px;
                    padding: 5px 0px 5px 6px;
                    width: 58%;
                }
                @media (max-width:400px) {
                    width: 56%;
                }
            }
            .rera-txt {
                font-weight: 600;
                font-size: 10px;
                margin-bottom: 0px!important;
                color: #192951;
                @media (max-width:992px) {
                    font-size: 9px;
                }
            }
    
            .pos-date{
                background: #43A66A;
                padding: 9px;
                border-radius: 20px 0px 0px 0px;
                color: #fff;
                left: 5px;
                position: absolute;
                font-size: 9px;
                @media (max-width:992px) {
                    border-radius: 14px 0px 0px 0px;
                    padding: 9px 0px 9px 7px;
                    left: 5px;
                    position: absolute;
                }
            }
            .top-picks{
                span{
                    font-size: 9px;
                }
            }
            p {
                font-size: 12px;
                color: #fff;
                margin-bottom: 0px!important;
                @media (max-width:992px) {
                    font-size: 9px;
                }
                span {
                    font-weight: 600;
                }
            }
        }
    }

  
    
    @keyframes slideIn {
        from {
            transform: translateX(41.66666667%);
        }
        to {
            transform: translateX(0);
        }
    }
      
      @keyframes slideOut {
        from {
            
            transform: translateX(-58.33333333%);
        }
        to { 
            transform: translateX(0);
         
        }
      }
      
      .map-container {
        position: relative;
       
        @media (min-width:1025px) {
            transition: transform 0.5s ease-in-out;
            &.map-container-expanded {
              animation: slideIn 0.5s forwards;
              transform: translateX(0);
            }
        }
        
        // &.map-container-collapsed {
        //   animation: slideOut 0.5s forwards;
        //   transform: translateX(100%);
        // }
        .mapsliderbtn{
            background-color: #fff;
            border-bottom: 1px solid #ccc;
            display: block;
            text-align: center;
            text-decoration: none;
            color: black;
            top: 20px;
            left: 30px;
        }

        @media (max-width:1024px) {
            height: 40vh !important;
            max-height: 40vh !important;
            position: fixed !important;
            right: 0 !important;
            top: 68px !important;
            padding: 0px;
            border-radius: 0px;

            .leaflet-container{
                height: 40vh !important;
                max-height: 40vh !important;
                overflow: hidden;
                padding: 0 !important;
                position: fixed !important;
                top: 65px !important;
                width: 100%;
                z-index: 1;
                border-radius: 0px!important;
            }

            .mapsliderbtn{
                display: none!important;
            }
            
            
        }
      }
    
    .list-container{
        position: relative;
        @media (min-width:1025px) {
            transition: transform 0.5s ease-in-out;
            &.list-container-collapsed {
                animation: slideOut 0.5s forwards;
                transform: translateX(0);
            }
        }
        .top-projects-slider{
        margin-left: 0px!important;
        }
        @media (max-width:1024px) {
            background-color: #fff;
            border-radius: 60px 60px 0 0;
            margin-top: 35vh;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            padding-top: 30px;
            position: relative;
            z-index: 3;
            padding-top: 30px!important;
            position: relative;
            box-shadow: 1px 3px 20px 0px #00000073;
            &::after{
                background-color: #20202033 !important;
                border-radius: 4px !important;
                content: "" !important;
                display: block !important;
                height: 4px !important;
                left: 50% !important;
                position: absolute !important;
                top: 15px !important;
                transform: translateX(-20px) !important;
                width: 40px !important;
            }
            .project-list {
                height: auto!important;
            }
        }
        
    }
}

.mam-project-listing-wrapper {
    margin-top: 90px;
    margin-bottom: 0px;
    @media (min-width:1025px) {
        .leaflet-control-zoom {
            bottom: 10px;
            right: 10px;
            position: absolute !important;
        }
    }
    @media (max-width:1024px) {
    margin-bottom: 0px!important;
    margin-top: 60px;
    }
}

//Loader
.universal-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9); // Semi-transparent overlay
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999; // Ensure it overlays everything
  }
  
  .universal-loader {
    // font-size: 1.5rem;
    // width: 90px;
    color: #fff;
    // background-color: rgba(0, 0, 0, 0.7); // Slight background for better contrast
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    svg{
        width: 80px;
        overflow: visible;
    }
    .custom-loader{
        width: 60px;
        height: 60px;
    }
  }
  
