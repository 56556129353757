// table
.ag-root-wrapper {
  border-radius: 45px !important;
  box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 25px;
  .ag-root {
    .ag-header {
      background: #f8f8f8;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.07);

      padding-inline: 30px;
      .ag-header-cell-label {
        color: #7c7b7b;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.8px;
      }

      .ag-header-viewport {
        .ag-header-cell-resize {
          &::after {
            display: none;
          }
        }
      }

      @media(max-width: 992px) {
        padding-inline: 10px;
        .ag-header-cell-label {
          font-size: 12px;
        }
      }
    }
    .ag-body {
      .ag-body-viewport {
        padding: 00px 30px 30px 30px;
        margin-top: 10px;
        .ag-row {
          border-bottom-color: #d9d9d9;

          .ag-cell {
            color: #979797;
            font-size: 14px;
            display: flex;
            align-items: center;
            &.name-column {
              font-size: 16px;
              color: #000;
            }
            .btn-download {
              display: flex;
              width: 137px;
              height: 40px;
              padding: 12px 12px 12px 19px;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              border-radius: 12px;
              background: #192951;
              color: #f8f8f8;
              border: 0px;
              span {
                margin-left: 10px;
              }
            }
          }
          //   height: 80px!important;
          //   transform: translateY(80px)!important;
        }

        @media (max-width: 992px) {
          padding: 0px 10px 30px 10px;
          .ag-row {
            .ag-cell {
              font-size: 12px;
              &.name-column {
                font-size: 14px;
              }
              .btn-download {
                width: 50px;
                height: 30px;
                padding: 8px 8px 8px 12px;
                span {
                 display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  &.ag-layout-normal{
    width: 100%!important;
  }
}

.dev-project-wrapper {
  .dev-project-list {
    padding-right: 50px;
    position: relative;
    &:after{
      content: '';
      display: block;
      clear: both;
      position: absolute;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 150px;
      // transform: rotate(90deg);
      background: linear-gradient(275deg, #F4F5FA 43.75%, rgba(255, 255, 255, 0.00) 100%);
    }
    ul {
      li {
        .project-button {
          border-radius: 22.5px;
          border: 1px solid #192951;
          
          height: 45px;
          color: #192951;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
          margin-right: 10px;
          padding-inline: 20px;
          &.active {
            background: #FFB03D;
            color: #ffffff;
            border-color: #FFB03D;
          }
          @media (max-width: 992px) {
           width:95%;
           font-size: 12px;
           display: flex;
           align-items: center;
            justify-content: center;
            line-height: 14px;
          }
        }
      }
    }
    .slick-slider {
      .slick-prev{
        display: none!important;
      }
      .slick-next{
        right:-40px
      }
    }
  }
}

.dev-header-search-wrap{
  float: right;
  @media (max-width: 992px) {
    width: 100%;
    float: none;
  }
  #filter-text-box{
    position: relative;
    background-image: url(../img/search-img-1.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right 10px center;
    width: 290px;
    height: 40px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding-right: 25px;
    padding-left: 10px;
    border: 0px;
    @media (max-width: 992px) {
      width: 100%;
      margin-top: 10px;
    }
  }
}
