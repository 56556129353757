.irfslist-class{
    margin-bottom: 27px;
    .shortlisted svg #Icon_ionic-ios-heart{
        fill: #F8990C;
    }
    .shortlisted svg #Ellipse_112 circle{
        fill: #F8990C;
    }
    .compare-btn{
        display: none;
    }
    .filter-sidebar-sort{
        background-color: #F8990C;
        @media (max-width:767px){
            bottom: unset;
            left: unset;
            transform: unset;
            line-height: normal;
            // width: 100%;
            box-shadow: none;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px 0px 0px 6px;
            color: #192951;
        }
    }
    .filter-sidebar-btn{
        background-color: #F8990C;
        @media (max-width:767px){
            bottom: unset;
            left: unset;
            transform:unset;
            line-height: normal;
            // width: unset;
            box-shadow: none;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 6px 6px 0px;
            color: #192951;
        }
        &::after{
            @media (max-width:767px){
                content: '';
                position: absolute;
                background-color: #F8990C;
            }
        }
    }
    .filter-apply-btn{
        background-color: #F8990C!important;
        border-color: #F8990C!important;
    }
}
.orange-btn{
    display: none;
}
.irfslist-class{
    .plp-card-detail{
        .plp-price-cards.parent{
            .card{
                .card-header{
                    background-color: #FED79A;
                }
            }
        }
    }
    .rightBtnsWrapper{
        @media (max-width: 767px){
            position: absolute;
            top: -285px!important;
            left: 15px;
            justify-content: space-between !important;
            width: 77%;
        }
    }
    .tooltip{
        background-color: #F8990C!important;
        .tooltip-inner{
            background-color: #F8990C!important;
        }
        &:after{
            display: none;
        }
        .tooltip-arrow::before{
            border-right-color: #F8990C!important;
        }
    }
    .plp-page{
        .plp-card-main{
            @media (max-width:767px){
                // padding: 0px;
                // gap: 0!important;
            }
            .image-side{
                @media (max-width:767px){
                    // border-radius: 0;
                }
            }
        }
        .listpad{
            @media (max-width:767px){
                padding: 0px 10px 10px;
            }
        }
        .plp-card-detail{
            border-top:1px solid #082040!important;
            border-bottom:1px solid #082040!important;
            @media (max-width:767px){
                padding: 14px 10px 10px;
                border-top: none!important;
                border-bottom: none!important;
            }
            .plp-price-cards{
                &.parent{
                    @media (max-width:767px){
                        margin: auto;
                        margin-right: 0;
                        display: flex;
                    }
                    .card{
                        @media (max-width:767px){
                            margin: auto;
                            margin-right: 0;
                        }
                        .card-header{
                            background-color: #FED79A;
                            @media (max-width:767px){
                                display: none;
                            }
                        }
                        .card-body{
                            .card-title{
                                @media (max-width:767px){
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .plp-carpet-area{
                @media (max-width:767px){
                    justify-content: flex-end;
                    gap: 4px;
                    font-size: 12px;
                    margin-bottom: 2px;
                }
            }
        }
    }
    .cupon-btn{
        background-color: #F8990C;
        border-radius: 8px;
        border: none;
        padding: 10px 15px;
        color: #fff;
        font-weight: 600;
        display: block;
        @media (max-width:767px){
            padding: 9px 10px;
            font-size: 12px;
            width: 90px;
            margin-left: 10px;
        }
    }
    .readmore{
        color: #F8990C!important;
    }
    .irfs-exl {
        display: inline-block;
        padding: 6px 12px;
        background-color: #FF0000;
        color: white;
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        top: 10px;
        line-height: 20px;
        text-align: center;
        margin: 20px 0;
        z-index: 11;
        @media (max-width: 767px){
            top: 40px;
            padding: 5px 8px;
            font-size: 10px;
            line-height: 15px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            border-style: solid;
            border-color: #FF0000 transparent transparent transparent;
            right: -15px;
            border-width: 16px;
            transform: rotate(45deg);
            top: 16px;
            @media (max-width: 767px){
                right: -13px;
                border-width: 14px;
                top: 11px;
            }
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            border-style: solid;
            border-color: #FF0000 transparent transparent transparent;
            right: -22px;
            border-width: 17px;
            @media (max-width: 767px){
                right: -20px;
                border-width: 14px;
            }
        }
    }
    .plp-card{
        background: transparent linear-gradient(90deg, #FAEAC0 0%, #F4F4F4 54%, #FAEAC0 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        position: relative;
        margin: 7px;
        @media (max-width:767px){
            background: #fff;
        }
        .irfswish{
            @media (max-width:767px){
                padding-top: 6px!important;
            }
        }
        .rightBtnsWrapper{
            @media (max-width: 767px){
                position: absolute;
                top: -295px;
                left: 15px;
                justify-content: space-between !important;
                width: 77%;
                z-index: 11;
            }
        }
       
        .rera-main-div{
            background-color: #F8990C;
            // justify-content: end;
            .pos-date{
                background-color: #F8990C;
                @media (min-width: 768px) and (max-width: 1400px){
                    left: 0px;
                }
            }
        }
        // .listcard-cont{
        //     @media (max-width:767px){
        //         background-color: #fff;
        //     }
        // }
        .border-primary {
            border: 1px solid #F8990C !important;
            @media (max-width:767px){
                border: none!important;
            }
        }
        .card-title{
            .text-primary{
                color: #F8990C!important;
            }
        }
        .bhk-color {
            &.active{
                border-color: #F8990C !important;
                background-color: #F8990C;
                color: #082040;
            }
        }
        .top-picks{
            color: #F8990C;
        }
        .compare-btn{
            .compare-btn{
                background-color: #F8990C;
            }
        }
    }
    .right-filter-accordation{
        .accordion-item{
            .accordion-body.filter-wrapbody{
                a.active{
                    background-color: #F8990C;
                }
            }
        }
    }
    .border-orange{
        border: 1px solid #F8990C!important;
    }
    .i-icon{
        color: #F8990C!important;
    }
    .text-orange{
        color: #F8990C!important;
    }
    .rc-slider-handle{
        background-color: #F8990C;
        border: 2px solid #F8990C;
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    border-color: #F8990C;
    box-shadow: 0 0 0 5px #F8990C;
   }
   .rc-slider-track{
    background-color: #F8990C;
   }
    .btn.sc{
            background: #F8990C;
    }
    .bgorange{
        margin-top: 20px;
        border-radius: 6px;
        display: grid;
        grid-gap: 0 10px;
        grid-template-columns: auto auto;
        grid-auto-flow: dense;
        @media (max-width:767px){
            margin: auto;
            margin-top: 10px;
            flex-direction: row-reverse;
        }
        div:first-child{
            @media (max-width:767px){
                grid-column-start: 2;
            }
        }
        div:nth-child(2){
            @media (max-width:767px){
                grid-column-start: 1;
                padding-right: 10px;
            }
        }
        .carousel{
            .carousel-item{
                padding: 0px;
                text-align: center;
                .irfsdisc{
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px!important;
                    margin-left: 5px;
                    text-align: left;
                    @media (max-width:767px){
                        font-size: 13px;
                        text-align: left;
                    }
                    @media (max-width:380px){
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .top-projects-card{
        background: transparent linear-gradient(180deg, #FAEAC0 0%, #F4F4F4 54%, #FAEAC0 100%) 0% 0% no-repeat padding-box;
        .rera-main-div {
            background-color: #F8990C;
            .pos-date {
                background-color: #F8990C;
            }
            .top-picks {
                color: #F8990C;
            }
        }
        .top-projects-details{
            background-color: transparent!important;
        }
    }
}