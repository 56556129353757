.banner-slide{
    background-image: url('../img/seopage/digital-hm-desktop-banner-1.jpg');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    @media ($max992) {
        background-image: url('../img/seopage/digital-hm-mobile-banner-1.jpg');
    }
}

.marketingPages{
    .bannerHero{
        .banner-slide{
            .container{
                height: 100%;
                display: flex;
                align-items: center;
                .home-banner-cont{
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    @media ($max992) {
                        text-align: center;
                    }
                }
            }
        }
    }
    .devListing{
        padding: 30px 0;
    }
    .slick-dots li button:before{
        color: #fff;
        border: 1px solid #000;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        scale: 0.8;
        opacity: 1;
        background-color: #fff;
    }
    .slick-dots li.slick-active button:before{
        background: $sc;
        color: $sc;
        scale: 1;
    }
}
//testpages
.devInfoSliderSec {
    padding-left: 20%;
    @media (max-width: 1440px) {
        padding-left: 5%;
        
    }
    @media ($max992) {
        padding: 30px 15px !important;
    }
    .slick-slider{
        @media ($max992) {
            padding: 50px 0 !important;
        }
    }
    .DeveloperInfoCard{
        background:url('../img/seopage/dummy-build-scale.jpg');
        background-size: cover;
        background-position: center;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border-radius: 12px;
        border: 1px solid #f1f1f1;
        padding: 20px;
        margin: 0 30px;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media ($max992) {
            margin: 0 5px;
            padding: 10px;
        }
        .developer-card{
            .infoWrapper{
                .imgWrap{
                    text-align: center;
                    img{
                        background: #fff;
                        border-radius: 12px;
                        aspect-ratio: 3 / 2;
                        margin: 0 auto;
                        width: 240px;
                        max-width: 100%;
                        padding: 10px;
                        object-fit: contain;
                        object-position: center center;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .slick-list{
        padding-left: 0px!important;
    }
    .slick-slide{
        // margin-left: 60px;
    }
}

//real estate sales
#crack {
    position: relative;
  }
  
  .crack-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 0;
    @media ($max992) {
        position: relative;
        top: unset;
        left: unset;
        padding: 30px 0;
    }
  }
  
  .crack-content {
    max-width: 580px;
  }
  
  .carck-desc {
    line-height: 32px !important;
  }

// svg 
.hsw{background: $sc;}
.cls-1 {
    opacity: 1;
    mix-blend-mode: luminosity;
    }
    .cls-1, .cls-3 {
    isolation: isolate;
    }
    .cls-2 {
    /*opacity: 0.515;*/
    opacity: 1;
    }
    .cls-3 {
    opacity: 0.256;
    /*opacity: 1;*/
    mix-blend-mode: multiply;
    fill: url(#linear-gradient);
    }
    .cls-4 {
    fill: url(#linear-gradient-3);
    }
    .cls-bg{/*fill: #fff;*/fill: url(#linear-gradient-3);}
    .cls-12, .cls-5 {
    fill: none;
    stroke-width: 2px;
    }
    .cls-5 {
    stroke: #fff;
    stroke-dasharray: 4 5;
    }
    .cls-6 {
    fill: #fff;
    }
    .cls-7 {
    fill: #192951;
    }
    .cls-8 {
    font-size: 18px;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    /*fill: #fff;*/
    /*fill: #192951;*/
    }
    .cls-9 {
    /*fill: #192951;*/
    /*fill: #fff;*/
    font-size: 16px;
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0.02em;
    font-weight: 600;
    }
    .lh-1{line-height: 148px;}
    .lh-2{line-height: 100px;}
    .cls-10 {
    fill: #192951;
    }
    .cls-11 {
    fill: #192951;
    }
    .cls-12 {
    stroke: #192951;
    }
    .cls-13 {
    filter: url(#Rectangle_90-18);
    }
    .cls-14 {
    filter: url(#Rectangle_86-18);
    }
    .cls-15 {
    filter: url(#Rectangle_90-17);
    }
    .cls-16 {
    filter: url(#Rectangle_86-17);
    }
    .cls-17 {
    filter: url(#Rectangle_90-16);
    }
    .cls-18 {
    filter: url(#Rectangle_86-16);
    }
    .cls-19 {
    filter: url(#Rectangle_90-15);
    }
    .cls-20 {
    filter: url(#Rectangle_86-15);
    }
    .cls-21 {
    filter: url(#Rectangle_90-14);
    }
    .cls-22 {
    filter: url(#Rectangle_86-14);
    }
    .cls-23 {
    filter: url(#Rectangle_90-13);
    }
    .cls-24 {
    filter: url(#Rectangle_86-13);
    }
    .cls-25 {
    filter: url(#Rectangle_90-12);
    }
    .cls-26 {
    filter: url(#Rectangle_86-12);
    }
    .cls-27 {
    filter: url(#Rectangle_90-11);
    }
    .cls-28 {
    filter: url(#Rectangle_86-11);
    }
    .cls-29 {
    filter: url(#Rectangle_90-10);
    }
    .cls-30 {
    filter: url(#Rectangle_86-10);
    }
    .cls-31 {
    filter: url(#Rectangle_90-9);
    }
    .cls-32 {
    filter: url(#Rectangle_86-9);
    }
    .cls-33 {
    filter: url(#Rectangle_90-8);
    }
    .cls-34 {
    filter: url(#Rectangle_86-8);
    }
    .cls-35 {
    filter: url(#Rectangle_90-7);
    }
    .cls-36 {
    filter: url(#Rectangle_86-7);
    }
    .cls-37 {
    filter: url(#Rectangle_90-6);
    }
    .cls-38 {
    filter: url(#Rectangle_86-6);
    }
    .cls-39 {
    filter: url(#Rectangle_90-5);
    }
    .cls-40 {
    filter: url(#Rectangle_86-5);
    }
    .cls-41 {
    filter: url(#Rectangle_90-4);
    }
    .cls-42 {
    filter: url(#Rectangle_86-4);
    }
    .cls-43 {
    filter: url(#Rectangle_90-3);
    }
    .cls-44 {
    filter: url(#Rectangle_86-3);
    }
    .cls-45 {
    filter: url(#Rectangle_90-2);
    }
    .cls-46 {
    filter: url(#Rectangle_86-2);
    }
    .cls-47 {
    filter: url(#Rectangle_90);
    }
    .cls-48 {
    filter: url(#Rectangle_86);
    }