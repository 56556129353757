.studioheader{
    @function proportional-width($container-width, $reference-screen-width) {
        @return calc($container-width / $reference-screen-width) * 100vw;
    }
    @mixin proportional-container($container-width, $reference-screen-width) {
        width: proportional-width($container-width, $reference-screen-width);
    }
    .container {
        @include proportional-container(1688px, 1920px);
        max-width: 100%;
        @media only screen and (max-width: 640px) {
            @include proportional-container(600px, 640px);
        }
    }
    @function px-to-vw($px, $base: 1366) {
        @return calc($px / $base) * 100vw;
    }
    @function px-to-vhmob($px, $base: 667) {
        @return calc($px / $base) * 100vh;
    }
    .navbar{
        background: #fff!important;
        padding: px-to-vw(8);
        .navbar-brand {
            width: px-to-vw(240);
            img{
                width: 100%;
            }
        }
        .nav-item{
            margin-right: px-to-vw(20);
            .nav-link{
                cursor: pointer;
                text-transform: uppercase;
                font-size: px-to-vw(16);
                padding: px-to-vw(8);
                &.active{
                        font-weight: 700;
                }
            }
        }
        .navbar-collapse{
            @media (max-width: 992px) {
                justify-content: center!important;
            }
            .navbar-nav{
                @media (max-width: 992px) {
                    align-items: center;
                    text-align: center;
                }
            }
        }
        @media (max-width: 992px) {
            .navbar-brand{
                width: auto;
            }
        }
    }
    .enqdiv{
        border-radius: px-to-vw(20);
        border: px-to-vw(2) solid #000;
        background: #FFF;
        box-shadow: 0px 0px px-to-vw(4) 0px rgba(0, 0, 0, 0.25);
        padding: px-to-vw(5) px-to-vw(20);
    }
    .register-btnstudio{
        margin-top: px-to-vw(20);
    }

    .enqbtn{
        color: #000;
        font-size: px-to-vw(12);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: none!important;
        cursor: pointer;
    }
    .enqdiv-mob{
        @media (max-width: 767px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            text-align: center;
            left: 0;
            border-radius: 0;
            background: #0942D9;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 5px 20px;
            color: #fff;
        }
        .enqbtn{
            color: #fff;
        }
    }
    .popup-overlay {
        z-index: 11111;
        position: fixed;
        background: rgb(0 0 0 / 75%);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .popup-content {
        background: #fff;
        width: px-to-vw(500);
        height: auto;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        padding: px-to-vw(30);
        @media (max-width: 767px) {
            width: 95%;
            margin: 0 auto;
        }
    }
    .submit-btn{
        margin-top: px-to-vw(30);
    }
}