.compare-banner {
    background-color: #42B57E;
    border-radius: 50px;
    height: calc(100vh - 70px);
    @media (min-width:993px) and (max-width:1030px){
        height: calc(76vh - 70px);
    }
    @media (min-width:1660px){
        height: calc(70vh - 70px);
    }
    @media (max-width:992px){
        height: auto;
    }
    .bannercontent{
        padding-top: 100px;
        padding-left: 30px;
        @media (max-width:992px){
            padding-top: 50px;
            padding-left: 0px;
            text-align: center;
        }
        .bwlogo{
            @media (min-width:993px) and (max-width:1030px){
                display: block;
                margin: auto;
            }
            @media (max-width:992px){
                width: 100px;
            }
        }
    }
    .bnrsubhead {
        font-size: 40px;
        line-height: 46px;
        color: #fff;
        font-weight: 700;
        margin-top: 40px;
        @media (min-width:993px) and (max-width:1030px){
            text-align: center;
        }
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
    .bannerimg-div{
        // height: 100%;
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        @media (max-width:992px){
            margin-top: 20px;
            position: relative;
            transform: unset;
            left: unset;
        }
        &::before{
            content: '';
            position: absolute;
            background-color: #5AEFA8;
            width: 320px;
            height: 320px;
            border-radius: 100%;
            top: 50%;
            left: 34%;
            transform: translate(-50%, -50%);
            @media (max-width:992px){
                width: 200px;
                height: 200px;
                left: 45%;
            }
        }
    }
    .bannerimg{
        width: 390px;
        margin: 0 auto;
        margin-right: 90px;
        display: block;
        z-index: 11;
        @media (max-width:992px){
            width: 240px;
            margin: 0 auto;
        }
    }
    .cobject{
        position: absolute;
        top: 35%;
        left: 10%;
        width: 140px;
        transform: translateY(-50%);
        @media (max-width:992px){
            left: 0%;
            width: 90px;
        }
    }
    .cobject1 {
        position: absolute;
        bottom: 180px;
        width: 80px;
        @media (max-width:992px){
            display: none;
        }
    }
    .cobject2{
        position: absolute;
        top: 20px;
        right: 150px;
        width: 90px;
        @media (max-width:992px){
            right: 40px;
        }
    }
    .cobject3{
        position: absolute;
        top: 20px;
        width: 140px;
    }
    .cobject4{
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        right: 110px;
        width: 70px;
        @media (max-width:992px){
            display: none;
        }
    }
    .cobject5{
        position: absolute;
        bottom: 50px;
        right: 80px;
        width: 140px;
        z-index: 111;
        @media (max-width:992px){
            right: 40px;
            width: 110px;
        }
    }
    .hand{
        position: absolute;
        right: 0;
        top: 45%;
        transform: translateY(-50%);
        width: 190px;
        @media (max-width:992px){
            display: none;
        }
    }
    .hand1{
        position: absolute;
        bottom: 0px;
        left: 5%;
        transform: translateX(-50%);
        width: 400px;
        z-index: 111;
        @media (max-width:992px){
            display: none;
        }
    }
}

.compare{
    .add-row{
        background-color: #F7F8F8;
        border-radius: 30px;
        padding: 0px 35px 35px;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
        @media screen  and (max-width: 768px) {
            padding: 0px 10px 15px;
        }
        .bnrsubhead {
            font-size: 40px;
            line-height: 46px;
            color: #050F27;
            font-weight: 700;
            margin-top: 40px;
            margin-bottom: 25px;
        }
        .add-box{
            border: 2px dashed #42B57E;
            height: 500px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                cursor: pointer;
                top: -5px;
                right: -5px;
            }
            @media screen  and (max-width: 768px) {
                min-height: 170px;
                height: auto;
                margin: 0px;
                img{
                    width: 60px;
                }
            }
            // div{
            //     .projectShowInfo{
            //         .projectName{
            //             @at-root {
            //                 .add-box {
            //                   height: 100%;
            //                 }
            //               }
            //         }
            //     }
            // }
        }
        .add-box:has(.projectShowInfo .projectName) {
            height: 100%;
            border: none;
            align-items: flex-start;
            background-color: #fff;
            border-radius: 40px;
            box-shadow: 0 0 14px 0 #00000040;
            padding: 10px;
        }
        .projectName{
            @media (max-width:767px){
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                flex-direction: column;
            }
            h4{
                color: #000;
                font-size: 24px;
                font-weight: 800;
                line-height: 29px;
                margin-bottom: 15px;
                margin-top: 10px;
            }
        }
    }

    .similer-project{
        .top-projects-slider{
            &::after{
                background: linear-gradient(270deg, #ffffff 40.4%, rgb(255 255 255 / 80%) 60.4%, rgb(255 255 255 / 0%) 100%);
            }
            .top-projects-card {
                box-shadow: -1px 1px 9px 0px rgb(17 17 17 / 40%);
                border-radius: 40px;
                overflow: hidden;
                padding: 10px;
                background-color: #fff;
                width: 360px !important;
                @media ($max992) {
                    width: 100% !important;
                }
            }
        }
    }
    .top-projects-slider .slick-list{
        padding-left: 10px;
    }
}

.mgtpbt{
    @media (max-width:992px){
        margin: 15px 0px;
    }
}
.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11111;
    }
    .popup-body {
        position: absolute;
        left: 30%;
        right: 30%;
        bottom: 30%;
        top: 30%;
        text-align: center;
        margin: auto;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width:992px){
            width: 90%;
            left: 50%;
            right: unset;
            transform: translateX(-50%);
        }
        .search-box{
            margin: auto;
            width: 90%;
            margin-top: 40px;
        }
        .search-wrap {
            z-index: 12;
            width: 90%;
            overflow-x: hidden;
            margin: auto;
            margin-left: 30px;
        }
        .select2-container--default .select2-selection--single {
            background-color: #fff;
            border: 1px solid #aaa;
            border-radius: 25px;
            height: 50px;
            padding: 10px;
            .select2-selection__arrow {
                height: 26px;
                position: absolute;
                top: 11px;
                right: 14px;
                width: 20px;
            }
        }
        // .select2-dropdown{
        //     left: 0px!important;
        //     width: auto!important;
        // }
    }
    .close-btn {
        border-radius: 100%;
        background-color: #42B57E;
        border: none;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
        color: #fff;
        font-weight: 700;
        z-index: 11;
    }

    .hotprop-row{
        background-color: #F7F8F8;
        border-radius: 30px;
        padding: 0px 35px 35px;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
        @media (max-width:992px){
            padding: 0px 0px 35px;
        }
        .bnrsubhead {
            font-size: 40px;
            line-height: 46px;
            color: #050F27;
            font-weight: 700;
            margin-top: 40px;
            margin-bottom: 25px;
        }
    }

    .prop-boxdiv {
        background: #fff;
        border-radius: 25px;
        padding:10px 25px;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        @media (max-width:992px){
            padding: 10px 13px;
        }
        .prop-box {
            background: #F7F8F8;
            border-radius: 25px;
            display: flex;
            align-items: center;
            padding: 20px;
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
            margin: 20px 0px;
            @media (max-width:992px){
                padding: 10px;
                margin-top: 8px;
            }
            .prop-cont{
                margin-left: 20px;
                .builder{
                    color: #8D8D8D;
                    font-size: 14px;
                    margin-bottom: 0px;
                    margin-bottom: 5px;
                }
                .project-name{
                    color: #000;
                    font-size: 20px;
                    font-weight: 700;
                }
                .project-desc{
                    color: #000;
                    font-size: 13px;
                    margin-bottom: 0px;
                    margin-top: 10px;
                }
            }
        }
        .vsimg{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img{
                width: 90px;
            }
        }
    }

.projectShowInfo{
    img{
        border-radius: 20px 20px 0 0;
        margin-bottom: 10px;
        height: 350px;
        object-fit: cover;
    }
}