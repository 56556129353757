
.comparison {
    // background-color: #F4F5FA;
    .comparison-title {
        padding-block: 50px;
        font-size: 32px;
        line-height: 42px;
    }
    .comparison-left-right {
        padding: 10px;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 30px;
    }
    .left-details {
        background-color: #43A66A;
        padding: 23px 20px;
        color: #ffffff;
        border-radius: 20px;
        @media screen and (max-width:600px){
            padding: 10px;
        }
        .feature-detail {
            font-size: 20px;
            line-height: 26px;
            font-weight: bold;
        }
        .left-desc {
            padding-top: 29px;
            @media screen and (max-width: 600px){
                padding-top: 0px;
            }
            .details {
                padding-block: 15.5px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;
            }
        }
    }
    .comparison-mob-next {
        background-color: #43A66A;
    }
    .compairion-top {
        top: 98px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        &.comparison-next {
            background-color: #43A66A;
            right: 3.5%;
        }
        &.comparison-prev {
            background-color: #707070;
            left: 3.5%;
        }
    }
    .position-relative.comparison-card:not(:last-child)::after {
        content: '';
        border-right: 1px solid #E6E6E6;
        height: 90%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    .right-details {
        padding: 23px 40px;
        @media screen and (max-width:600px){
            padding: 10px;
        }
        .irfs-exl{
            display: none;
        }
        .compareirfs{
            .irfs-exl{
                display: block;
                background-color: red;
                color: #fff;
                display: inline-block;
                font-size: 12px;
                font-weight: 700;
                line-height: 20px;
                margin: 20px 0;
                padding: 6px 12px;
                position: absolute;
                text-align: center;
                top: 3px;
                z-index: 11;
                &::before{
                    border: 16px solid #0000;
                    border-top-color: red;
                    content: "";
                    position: absolute;
                    right: -16px;
                    top: 16px;
                    transform: rotate(45deg);
                }
                &::after{
                    border: 17px solid #0000;
                    border-top-color: red;
                    content: "";
                    position: absolute;
                    right: -22px;
                    top: 0;
                }
            }
        }
        .comparison-card-img {
            height: 191px;
            object-fit: cover;
            border-radius: 6px;
            @media screen and (max-width: 600px){
                height: 120px;
                width: 150px;
            }
        }
        .right-desc {
            padding-top: 29px;
            .right-heading {
                height: 58px;
                color: #082040;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 600px){
                    font-size: 16px;
                }
            }
            .right-detail {
                font-size: 16px;
                line-height: 26px;
                height: 26px;
                overflow: hidden;
                @media screen and (max-width:700px){
                    font-size: 12px;
                    line-height: 13px;
                }
            }
            .details {
                padding-block: 15.5px;
                border-bottom: 1px solid #E6E6E6;
                min-height: 58px;
                max-height: 58px;
                vertical-align: middle;
                display: flex;
                align-items: center;
                @media screen and (max-width: 600px){
                    max-height: 70px;
                    min-height: 70px;
                    padding-block: 10px;
                    flex-direction: column;
                    align-items: start;
                    .details {
                        font-size: 12px;
                        display: block;
                        border-bottom: 0px;
                        min-height: auto;
                        padding-block: 0px;
                        font-weight: 700;
                    }
                }
                .amenities-gap {
                    gap: 10px;
                    height: 100px;
                    overflow-y: auto;
                    .pdp-amenties-tag {
                        padding: 6px 12px 6px 6px;
                        background-color: #edf1fa;
                        border-radius: 6px;
                    }
                }
                .amenities-gap::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
                    border-radius: 10px;
                    background-color: #f5f5f500;
                }
                .amenities-gap::-webkit-scrollbar {
                    width: 6px;
                    background-color: #f5f5f500;
                }
                .amenities-gap::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                    background-color: #e6e6e6a4;
                }
            }
        }
    }
    .property-head {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 32px;
    }
    // .comparison-slider{
    //     &.parent {
    //         display: grid;
    //         grid-template-columns: repeat(3, 1fr);
    //         grid-template-rows: 1fr;
    //         grid-column-gap: 0px;
    //         grid-row-gap: 0px;
    //         }
    // }
    @media screen and (max-width: 991px){
        .left-details {
            .feature-detail {
                font-size: 12px;
            }
            .left-desc {
                .details {
                    font-size: 12px;
                    max-height: 58px;
                }
            }
        }
    }
    @media screen and (max-width: 600px){
        .mob-comparison-details {
            padding-block: 15px;
            .mob-comparision-title {
                font-size: 14px;
                line-height: 20px;
                height: 40px;
                // @include line-show(2);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .amenities-gap {
                gap: 10px;
                height: 100px;
                overflow-y: auto;
                .pdp-amenties-tag {
                    padding: 6px 12px 6px 6px;
                    background-color: #edf1fa;
                    border-radius: 6px;
                }
            }
            .mob-comparison-desc {
                padding-block: 12px;
                border-bottom: 1px solid #E6E6E6;
                span {
                    display: flex;
                    flex-wrap: wrap;
                    line-clamp: 1;
                    box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 24px;
                    /* optional: add vendor prefixes for maximum browser compatibility */
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .comparison-title{
            padding-block: 45px;
            font-size: 22px;
            line-height: 28px;
            text-align: center !important;  
        }
    }
}
.comparison-winner{
    padding: 20px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 0px 10px #00000029;
    .comparison-winner-img{
        border-radius: 27px;
        background-color: #E2F1E8;
        padding: 20px 25px 10px;
        img{
            width: 231px;
            height: 231px;
            border-radius: 22px;
            object-fit: cover;
            @media screen and (max-width:600px){
                width: 100%;
            }
        }
        h3{
            color: #43A66A;
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
        }
    }
    .winner-table{
        width: 100%;
        list-style: none;
        li{
            border: 1px solid #8E8E8E;
            border-radius: 48px;
            height: 70px;
            margin-bottom: 10px;
            padding: 10px 30px;
            color: #252525;
            @media screen and (max-width:600px){
                padding: 10px;
                height: auto;
            }
            &:first-child{
                background-color: #43A66A;
                color: #fff;
                box-shadow: 0px 3px 12px #00000067;
                transform: scale(1.1);
                svg{
                    text{
                        fill: #fff;
                    }
                }
            }
            &:not(:first-child){
                svg{
                    .circle-progress{
                        stroke:#C69121;
                    }
                }
            }
            .index{
                font-size: 26px;
                line-height: 39px;
                min-width: 40px;
                font-weight: 700;
                @media screen and (max-width:600px){
                    font-size: 16px;
                    line-height: 26px;
                    min-width: 20px;
                }
            }
            .cup-img{
                min-width: 100px;
                @media screen and (max-width:600px){
                    min-width: 44px;
                }
            }
            .project-info{
                vertical-align: middle;
                img{
                    border-radius: 100%;
                    margin-right: 20px;
                    @media screen and (max-width:600px){
                        margin-right: 5px;
                    }
                }
                p{
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0;
                    @media screen and (max-width:600px){
                        font-size: 12px;
                    }
                }
            }
            .bw-score{
                font-size: 18px;
                line-height: 27px;
                font-weight: 700;
                @media screen and (max-width:600px){
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
}
@media screen and (max-width:700px){
    .comparison-slider{
        .slick-arrow{
            top: calc(50% - 42px);
        }
        .slick-arrow.slick-prev{
            left: -20px;
        }
    }
}