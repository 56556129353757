
.irfsbanner{
    @media (max-width:992px){
        background: radial-gradient(76.45% 76.45% at 50% 50%, #FDCD3E 36%, #E79818 100%);
        border-radius: 0px 0px 40px 40px;
        margin-bottom: 150px;
    }
    .bannercontainer{
        @media (min-width: 1023px){
            padding: 0px;
        }
        .bannerbg {
            border-radius: 81px;
            background: radial-gradient(76.45% 76.45% at 50% 50%, #FDCD3E 36%, #E79818 100%);
            padding: 20px 35px 20px 0px;
            position: relative;
            align-items: center;
            padding-top: 40px;
            @media (max-width:992px){
                padding: 0;
                padding-top: 10px;
            }
            .bnrrightmob{
                margin: auto;
                margin-bottom: -155px;
                margin-top: 10px;
                @media ($max992) {
                    width: 80%;
                }
                @media (max-width:767px) {
                    width: unset;
                }
            }
            .patterntl{
                position: absolute;
                top: 0px;
                left: 0;
                mix-blend-mode: darken;
                width: auto;
                height: 95%;
                padding: 0;
            }
            .patterntl1{
                right: 0;
                left: unset;
            }
            .patternbl{
                position: absolute;
                bottom: 3px;
                left: 0;
                mix-blend-mode: darken;
                width: auto;
                height: 95%;
            }
            .patternbl1{
                left: unset;
                right: 0;
            }
            .bnrcontent {
                padding-left: 55px;
                @media (max-width:992px){
                    padding-left: 0px;
                    text-align: center;
                }
                .logogrid{
                    @media (max-width:767px){
                        width: 155px;
                        margin: auto;
                        margin-bottom: 5px;
                    }
                }
                .bannertitle {
                    color: #FFF;
                    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    font-size: 70px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 70px;
                    margin-bottom: 0;
                    @media ($max992) {
                        font-size: 64px;
                        line-height: normal;
                    }
                    @media (max-width:767px){
                        font-size: 24px;
                        line-height: normal;
                    }
                }
                .irfsTitle{
                    margin-bottom: 10px;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    @media ($max640) {
                        width: 60%;
                        margin: 0 auto;
                        margin-bottom: 10px;
                    }
                    p{
                        margin-bottom: 0;
                        color: #FFF;
                        text-shadow: 0px 0px 4.9px rgba(0, 0, 0, 0.25);
                        font-size: 34px;
                        font-weight: 600;
                        line-height: normal;
                        border-bottom: 1px solid #fff;
                        padding-bottom: 5px;
                        @media ($max640) {
                            font-size: 16px;
                            padding-bottom: 0;
                            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                        }
                    }
                }
                .discountdiv{
                    .disc{
                        display: flex;
                        align-items: center;
                        @media (max-width:992px){
                            justify-content: center;
                        }
                        @media (max-width:767px) {
                            font-size: 12px;
                        }
                        img{
                            width: 24px;
                            margin-right: 5px;
                            @media (max-width:767px){
                                width: 16px;
                            }
                        }
                        color: #000;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
            .btmdiv {
                background: linear-gradient(90deg, #F44336 0%, #F44336 83%, rgba(244, 68, 55, 0.00) 100%);
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 45px;
                padding-left: 35px;
                @media (max-width:992px){
                    background: linear-gradient(90deg, rgba(244, 67, 54, 0.00) 0%, #F44336 13%, #F44336 83%, rgba(244, 68, 55, 0.00) 100%);
                    justify-content: center;
                    padding-left: 0px;
                    margin-top: 10px;
                    gap: 0 20px;
                }
                @media (max-width:767px) {
                    height: 35px;
                    gap: 0 0;
                }
                img{
                    margin-right: 5px;
                }
                p{
                    margin-bottom: 0px!important;
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    @media (max-width:767px){
                        font-size: 12px;
                        padding: 0 7px;
                        border-right: 1px solid #FFF;
                        &:last-of-type{
                            border-right: none;
                        }
                    }
                }
            }
            .themeBtnWrapper{
                padding-left: 55px;
                padding-top: 20px;
                text-transform: uppercase;
                font-weight: 500;
                @media ($max992) {
                    padding-left: 0;
                    text-align: center;
                }
            }
            .bgg {
                position: absolute;
                opacity: 0.92;
                mix-blend-mode: color-burn;
                top: 18px;
                left: 17px;
            }
            .cl{
                position: absolute;
                z-index: 11;
            }
            .bnrsharman{
                position: absolute;
                right: -65px;
                bottom: -1px;
                width: auto;
            }
            .irfsbuild{
                width: auto;
                margin: auto;
                margin-right: 0;
                margin-bottom: -20px;
                @media (max-width:767px){
                    margin-bottom: -23px;
                }
            }
            .rightflex {
                display: flex;
                align-items: center;
                justify-content: center;
                .rightcontent {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    .irfsfinallogo{
                        margin-bottom: 20px;
                    }
                    .lim {
                        color: #192951;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        background: #FCCA3C;
                        padding: 6px 0px;
                        margin-bottom: 0px;
                    }
                    h2.bumper {
                        color: #FFF;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 55px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .search-component{
        width: 550px;
        @media ($max1024) {
            width: 440px;
            position: relative;
        }
        .select2-selection{
            height: 65px;
            padding: 10px 60px 10px 80px;
        }
        .searchBtn {
            background-color: #192951;
            padding: 10px 25px;
        }
        .select2-container {
            .select2-search--inline{
                .select2-search__field {
                    margin-top: 8px;
                    margin-left: 0px;
                    font-size: 13px;
                    @media (max-width:1024px) and (min-width:992px) {
                        font-size: 9px;
                    }
                }
            }
        }
    }
}
.irfshome{
    .hero-searchmain {
        @media (max-width:992px){
            width: 100%;
            background: radial-gradient(76.45% 76.45% at 50% 50%, #FDCD3E 36%, #E79818 100%);
            // position: absolute!important;
            // z-index: 11111111;
            // z-index: 2;
        }
    }
    .searchIcon{
        img {
            @media (max-width:992px){
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}

.irfs-page{
    .searchIcon{
        img{
            padding: 0 10px;
            // @media (max-width:767px){
            //     margin-top: 15px;
            // }
        }
    }
    @media ($max992) {
        margin-top: 60px;
    }
    @media (max-width:767px){
        margin-top: 2rem;
        padding-top: 0px!important;
        // padding: 0 15px;
    }
   .searchPopup .nextBtn{
        background-color: #FFB03D!important;
    }
    .cityDropdown {
        top: 44%;
        color: #FFB03D;
    }
    .searchBtn {
        // top: 44%;
        background-color: #FFB03D;
        padding: 10px 25px;
    }
    .sec-title{
        @media (max-width:767px){
            font-size: 24px;
            line-height: 28px;
        }
        @media (max-width:360px) {
            font-size: 21px;
        }
    }
    .locality-list-wrap li a.active{
        background-color: #FFB03D;
        border-color: #FFB03D;
    }
    .slick-arrow:hover {
        background-color: #FFB03D;
    }
    .home-features .project-container{
        background-color: #fff;
        box-shadow: none;
        .top-projects-slider{
            &::after{
                background: linear-gradient(270deg, #ffffff 40.4%, rgba(241, 241, 241, 0.8) 60.4%, rgba(241, 241, 241, 0) 100%);
            }
        }
    }
    .compare-section{
        padding-top: 150px;
        @media (max-width:767px){
            padding: 0 15px;
            padding-top: 40px;
        }
        .comparesec-row {
            border-radius: 50px;
            background: #F38023;
            display: flex;
            align-items: center;
            padding-right: 30px;
            padding-left: 25px;
            @media (max-width:767px){
                flex-direction: column-reverse;
                padding-top: 40px;
            }
            .sec-title{
                font-size: 3.8rem;
                margin-bottom: 15px;
                @media (max-width:992px){
                    font-size: 24px;
                    line-height: 28px;
                    margin-top: 20px;
                }
                @media (max-width:767px){
                    font-size: 24px;
                    line-height: 28px;
                    margin-top: 20px;
                }
            }
            .joshi2{
                margin-top: -145px;
                @media (max-width:1025px){
                    margin-top: -100px;
                }
                @media (max-width:767px){
                    margin-top: 10px;
                }
            }
            .house1 {
                position: absolute;
                top: 20%;
                transform: translateY(-50%);
                left: -40px;
                width: 95px;
                @media (max-width:767px){
                    top: 33%;
                    left: -35px;
                    width: 70px;
                }
            }
            .house2 {
                position: absolute;
                top: 20%;
                transform: translateY(-50%);
                left: 105px;
                @media (max-width:767px){
                    top: 30%;
                    left: 40px;
                    width: 70px;
                }
            }
            .house3 {
                position: absolute;
                top: 46%;
                transform: translateY(-50%);
                left: 15px;
                width: 110px;
                @media (max-width:767px){
                    top: 58%;
                    left: 5px;
                    width: 80px;
                }
            }
        }
    }
    .searchBtn{
        background-color: #ffb03d;
    }
}
.home-features{
    @media (max-width:767px){
        padding-top: 30px!important;
        padding: 0 15px;
    }
    .cityDropdown{
        display: none;
    }
}
.faq{
    @media (max-width:992px){
        padding: 0 15px;
    }
}
.overview{
    // background-image: url('../img/dotted-bg.png');
    // background-size: contain;
    position: relative;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, transparent 10%); /* Adjust the size and color of the dots */
    background-size: 50px 50px; /* Adjust the size of the dots and spacing */
    background-repeat: repeat;
    background-position: center;
    padding-top: 50px;
    @media ($max992) {
        padding-top: 120px;
    }
    @media (max-width:767px){
        padding: 0;
        padding-top: 60px;
    }
    &::before{
        content: '';
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.87) 13.5%, rgba(255, 255, 255, 0.00) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
    }
    .flower{
        position: absolute;
    //     -webkit-animation-name: rotate;
    //     -moz-animation-name: rotate;
    //      -ms-animation-name: rotate;
    //       -o-animation-name: rotate;
    //          animation-name: rotate;
    //  -webkit-animation-iteration-count: infinite;
    //     -moz-animation-iteration-count: infinite;
    //      -ms-animation-iteration-count: infinite;
    //       -o-animation-iteration-count: infinite;
    //          animation-iteration-count: infinite;
    //  -webkit-animation-timing-function: linear;
    //     -moz-animation-timing-function: linear;
    //      -ms-animation-timing-function: linear;
    //       -o-animation-timing-function: linear;
    //          animation-timing-function: linear;
        &.flower1{
            width: 62px;
            top: -100px;
            left: 250px;
            @media (max-width:767px){
                width: 30px;
                top: -35px;
                left: 55px;
            }
            // -webkit-animation-duration: 10s;
            // -moz-animation-duration: 10s;
            //  -ms-animation-duration: 10s;
            //   -o-animation-duration: 10s;
            //      animation-duration: 10s;
        }
        &.flower2{
            top: 55px;
            left: 0;
            width: 98px;
            @media (max-width:767px){
                width: 50px;
            }
            // -webkit-animation-duration: 15s;
            // -moz-animation-duration: 15s;
            //  -ms-animation-duration: 15s;
            //   -o-animation-duration: 15s;
            //      animation-duration: 15s;
        }
        &.flower3{
            position: absolute;
            right: 0;
            top: 0px;
            @media (max-width:767px){
                width: 55px;
                top: -70px;
            }
            // -webkit-animation-duration: 25s;
            // -moz-animation-duration: 25s;
            //  -ms-animation-duration: 25s;
            //   -o-animation-duration: 25s;
            //      animation-duration: 25s;
        }
        @-webkit-keyframes rotate {
            from { -webkit-transform: rotate(360deg); }
            to { -webkit-transform: rotate(0); }
        }
        @-moz-keyframes rotate {
            from { -moz-transform: rotate(360deg); }
            to { -moz-transform: rotate(0); }
        }
        @-ms-keyframes rotate {
            from { -ms-transform: rotate(360deg); }
            to { -ms-transform: rotate(0); }
        }
        @-o-keyframes rotate {
            from { -o-transform: rotate(360deg); }
            to { -o-transform: rotate(0); }
        }
        @keyframes rotate {
            from { transform: rotate(360deg); }
            to { transform: rotate(0); }
        }
    }
    .z11{
        position: relative;
        z-index: 11;
    }
    .overview-flex {
        display: flex;
        margin-top: 120px;
        @media (max-width:992px){
            flex-direction: column;
        }
        @media (max-width:767px) {
            margin-top: 90px;
        }
        .dflex-mob{
            @media (max-width:992px){
                display: flex;
                margin-top: 55px;
                align-items: flex-start;
            }
            @media (max-width:767px) {
                margin-top: 15px;
            }
        }
        .overhead{
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            @media (max-width:767px){
                font-size: 16px;
            }
        }
        p{
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            @media (max-width:767px){
                font-size: 12px;
            }
        }
        .over-card{
            border-radius: 24px;
            background: #192951;
            box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.14);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 20px;
            transform: rotate(12.258deg);
            transition: all .6s ease-in-out;
            transition-delay: .6s;
            transform-origin: center;
            @media (max-width:767px){
                flex-direction: row;
                padding: 10px 0px;
            }
            .coupons{
                margin-bottom: -101px;
                margin-right: -67px;
                margin-top: -60px;
                @media (max-width:767px){
                    margin-bottom: -39px;
                    margin-right: -20px;
                    margin-top: -40px;
                }
            }
            .over-cardicon{
                float: right;
            }
            .over-cardcontent{
                padding-left: 40px;
                padding-right: 100px;
                @media (max-width:767px){
                    padding-left: 20px;
                    padding-right: 0px;
                }
            }
        }
        .over-card1 {
            display: flex;
            align-items: center;
            border-radius: 24px;
            background: #FFB029;
            box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.14);
            margin-left: 25px;
            padding-left: 40px;
            padding-right: 30px;
            height: auto;
            // transition: all .6s ease-in-out;
            transition-delay: .6s;
            // transform-origin: right;
            @media (min-width: 768px) and (max-width: 1400px){
                padding: 15px 30px;
            }
            @media (max-width:992px){
                margin-left: 0px;
                display: block;
                padding-left: 20px;
                padding-right: 15px;
                margin: 0 5px;
                padding-top: 15px;
                height: 510px;
            }
            @media (max-width:767px) {
                height: 240px;
            }
            .deal{
                width: 330px;
                margin-top: -33px;
                margin-bottom: -3px;
                @media ($max992) {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                @media (max-width:767px){
                    width: 100px;
                }
            }
            .support1{
                width: 310px;
                @media (max-width:767px){
                    width: 120px;
                    margin-top: -30px;
                    margin-left: -50px;
                    left: -40px;
                    position: relative;
                }
            }
            &.over-card1-1{
                transform: rotate(-4deg);
            }
            &.over-card1-2{
                background: #E65C62;
                transform: rotate(4deg);
            }
        }
    }
}
@keyframes example {
    0%   {transform: rotate(4deg);}
    25%  {transform: rotate(-4deg);}
    50%  {transform: rotate(2deg);}
    75%  {transform: rotate(-2deg);}
    100% {transform: rotate(0deg);}
}
.in-viewport .overview-flex .over-card {
    animation: example 0.7s ease-in-out forwards;
    transform: rotate(0deg);
}
@keyframes example1 {
    0%   {transform: rotate(-4deg);}
    25%  {transform: rotate(4deg);}
    50%   {transform: rotate(-2deg);}
    75%  {transform: rotate(2deg);}
    100% {transform: rotate(0deg);}
}
.in-viewport .overview-flex .over-card1.over-card1-1 {
    animation: example1 0.7s ease-in-out forwards;
    transform: rotate(0deg);
}
@keyframes example2 {
    0%   {transform: rotate(4deg);}
    25%  {transform: rotate(-4deg);}
    50%   {transform: rotate(2deg);}
    75%  {transform: rotate(-2deg);}
    100% {transform: rotate(0deg);}
}
.in-viewport .overview-flex .over-card1.over-card1-2 {
    animation: example2 0.7s ease-in-out forwards;
    transform: rotate(0deg);
    @media (max-width:992px){
        margin-top: 0px!important;
        text-align: right;
    }
}

.irfsbtn{
    a{
        border-radius: 12px;
        background: #FFB03D;
        text-decoration: none;
        color: #192951;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 120%;
        padding: 10px 25px;
        @media (max-width:767px){
            width: 120px;
            display: block;
            padding: 10px 20px;
        }
    }
}
.irfs-btn2{
    background-color: #000;
    border-radius: 12px;
    padding: 0px 25px;
    width: fit-content;
    margin: auto;
    margin-right: 0;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 55px;
    text-decoration: none;
    @media (max-width:992px){
        margin-right: auto;
        padding: 0px 15px;
        line-height: 45px;
        margin-bottom: 25px;
        margin-top: 30px;
    }
}
.feature-irfs{

}
.sbutract{
    padding-top: 100px;
    @media (max-width:767px){
        padding: 0 15px;
        padding-top: 85px;
    }
    .sbutractrow{
        background-image: url('../img/subtract.png');
        background-size: 100% 100%;
        padding-right: 30px;
        display: flex;
        align-items: center;
        @media ($max1024) {
            padding: 40px 30px;
        }
        @media (max-width:992px){
            padding-right: 0px;
            padding-bottom: 0px;
        }
        @media (max-width:767px){
            flex-direction: column-reverse;
            padding-top: 50px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom left;
            border-radius: 30px;
        }
        .joshi{
            margin-top: -80px;
            @media ($max1024) {
                margin-top: 0;
                margin-bottom: -105px;
            }
            @media (max-width:992px){
                margin: auto;
                display: block;
            }
            @media (max-width:767px){
                margin-top: -110px;
            }
        }
        .sec-title{
            @media (max-width:992px){
                margin-top: 20px;
            }
        }
    }
}


.moreno{
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 0px 5px 2px 4px;
    font-size: 12px;
    color: #192951;
    font-family: "Century Gothic";
    font-size: 9px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.faq{
    .accordion-item{
        border-radius: 14px;
        background: #F4F4F4;
        width: 900px;
        margin:10px auto;
        @media (max-width:992px){
            width: 100%;
        }
        .accordion-button{
            height: 70px;
            @media (max-width:767px){
                height: auto;
                padding: 15px 0px!important;
            }
        }
        .accordion-header{
            border-radius: 0px!important;
            button{
                border-radius: 15px!important;
                .pdplist-background{
                    p{
                        padding-left: 35px;
                        @media (max-width:767px){
                            padding-left: 15px;
                            font-size: 16px!important;
                            line-height: 21px;
                            padding-right: 40px;
                        }
                    }
                }
            }
        }
        .accordion-body{
            padding-left: 35px;
            @media (max-width:767px){
                padding-left: 17px;
            }
            p{
                @media (max-width:767px){
                    font-size: 16px!important;
                    line-height: 24px;
                }
            }
        }
    }
    .mainSectionAccordion > .accordion-item > .accordion-header > button:after{
        content: '+'!important;
        font-size: 28px;
        border: 1px solid #212529;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        right: 60px;
        @media (max-width:767px){
            font-size: 24px;
            padding: 10px;
            right: 35px;
        }
    }
    .accordion-button::after{
        background-image: unset!important;
    }
    .mainSectionAccordion > .accordion-item > .accordion-header > button:not(.collapsed):after{
        content: '-'!important;
        font-size: 40px;
        padding-top: 11px;
        padding-bottom: 18px;
        @media (max-width:767px){
            font-size: 38px;
            padding-top: 7px;
            padding-bottom: 14px;
        }
    }
    .accordion-button:not(.collapsed)::after{
        transform:unset!important
    }
}

.tabcontentproject{
    .irfsactive{
        display: none;
    }
}
.dnone{
    .plp-card{
        @media (max-width:767px){
            background-color: #F7F7F7;
        }
        &.insideCardWrapper{
            @media (max-width:767px) {
                margin: 0;
            }
        }
        .image-side img {
            height: 410px;
        }
    }
    .plp-price-cards{
        .card{
            &.border-primary{
                background-color: unset!important;
                @media (max-width:767px){
                    border: none!important;
                }
                .card-body{
                    background-color: unset!important;
                }
            }
        }
    }
    .irfsactive{
        display: block;
        margin-top: 0px;
        @media (max-width:767px){
            margin-top: 5px;
        }
        .discdivactive{
            .carousel{
                margin-left: 30px;
            }
            .discimg{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 25px;
                &.spin{
                    animation: spin 2s ease-out forwards;
                }
            }
        }

        .offerdesc{
            font-size: 12px;
            margin-bottom: 0px;
        }
        .irfsdisc{
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 0px;
            span{
                font-weight: 600;
            }
            @media (max-width:767px){
                font-size: 16px;
                font-weight: 700;
            }
        }
        .dflex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 30px 6px 0px;
            @media (max-width:767px){
                padding: 6px 0px 6px 0px;
            }
            .carousel{
                width: 240px;
                @media (max-width:767px){
                    width: 200px;
                }
            }
        }
    }
}