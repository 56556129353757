#wishlistProjects{
    // .project-detail-main{
    //     .image-side{
    //         .project-img{
    //             height: 280px !important;
    //         }
    //     }
    // }
    .plp-page .plp-card-main .image-side .project-img, .project-detail-main .plp-card-main .image-side .project-img{
        height: 280px !important;
    }
}