.plp-page,
.project-detail-main {

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #082040;
    }

    // background-color: #fff;
    min-height: calc(100vh - 100px);

    .ready-to-move {
        font-size: 16px;
        line-height: 16px;
    }

    .min-height-100vh {
        min-height: calc(100vh - 100px);
    }

    .ready-since {
        font-size: 14px;
        line-height: 16px;
    }

    .filter-sec {
        .container {
            border-bottom: 1px solid #EAEAEB;
        }

        .search-div {
            border-color: #082040 !important;
            border-radius: 6px;
        }
    }

    .mapviews-main {
        &.form-switch {
            padding-left: 0px;

            .form-check-input {
                margin-left: 0em;
                border: 0px;
            }
        }
    }

    .form-check-input:checked {
        background-color: #ffffff;
    }

    .form-check-input:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    .form-switch .form-check-input:checked {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%2343A66A'/></svg>");
    }

    .right-side-plp-filter {
        // position: sticky;
        // top: 120px;
        // z-index: 1;
    }

    #scroll-up-arrow {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #ffffff;
        color: #000000;
        font-size: 24px;
        padding: 10px 14px;
        border-radius: 50%;
        z-index: 999;
        cursor: pointer;
    }

    .searchlist {
        margin-bottom: 20px;
    }

    .hidden {
        display: none;
    }

    .rightBtnsWrapper {
        @media (max-width:767px) {
            position: absolute;
            top: -274px;
            left: 15px;
            justify-content: space-between !important;
            width: 77%;
        }
    }

    .discdiv {
        position: relative;
        display: none;

        .discimg {
            width: 25px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.5s ease-out;

            &.spin {
                animation: spin 2s ease-out forwards;
            }
        }

        .carousel {
            margin-left: 25px;
        }
    }

    .irfslist-class {

        .discdiv {
            position: relative;
            display: block;

            .discimg {
                width: 25px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .carousel {
                margin-left: 25px;
            }
        }
    }

    .plp-card-main {
        padding: 16px;

        .image-side {
            flex: 0 0 42%;
            height: 380px;
            border-radius: 24px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.14);

            .project-img {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 24px;
                height: 380px;

                @media screen and (max-width:767px) {
                    height: 280px;
                }
            }

            @media screen and (min-width: 1600px) {
                height: 421px;
                flex: 0 0 40%;

                .project-img {
                    height: 421px;
                }
            }

            @media screen and (min-width: 1800px) {
                flex: 0 0 35%;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 10px 10px 20px;
        }
    }

    .plcard-name-bhk {
        gap: 10px;
    }

    .plpcard-name {
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;

        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0px;
            width: 80%;
        }
    }

    .developer-name {
        color: #667486;
    }

    .bhk-tab {
        gap: 10px;
    }

    .unitConfig {
        font-size: 14px;

        @media (max-width:767px) {
            font-size: 12px;
        }
    }

    .carpetmobile {
        .plp-carpet-area {
            margin-bottom: 0px;
        }
    }

    .plp-card-detail {
        padding: 14px 0px 10px;

        @media (max-width:992px) {
            padding: 3px 0px 0px;
            border: none !important;
        }

        @media (min-width:1300px) and (max-width:1380px) {
            margin-top: -3px;
        }

        .amenities-gap {
            gap: 5px;
            width: 100%;

            @media ($max640) {
                gap: 5px;
            }

            // height: 73px;
            // .amenities-list{
            //     overflow: auto;
            //     white-space: nowrap;
            //     scrollbar-width: none;
            //     width: 50%;
            //     div{
            //         display: inline-block;
            //         margin-right: 5px;
            //     }
            // }
        }

        .plp-carpet-area {
            display: flex;
            gap: 12px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 14px;
            color: #6C6F74;

            @media (max-width:767px) {
                justify-content: flex-start;
                gap: 4px;
                font-size: 12px;
                margin-bottom: 2px;
                padding-left: 0px;
            }
        }

        .plp-price-cards {
            &.parent {
                display: inline-grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 12px;
                grid-row-gap: 0px;

                @media screen and (max-width: 767px) {
                    grid-column-gap: 7px;
                    margin: auto;
                    margin-right: 0;
                    display: flex;
                }

                .card {
                    border-radius: 8px;
                    min-width: 145px;
                    // background-color: #E2F1E8;
                    background-color: #fff;

                    @media (max-width:767px) {
                        // margin: auto;
                        // margin-right: 0;
                    }

                    @media screen and (max-width: 767px) {
                        min-width: calc(33% - 5px);

                        &:first-child {
                            min-width: 120px;

                            @media ($max380) {
                                min-width: 110px;
                            }
                        }
                    }

                    .card-header {
                        font-size: 13px;
                        line-height: 17px;
                        color: #252525;

                        @media (max-width:767px) {
                            display: none;
                        }

                        @media (min-width:1200px) and (max-width:1400px) {
                            line-height: 15px;
                        }

                        @media screen and (max-width:767px) {
                            font-size: 12px;
                        }

                        @media ($max380) {
                            font-size: 10px;
                        }
                    }

                    .card-body {
                        border-radius: 0 0 8px 8px;

                        &.mobPrice {
                            @media ($max640) {
                                padding-left: 0 !important;
                            }
                        }

                        .card-title,
                        a {
                            font-size: 15px;
                            color: #082040;

                            @media (max-width:767px) {
                                font-size: 14px;
                                line-height: 20px;

                                span {
                                    line-height: 20px;
                                }
                            }

                            @media ($max380) {
                                font-size: 12px;
                                line-height: 8px;

                                span {
                                    line-height: 8px;
                                }
                            }
                        }

                        .card-title {
                            @media (max-width:767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .plp-filter-sec {
        padding: 28px 20px 20px;
        background-color: $white;
    }

    .datamapview-leftbar {
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 30px;
        padding: 20px 10px;
        background-color: $white;

        @media (max-width:767px) {
            padding: 15px 3px;
        }

        .leaflet-container {
            border-radius: 30px;
        }

        .infinite-scroll-component__outerdiv {
            padding: 0px;

            .infinite-scroll-component {
                padding: 5px;

                @media (min-width:1200px) and (max-widrh:1370px) {
                    padding: 5px;
                }

                .pd-lt {
                    padding-left: 0.3rem !important;
                }
            }
        }

        .carousel-indicators {
            display: none;
        }
    }

    .plp-filter-dropdown {
        padding: 12px 16px 12px 22px;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 30px;
        z-index: 1;

        // transition: top 0.3s ease-in-out;
        &.sticky {
            top: 100px;
            width: calc(25% - 1.9rem);
        }

        h3 {
            font-size: 30px;
            line-height: 26px;
            font-weight: 700;
            color: #082040;

        }
    }

    .filter-checkbox {
        padding: 5px 10px;
        border: 1px solid #667486;
        border-radius: 8px;
        cursor: pointer;

        span {
            font-size: 14px;
            // line-height: 20px;
        }

        &.active {
            background: #5d1a79;
            background: linear-gradient(271deg, #5D1A79 0, #082040 50%);
            color: #FFFFFF;
        }
    }

    .filter-checkbox:hover {
        background: #5d1a79;
        background: linear-gradient(271deg, #5D1A79 0, #082040 50%);
        color: #FFFFFF;
    }

    .similer-project {
        .card-body {
            p {
                text-align: left;
            }
        }
    }

    .infinite-scroll-component {
        overflow-x: hidden !important;
    }
}

.projectDisclaimer {
    padding-inline: 15px;

    p {
        font-size: 14px;
        color: #667486;
        line-height: 20px;
        text-wrap: balance;

        @media (max-width:767px) {
            font-size: 10px;
            line-height: 16px;
        }
    }
}

.filter-checkbox:hover:last-child {
    border-bottom: none;
    // padding-bottom: 0px;
}

.right-side-plp-filter {
    position: relative;
    width: 100%;
    height: 100%;
}

#filter-sticky {
    &.stickySidebar {
        width: calc(25% - 1.9rem);
    }
}

.chat-img {
    height: 50px;
    width: 50px;
}

.plp-amenties-tag {
    padding: 6px 8px;
    background-color: #fff;
    max-height: 30px;
    border: 0.5px solid rgba(102, 116, 134, .5);
    border-radius: 8px;

    @media screen and (max-width:767px) {
        padding: 2px 5px;
        border-radius: 11px;
        // max-height: 100%;
    }

    p {
        // max-width: 150px;
        font-size: 12px;
        color: #667486;

        @media screen and (max-width:767px) {
            text-wrap: wrap;
            font-size: 11px;
            line-height: 18px;
        }
    }
}

.plp-card .readmore {
    @media screen and (max-width:767px) {
        padding: 0 !important;
    }
}

/* Style the tab */

.tab {
    overflow: hidden;
    // background-color: #f1f1f1;
    border-color: #C9C9C9 !important;
}


/* Style the buttons inside the tab */

.tab button {
    background-color: inherit;
    transition: 0.3s;
    color: #C9C9C9;
    border-radius: 6px;
}


/* Change background color of buttons on hover */

.tab button:hover {
    background-color: #E2EDEC;
}


/* Create an active/current tablink class */

.tab button.active {
    background-color: #E2EDEC;
    border-color: #43A66A !important;
    color: #000000;
    font-weight: bold;
}


/* Style the tab content */

.tabcontent {
    // display: none;
}

// .image-side .carousel-item:after{
//     position: absolute;
//     content: '';
//     z-index: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: linear-gradient(180deg, rgba(8, 32, 64, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
// }

.possession-cont {
    width: 510px;
    gap: 14px;
}

.star-side {
    right: 0rem;
    bottom: unset;
    top: 25%;
}

.sticky-btn {
    left: -140px;
    right: auto;
    transform: rotate(-90deg);
}

.number-box {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.pagination {
    justify-content: center;
    margin-bottom: 20px;

    .page-item {
        .page-link {
            color: #082040;
        }

        :hover {
            border-color: $baseGreen;
            background: $baseGreen;
            color: #ffffff;
        }

        &.active {
            .page-link {
                border-color: $baseGreen;
                background: $baseGreen;
                color: #ffffff;
            }
        }
    }
}

@media (max-widrh:767px) {
    .star-side {
        right: -0.5rem;
        bottom: 0;
        top: unset;
    }

    .sticky-btn {
        bottom: 0px;
        width: 100%;
        top: unset !important;
        gap: 2px !important;
        border: 0px;
        transform: rotate(0deg);
        background: linear-gradient(271deg, #5d1a79 0%, #082040 50%);
        left: 0px;

        a {
            background: unset !important;
            width: 50%;
            border-radius: 0px !important;
        }
    }

    .plp-card-main {
        padding: 10px;
    }

    .ready-to-move {
        font-size: 12px;
        line-height: 16px;
    }

    .ready-since {
        font-size: 12px;
        line-height: 16px;
    }

    .plp-card-detail {
        .plp-price-cards {
            &.parent {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 10px;
                grid-row-gap: 10px;
            }
        }
    }
}

.accordion_faq .set .panel {
    display: block;
    // padding: 10px 25px;
    text-decoration: none;
    color: #082040;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s linear;
    position: relative;
    height: auto;
    border: 0px solid;
    line-height: 22px;
    letter-spacing: 1px;
}

.accordion_faq .set {
    padding: 10px 0px;
    display: block;
    border-bottom: 1px solid #e9eff2;
}

.accordion_faq .content {
    margin-top: 15px;

    .filter-checkboxes {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
}

.accordion_faq a {
    font-size: 20px !important;
}

#loadMore {
    text-align: center;
    margin: 20px 0px;
}

.faq-sec {
    margin: 20px 50px;
}

.rera-number-div {
    display: none;
    background-color: #FFFFFF;
    color: #000000;
    position: absolute;
    top: 16px;
    padding: 10px 36px 20px 20px;
    z-index: 10;
    border-radius: 0 8px 8px 0;
    transform: translateX(-50%);
    transition: all 0.6s ease-in-out;

    h6 {
        font-size: 14px;
        line-height: 26px;
        color: #000000;
    }

    p.psn {
        font-size: 12px;
        line-height: 26px;
        color: #3B3B3B;
        margin-bottom: 10px;
    }

    span.psn-details {
        font-size: 12px;
        line-height: 26px;
        color: #3B3B3B;
        display: block;
    }

    .parent {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 0;
    }

    .div1 {
        grid-area: 1 / 1 / 3 / 2;
    }

    .div2 {
        grid-area: 1 / 2 / 3 / 3;
    }

    .div3 {
        grid-area: 1 / 3 / 3 / 4;
    }
}

.compare-checkbox {
    border: 1px solid #707070;
    border-radius: 0px !important;

    &:focus {
        box-shadow: unset;
        border: 1px solid #707070;
    }
}

.rera-main-div:hover~.rera-number-div {
    display: inline-block;
    transform: translateX(0%);
}

.rera-number-div:hover {
    display: inline-block;
    transform: translateX(0%);
}

.c-p {
    cursor: pointer;
}

.btn {
    &.sc {
        background: $baseGreen;
        // background: linear-gradient(271deg, rgba(93, 26, 121, 1) 0%, rgba(8, 32, 64, 1) 50%);
        height: 50px;
        border-color: transparent;
        padding: 14px 10px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        line-height: 20px;
        letter-spacing: .50px;
        // flex: if, ac, jc;
        // @include flex-gap(0, 24px);
        transition: all 0.2s ease-in-out;

        span {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        i {
            font-size: 14px;
        }

        &:hover {
            transition: all 0.2s ease-in-out;
            background-color: #43A66A;
            border-color: #43A66A;
            color: $white;
        }
    }

    .h-auto {
        height: auto;
    }
}

.c-pointer {
    cursor: pointer;
}

.clear-all {
    padding: 5px 0px
}

.text-primary {
    color: #43A66A !important;
}

#login-edit-contact i {
    cursor: pointer;
}

.text-secondary {
    color: #082040 !important;
}

.text-listing {
    color: #252525;
}

.primary-bg {
    background-color: #43A66A;
}

.border-primary {
    border: 1px solid #43A66A !important;

    @media (max-width:767px) {
        border: none !important;
    }
}

.secondary-bg {
    background-color: #ECF6F0 !important;
}

.zindex-10 {
    z-index: 10;
}

.flex-basis-0 {
    flex-basis: 0;
}

.bhk-color {
    background-color: #EBEBEB;
    border-radius: 6px;
    color: #082040;
    height: 30px;

    @media (max-width:767px) {
        height: 23px;
        border-radius: 8px;
    }

    &.active {
        border-color: #E2F1E8 !important;
        background-color: #E2F1E8;
    }
}

.text-property {
    color: #667486;
    font-size: 14px;
}

.moreno {
    width: 22px;
    height: 22px;
    line-height: 0px;
    margin-top: 3px;
}

.text-rate {
    color: #C9C9C9;
}

.text-mob-comparision {
    color: #6C6F74;
}

.object-fit-cover {
    object-fit: cover;
}

.developer-name {
    font-size: 14px;
    color: #747474;
    text-transform: capitalize;

    @media (max-width:767px) {
        font-size: 12px;
    }
}

.carousel-indicators {
    bottom: -30px;
    margin-bottom: 0rem;

    [data-bs-target] {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        background-color: #082040;

    }
}

.fs-22 {
    font-size: 22px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14i {
    font-size: 14px !important;
}

.fs-12i {
    font-size: 12px !important;
}

.gap-12 {
    gap: 12px;
}

.sec-border-2 {
    border: 2px solid #CAE6D6;
    border-bottom: 0px;
    border-radius: 8px;
}

.border-bottom-plp {
    border-bottom: 1px solid #EDEDED;
}

.sec-darkborder-2 {
    border: 2px solid #43A66A;
    border-radius: 8px;

}

.rounded-8 {
    border-radius: 8px;

}

.border-primary-bottom {
    border-bottom: 2px solid #43A66A;
}

.border-secondary {
    border: 1px solid #082040;
    // border-radius: 4px;
}

.border-secondary-bottom {
    border-bottom: 1px solid #082040 !important;
}

.border-form {
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.start-1 {
    left: -1%;
}

.end-1 {
    right: -1%;
}

.top-15 {
    top: 15% !important;
}

.top-55 {
    top: 55px !important;
}

.fw-500 {
    font-weight: 500;
}

.green-cross {
    top: -10px;
    right: -10px;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.maxh-300 {
    height: 300px !important;
}

.maxh-430 {
    height: 380px !important;
    border-radius: 12px;
}

.search-width {
    max-width: 410px;
}

.fa-circle-chevron-left {
    color: #707070;
}

.review-stars {
    color: #EAEAEA;

    &.active {
        color: #FDCC0D;
    }
}

.btn-light {
    color: #000;
    background-color: #ffffff;
    border: 1px solid #082040;
    border-radius: 8px;
}

.flex-1 {
    flex: 1;
}

.project-listing-main {
    min-height: calc(100vh - 100px);
    justify-content: flex-start;
}

@media (max-width:991px) {
    .maxh-430 {
        height: 370px !important;
    }
}

@media (max-width:767px) {
    .search-width {
        display: none !important;
        max-width: 140px;
    }
}

@media (max-width:600px) {
    .maxh-430 {
        height: 200px !important;
    }

    .green-cross {
        top: -5px;
        right: 0px;

        img {
            width: 14px;
        }
    }

    button.btn.sc i {
        margin-right: 07px;
    }

    // .plp-page{
    //     margin-top: 100px!important;
    // }
}

.filter-sidebar {
    h4 {
        padding: 15px 25px;
    }

    .headBtns {
        display: grid;
        grid-template-columns: 4% 85% auto;
        align-items: center;
        padding: 0 10px;

        @media ($max992) {
            grid-template-columns: 4% 80% auto;
        }

        @media ($max640) {
            grid-template-columns: 5% 70% auto;
        }

        @media ($max340) {
            grid-template-columns: 5% 60% auto;
        }
    }

    .nav-pills {
        .nav-link {
            color: #000;
            font-size: 14px;
            padding: 15px 5px 15px 15px;
            border-bottom: 1px solid #EAEAEB;
            background-color: #F6F6F6;
            border-radius: 0;

            &.active {
                background-color: #fff;
                font-weight: 700;
            }
        }
    }

    .left-tab-container {
        height: calc(100vh - 76px);
        background-color: #F6F6F6;
    }

    @media screen and (max-width: 1024px) {
        left: 0;
        position: fixed;
        top: 0px;
        z-index: 99999999;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        // overflow-x: none;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;
        transition: -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;
        transition: transform 525ms cubic-bezier(0, 0, .2, 1) 0ms;
        transition: transform 525ms cubic-bezier(0, 0, .2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;
        background-color: #fff;

        &.active {
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    .tab-content {
        a {
            font-size: 12px;
            padding: 5px 10px;
            text-transform: capitalize;
            background-color: #EBEBEB;
            border: 0px;
            color: #082040;
            border-radius: 6px;

            &.active {
                background-color: #43A66A;
                color: #fff;
            }
        }
    }
}

.irfslist-class .filter-sidebar .tab-content a.active {
    background-color: #F8990C;
}

// .searchIcon{
//     margin-top: 10px;
// }
// .searchIcon{
//     img{
//         padding: 0 10px;
//     }
// }
header .serachiconshow {
    @media ($max992) {
        width: 100%;
    }
}

.searchIcon {
    img {
        padding: 0 10px;

        @media ($max992) {
            width: 100%;
        }
    }

    &.searchBar {
        @media ($max992) {
            width: 90%;
            margin: 0 auto;
            margin-top: 15px;
        }

        @media (max-width:767px) {
            width: 100%;
            margin-top: 15px;
        }
    }

}

.mobile-filter-wrap {

    .filter-sidebar-btn,
    .filter-sidebar-sort {
        position: fixed;
        bottom: 0px;
        // z-index: 9999;
        z-index: 998;
        overflow: auto;
        background-color: #43A66A;
        color: #fff;
        opacity: 1;
        right: 0px;
        font-size: 14px;
        padding: 0;
        border-radius: 0px;
        width: 50%;
        height: 40px;
        text-align: center;
        text-decoration: none;
        line-height: 40px;
        text-transform: uppercase;
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);

        @media (max-width:992px) {
            position: unset;
            bottom: unset;
            left: unset;
            transform: unset;
            line-height: normal;
            box-shadow: none;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 6px 6px 0px;
            width: 250px;
            font-weight: 600;
        }

        @media (max-width:767px) {
            width: 150px;
        }

        &.active {
            background-color: #389d5c;
            /* Slightly darker green */
            box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
            /* Smaller shadow */
        }

        .relativeWrapper {
            position: relative;
            display: inline-block;

            .activeDot {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -15px;
                background-color: $pc;
                color: white;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                font-size: 10px;
                line-height: 20px;
                text-align: center;
            }
        }
    }

    .filter-sidebar-sort {
        left: 0;
        border-right: 1px solid #cccccc;

        @media (max-width:767px) {
            bottom: unset;
            left: unset;
            transform: unset;
            line-height: normal;
            width: 150px;
            box-shadow: none;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px 0px 0px 6px;
        }
    }
}

.mobile-filter-wrap-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99999999;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;
    transition: -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;
    transition: transform 525ms cubic-bezier(0, 0, .2, 1) 0ms;
    transition: transform 525ms cubic-bezier(0, 0, .2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0, 0, .2, 1) 0ms;

    .filter-sidebar-sort-popup {
        background-color: #fff;
        padding: 26px;
        position: absolute;
        bottom: 0%;
        left: 0;
        width: 100%;
    }

    h4 {
        font-size: 18px;
        color: #000;
        margin-bottom: 10px;
    }

    ul {
        li {
            padding: 10px 0;
            border-bottom: 1px solid #EAEAEB;

            &.active {
                background-color: #f7f7f7;
                /* Light background to indicate active state */
                border-left: 4px solid $sc;
                /* Blue border on the left to highlight the active item */
                padding-left: 10px;
                /* Adjust padding to account for the border */
                font-weight: bold;
                /* Make the text bold to stand out */
            }

            div {
                color: #000;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }

    &.active {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.irfslist-class .mobile-filter-wrap-popup ul li.active {
    border-left: 4px solid #F8990C;
}

.filter-apply-btn {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    background-color: #F6F6F6;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0 !important;

}

// @media screen and  (min-width:1024px){
//     .right-side-plp-filter{
//         position: fixed;
//         top: 99px;
//         z-index: 1;
//         width: 306px;
//         height: calc(100vh - 90px);
//         overflow: auto;
//     }
// }
.map-plp-card {
    padding: 10px;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 20px;
    margin-bottom: 30px !important;

    .plpcard-name {
        font-size: 14px;
        line-height: 16px;
        min-height: 40px;

        @media (min-width:1300px) and (max-width:1380px) {
            font-size: 13px;
            line-height: 17px;
        }
    }

    .bhk-tab {
        font-size: 14px;
    }

    .plp-card-detail {
        .tab-content {
            font-size: 14px;
        }
    }

    .readmore {
        font-size: 12px;
        background-color: #43A66A;
        width: 33px;
        height: 33px;
        border-radius: 100%;
        display: inline-block;
        padding: 2px 0;
        position: absolute;
        right: 0px;
        bottom: 10px;

        @media (min-width:1300px) and (max-width:1380px) {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            @media (min-width:1300px) and (max-width:1380px) {
                width: 22px !important;
                height: 20px !important;
            }
        }
    }

    .c-pointer {
        padding-top: 0px !important;
        margin: 0px !important;
    }

    .card-header {
        font-size: 12px !important;
    }

    .card-title {
        font-size: 12px !important;
    }

    .plp-price-cards {
        .card {
            min-width: 115px !important;
        }
    }

    .pdp-rank-wrap {
        bottom: -20px;

        &.rank-wrap {
            .circle-1 {
                width: 30px;
                height: 30px;
                padding: 1px;
            }

            .circle-2 {
                width: 30px;
                height: 30px;
                padding: 2px;
            }

            .circle-3 {
                width: 27px;
                height: 27px;
                font-size: 14px;
                line-height: 28px;
            }

            .rank-in-locality {
                font-size: 12px;
                padding-left: 0px !important;
                padding-right: 5px !important;

                &:after {
                    right: -19px !important;
                    width: 26px;
                    height: 30px !important;
                    z-index: -1;
                }

                &:before {
                    height: 30px !important;
                    left: -19px !important;
                    width: 26px;
                    top: 0px;
                    z-index: -1;
                }
            }
        }
    }

}

.tabcontentirfs {
    display: none;
}

.tabcontentproject {
    display: block;
}

.emiCalc-popup {
    position: absolute;
    top: 0;
    right: 30px;
    width: 400px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 40%);
    // padding: 15px;
    z-index: 100;

    @media (max-width:992px) {
        left: 0;
        width: 100%;
    }

    .rc-slider {
        height: 45px;
        padding: 20px 0;
        margin-left: 0;
        width: 100%;
    }

    .emi-title {
        background-color: #43A66A;
        padding: 15px;
        color: #fff;
        border-radius: 12px 12px 0 0;
    }
}

.position-none {
    position: unset;
}

.plp-card {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
    margin: 2px 5px;
    border-radius: 30px;
    opacity: 1;

    @media ($max640) {
        margin: 7px;
    }

    .image-side {
        img {
            border-radius: 0px;
            object-fit: cover;
            // height: 355px;
            aspect-ratio: 1/1;
            width: 100%;

            @media (min-width:1600px) {
                height: 400px;
            }

            @media screen and (max-width:767px) {
                height: 280px;
            }
        }
    }

    .irfs-exl {
        display: none;
    }

    .rera-main-div {
        // background: rgb(67, 166, 106);
        // background: linear-gradient(45deg, rgba(67, 166, 106, 1) 0%, rgba(67, 166, 106, 0.8491771708683473) 52%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        // padding: 10px 0px 0px;
        display: flex;
        grid-template-columns: auto auto;
        align-items: center;
        // justify-content: space-between;
        background-color: #43A66A;
        border-radius: 0px 20px 0px 0px;
        justify-content: flex-end;

        @media (max-width:767px) {
            border-radius: 0px 14px 0px 0px;
        }

        .top-picks {
            margin-left: 5px;

            @media (min-width:1600px) {
                font-size: 12px;
            }
        }

        .list-rerabox {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            background-color: #fff;
            height: 100%;
            border-radius: 20px 0px 0px 0px;
            padding: 10px 6px 10px 6px;
            align-items: center;
            width: 60%;

            @media (min-width:1600px) {
                padding: 10px;
            }

            @media (max-width:767px) {
                border-radius: 14px 0px 0px 0px;
                padding: 5px 0px 5px 6px;
                width: 58%;
            }

            @media (max-width:320px) {
                width: 50%;
            }
        }

        .rera-txt {
            font-weight: 600;
            font-size: 10px;
            margin-bottom: 0px !important;
            color: #192951;

            @media (min-width:1600px) {
                font-size: 12px;
            }

            @media (max-width:767px) {
                font-size: 10px;
            }

            @media (max-width:375px) {
                font-size: 9px;
            }
        }

        .pos-date {
            background: #43A66A;
            padding: 9px;
            border-radius: 20px 0px 0px 0px;
            color: #fff;
            left: 5px;
            position: absolute;

            @media (max-width:767px) {
                border-radius: 14px 0px 0px 0px;
                padding: 0px 0px 0px 7px;
            }

            @media (max-width:370px) {
                padding: 0px 0px 0px 7px;
            }
        }

        p {
            font-size: 11px;
            color: #fff;
            margin-bottom: 0px !important;

            @media (min-width:1600px) {
                font-size: 12px;
            }

            @media (max-width:767px) {
                font-size: 11px;
            }

            @media (max-width:380px) {
                font-size: 10px;
            }

            span {
                font-weight: 600;
            }
        }
    }

    .readmore {
        font-size: 12px;
        color: #082040;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        font-weight: 700;
    }

    .last-property-sold {
        p {
            color: #9B9B9B;
            font-size: 14px;
            line-height: 26px;
        }

        a {
            background-color: #082040;
            border-radius: 8px;
            min-width: 100%;
        }
    }

    .rank-wrap {
        vertical-align: middle;
        align-items: center;

        .circle-1 {
            width: 56px;
            height: 56px;
            background-color: #fff;
            // padding: 6px;
            border-radius: 100%;
            position: relative;
            z-index: 2;
            padding: 3px;
            float: left;
        }

        .circle-2 {
            width: 50px;
            height: 50px;
            background-color: #FFF038;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            padding: 3px;
        }

        .circle-3 {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            background-color: #43A66A;
            font-size: 22px;
            line-height: 42px;
            font-weight: 700;
            display: inline-block;
            color: #fff;
            background: transparent linear-gradient(259deg, #43A66A 0%, #8DC356 51%, #4DAA68 100%) 0% 0% no-repeat padding-box;
        }

        .rank-in-locality {
            padding: 5px;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: #fff;
            margin-left: -5px;
            padding-left: 15px;
            padding-right: 20px;
            background: transparent linear-gradient(259deg, #43A66A 0%, #8DC356 81%, #4DAA68 100%) 0% 0% no-repeat padding-box;
            position: relative;
            float: left;

            &:after {
                content: '';
                position: absolute;
                height: 100%;
                right: 0;
                top: 0;
                border-right: 14px solid #fff;
                border-top: 14px solid #4DAA68;
                border-bottom: 14px solid #4DAA68;
            }
        }
    }
}

.leaflet-popup-content {
    margin: 0;
    max-width: 220px;
    width: 220px !important;

    @media(max-width:992px) {
        width: 180px !important;
    }
}

.inner-map-popup {
    width: 220px;

    .project-img {
        height: 130px;
        width: 100%;
        max-width: 220px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        object-fit: cover;
    }

    .leaflet-popup-content {
        padding: 10px;
    }

    .conf-desc {
        color: #949494;
        font-family: Poppins;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .dev-name {
        font-size: 12px;
        font-family: Poppins;
        color: #949494;
        line-height: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
        width: 200px;
    }

    .pname {
        font-size: 16px;
        font-family: Poppins;
        color: #000;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .pricebox {
        p {
            margin-bottom: 5px;
            margin-top: 5px;

            span {
                font-size: 12px;
                font-family: Poppins;
                color: #000;
                font-weight: 600;
            }
        }
    }

    @media(max-width:992px) {
        width: 180px;
    }

}

.map-card-btn-wrapper {
    text-align: center;

    .map-card-btn {
        background-color: #389d5c;
        border-color: #389d5c;
        padding: 0px;
        width: auto;
        margin: auto;
        margin-right: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        top: -15px;
        float: right;
        padding-inline: 10px;
        border-radius: 12px;
        justify-content: center;

        img {
            margin-inline: auto;
        }

        @media(max-width:767px) {
            width: 100%;
            height: 40px;
            margin-bottom: 10px;
            top: 0px;
            padding-block: 5px;
            display: block;
        }
    }
}

.map-image-side {
    width: 130px;
    overflow: hidden;
    float: left;
}

.map-info-side {
    width: calc(100% - 130px);
    float: left;

    .plp-price-cards {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.project-img-map {
    height: 130px;
    width: 121px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 20px;
    overflow: hidden;

    @media (min-width:1300px) and (max-width:1370px) {
        width: 95px;
        height: 95px;
    }

    @media screen and (max-width: 767px) {
        height: 120px;
        width: 110px;
    }
}

.sort-filter {
    height: 40px;
}

.compare-btn {
    input {
        opacity: 0;
        -webkit-appearance: none;
        display: inline-block;
        vertical-align: middle;
        z-index: 100;
        width: 80px;
        height: 30px;
        top: 0;
        right: 0;
        position: absolute;
        cursor: pointer;
    }

    .compare-btn {
        background-color: #43A66A;
        border-radius: 4px;
        width: 80px;
        height: 30px;
        transition: all 0.2s ease-in-out;

        path {
            stroke: #43A66A;
            stroke-width: 8px;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
            // display: none;
            transition: stroke-dashoffset 0.2s ease-in-out 0s;
        }

        text {
            font-size: 50px;
            fill: #fff;
        }
    }

    // &.checked{
    //     input{
    //         width: 40px;
    //     }
    //     svg{
    //         width: 40px;
    //         background-color: transparent;
    //         border: 1px solid #43A66A;
    //         // path{
    //         //     display: block;
    //         //     stroke-dasharray: 126.37, 126.37;
    //         //     stroke-dashoffset: 0;
    //         //     transition: stroke-dashoffset 0.2s ease-in-out 0s;
    //         // }
    //         text{
    //             display: none;
    //         }
    //     }
    // }
    @keyframes drawCheckmark {
        from {
            stroke-dashoffset: 200;
        }

        to {
            stroke-dashoffset: 30;
        }
    }

    &.checked path {
        stroke-dasharray: 200;
        animation: drawCheckmark 0.5s ease forwards;
    }

}

.top-filter-accordation {
    position: relative;

    .accordion-collapse {
        .accordion-body {
            display: none;
            width: 100%;
            min-width: 200px;
            position: absolute;
            /* This makes the accordion content position absolute */
            z-index: 99999;
            /* Adjust as necessary to ensure it's above other content */
            background-color: #fff;
            box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 11%);
            border-radius: 0 0 6px 6px;
        }

        &.show {
            .accordion-body {
                top: 43px !important;
                left: 0px !important;
                display: block;
                padding-top: 10px !important;
            }
        }
    }

    &.budget {
        .accordion-body {
            width: 300px
        }
    }

    &.built-up-area {
        .accordion-body {
            width: 300px
        }
    }
}

.filter-sticky-map {
    position: fixed;
    background-color: #fff;
    top: 85px;
    width: 100%;
    z-index: 99;
    padding: 15px 15px;
    left: 0;
    margin: 0;
}

.mob-map-wrapper {
    #sticky-map-rightsidebarwrap {
        &.flexibleHeight {
            height: calc(100vh - 150px) !important;

            // height: 700px !important;
            @media ($max1440) {
                // height: 80vh !important;
                // height: 420px !important;
            }
        }

        transition: all 0.6s ease-in-out;
    }

    #sticky-map-rightsidebar {
        right: 0;
        position: fixed;
        top: 157px;
        // height: calc(100vh - 160px);
        height: 100%;
        // z-index: 999;
        z-index: 1;
    }

    @media screen and (max-width: 1024px) {
        position: relative;
        margin-top: 0px !important;

        .px-4 {
            padding: 0px !important;
        }

        .col-reverse {
            flex-direction: column-reverse;
        }

        #sticky-map-rightsidebarwrap {
            max-height: 40vh !important;
            height: 40vh !important;
            top: 0px !important;
            position: fixed !important;
            right: 0px !important;
        }

        #sticky-map-rightsidebar {
            position: fixed !important;
            top: 90px !important;
            padding: 0px !important;
            z-index: 1;
            width: 100%;
            overflow: hidden;
            max-height: 40vh !important;
            height: 40vh !important;
        }

        #projects {
            position: relative;
            margin-top: 35vh;
            background-color: #fff;
            z-index: 3;
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
            padding-top: 30px;
            border-radius: 60px 60px 0px 0px;

            &:after {
                content: "" !important;
                display: block !important;
                position: absolute !important;
                top: 15px !important;
                left: 50% !important;
                transform: translateX(-20px) !important;
                width: 40px !important;
                height: 4px !important;
                border-radius: 4px !important;
                background-color: rgba(32, 32, 32, 0.2) !important;
            }
        }

    }

    @media (max-width:767px) {
        #sticky-map-rightsidebar {
            top: 60px !important;
        }
    }
}

@media screen and (max-width: 768px) {
    // #sticky-map-rightsidebarwrap {
    //     z-index: -1;
    // }
}

.custom-popup .leaflet-popup-content {
    text-align: left;
}

.leaflet-popup-content-wrapper {
    box-shadow: none;
    border-radius: 8px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: #fff;
    color: #333;
}

.leaflet-popup-close-button {
    color: #333;
}

.new-map-prjt-listing {
    .infinite-scroll-component {
        overflow: unset !important;
    }
}

.mobile-map-list-view {
    // position: fixed;
    position: absolute;
    // top: 65px;
    top: 95px;
    // z-index: 99999;
    z-index: 1;
    right: 0;
    background-color: transparent;
    height: 49px;
    padding: 12px 10px 10px 0;

    @media(max-width: 992px) {
        top: 0px;
        display: flex !important;

        .rounded-pill {
            display: inline-flex;
            align-items: center;

            width: 50px;
            height: 30px;
            background-color: #fff;
            border: 2px solid rgba(0, 0, 0, 0.2);

            svg {
                max-width: 30px !important;
                height: auto !important;
                max-height: 20px;
                margin: auto;
            }
        }
    }
}

.new-map-view-card {
    .image-side {
        height: 290px !important;
        border-radius: 24px 24px 0px 0px !important;

        .project-img {
            height: 290px !important;
            border-radius: 24px 24px 0px 0px !important;
        }

        .carousel-inner,
        .carousel-item {
            height: 290px !important;
        }

        img {
            object-fit: cover !important;
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            height: 230px !important;

            .carousel-inner,
            .carousel-item,
            .project-img {
                height: 230px !important;
            }
        }
    }

    .top-picks {
        bottom: unset !important;
    }

    .plp-card-main {
        background-color: #fff;
        border-radius: 25px !important;
    }

    .info-box {
        min-height: 135px;

        @media screen and (max-width: 768px) {
            min-height: auto;
        }
    }
}

.countNo {
    font-size: 14px;
    font-weight: 600;
    padding-left: 5px;
}

.noProjectWrapper {
    // border: 1px solid $pc;
    border-radius: 8px;
    padding: 20px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-bottom: 20px;

    p {
        margin-bottom: 0;
    }
}

.countSentenceWrapper {
    @media ($max992) {
        // overflow-x: scroll;
    }

    .countSentence {
        font-size: 20px;
        line-height: 30px;
        padding-left: 20px;

        @media ($max992) {
            width: auto;
            overflow-x: scroll;
            white-space: nowrap;
            font-size: 14px !important;
            line-height: normal;
            padding-left: 0px;
            margin: 0;
            padding-bottom: 10px;
        }
    }
}

//wishlist
.wishListWrapper {
    margin: 0 auto;
    max-width: 1320px;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }

    @media (max-width: 1200px) {
        max-width: 1140px;
    }

    @media (max-width: 1024px) {
        max-width: 960px;
    }
}

#RandomTextLoginButton {
    .wishlist-banner {
        height: auto;

        .wishlist-inner {
            display: grid;
            grid-template-columns: 25% auto;
            align-items: center;

            @media ($max992) {
                grid-template-columns: auto;
                justify-content: center;
                text-align: center;
                padding: 15px 0;
                padding-bottom: 0;
                margin-top: 0;
                gap: 10px 0;
            }

            img {
                @media ($max992) {
                    margin: auto;
                    order: 2;
                }
            }

            .inner {
                padding: 10px 0;
                h3 {
                    @media ($max992) {
                        margin: 0 !important;
                        padding: 10px !important;
                    }
                    @media ($max640) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    // #sticky-map-rightsidebarwrap {

    //     // display: none;
    //     &.active {
    //         // display: block;
    //         z-index: 99999999;
    //     }
    // }
}